import useAxiosWithInterceptor from "../useAxiosWithInterceptor";
import { AxiosResponse } from "axios";

export interface IAddEditProductToUserCart {
  uuid?: string;
  productId: number | undefined;
  membershipDurationId?: number | null | undefined;
  checkedInDate?: string | null;
  locationId?: number | null;
  instructorId?: number | null;
  qty: number | undefined;
  couponCode?: string | null;
  savedForLater?: boolean;
  addons?: {
    addonId: number;
    qty: number;
  }[];
}

const useUserCartAPI = () => {
  const { axiosInstance } = useAxiosWithInterceptor();

  const loadAllUserCart = (): Promise<any[]> =>
    axiosInstance
      .get("api/UserCart/All")
      .then((response: AxiosResponse<any[]>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const loadReadyUserCart = (): Promise<any[]> =>
    axiosInstance
      .get("api/UserCart/Ready")
      .then((response: AxiosResponse<any[]>) => response.data)
      .catch((error: any) => {
        throw error;
      });
  const reviewReadyCartCoupons = (): Promise<any[]> =>
    axiosInstance
      .get("api/UserCart/ReviewReadyCartCoupons")
      .then((response: AxiosResponse<any[]>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const loadReadyAnonymousCart = (payload: any): Promise<any[]> =>
    axiosInstance
      .post("api/UserCart/ReadyAnonymous", payload)
      .then((response: AxiosResponse<any[]>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const getFromUserCart = async (uuid: string): Promise<any> => {
    const response = await axiosInstance.get(`api/UserCart/${uuid}`);
    return response.data;
  };

  const addToUserCart = async (
    payload: IAddEditProductToUserCart
  ): Promise<any> => {
    const response = await axiosInstance.post("api/UserCart/Add", payload);
    return response.data;
  };

  const updateToUserCart = async (
    payload: IAddEditProductToUserCart
  ): Promise<any> => {
    const response = await axiosInstance.put(
      `api/UserCart/Update/${payload.uuid}`,
      payload
    );
    return response;
  };

  const deleteFromUserCart = async (uuid: string): Promise<any> => {
    const response = await axiosInstance.delete(`api/UserCart/${uuid}`);
    return response;
  };

  const addCouponToCart = async (payload: any): Promise<any> => {
    const response = await axiosInstance.post(
      "api/UserCart/ApplyCoupon",
      payload
    );
    return response.data;
  };

  return {
    loadAllUserCart,
    loadReadyUserCart,
    getFromUserCart,
    addToUserCart,
    updateToUserCart,
    deleteFromUserCart,
    addCouponToCart,
    loadReadyAnonymousCart,
    reviewReadyCartCoupons
  };
};

export default useUserCartAPI;
