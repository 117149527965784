import useAxiosWithInterceptor from "../useAxiosWithInterceptor";
import { AxiosResponse } from "axios";
import { PaginatedResult } from "./PaginatedResult";

export interface MyTicket {
    id: string;
    user: any;
    checkedInDate: string;
    location: any;
    instructor: any;
    status: {
        id: number;
        name: string;
    };
    comment: string;
}

const useTicketsAPI = () => {
    const {axiosInstance} = useAxiosWithInterceptor();

    const loadAvailableSessions = (): Promise<PaginatedResult<MyTicket>> =>
        axiosInstance
            .get("api/ProductSessions/Available")
            .then((response: AxiosResponse<any>) => response.data)
            .catch((error: any) => {
                throw error;
            });

    const loadAvailableTickets = (): Promise<PaginatedResult<MyTicket>> =>
        axiosInstance
            .get("api/Products/AvailableTickets")
            .then((response: AxiosResponse<any>) => response.data)
            .catch((error: any) => {
                throw error;
            });

    const loadMySessions = (): Promise<PaginatedResult<MyTicket>> =>
        axiosInstance
            .get("api/UserCheckins/CurrentUserSessions")
            .then((response: AxiosResponse<any>) => response.data)
            .catch((error: any) => {
                throw error;
            });

    const loadMyTickets = (): Promise<PaginatedResult<MyTicket>> =>
        axiosInstance
            .get("api/UserCheckins/CurrentUserPurchasedTickets")
            .then((response: AxiosResponse<any>) => response.data)
            .catch((error: any) => {
                throw error;
            });

    return { loadMyTickets, loadMySessions, loadAvailableSessions, loadAvailableTickets};
};

export default useTicketsAPI;
