import React, { useContext } from 'react';
import LoginSM from "../LoginSM/LoginSM";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";

export default function Login() {
  const { direction } = useContext(LocaleContext) as LocaleContextProps;

  return (
    <div>
      <section className="login-page" style={{ backgroundColor: "#ebf3ff" }} dir={direction}>
        <div className="row pg_none">
          <div className="d-flex justify-content-center align-items-center py-4">
            <LoginSM/>
          </div>
        </div>
      </section>
    </div>
  );
}
