import { AxiosResponse } from 'axios';
import useAxiosWithInterceptor from './useAxiosWithInterceptor';

const useMembershipsService = () => {
  const { axiosInstance } = useAxiosWithInterceptor();

  const getMemberships = (): Promise<any> =>
    axiosInstance
      .get('/api/ProductMemberships/Home')
      .then((response: AxiosResponse<any>) => {
        return response.data
      })
      .catch((error: any) => {
        throw error;
      });
  const getMembershipDurations = (id: number): Promise<any> =>
    axiosInstance
      .get('/api/ProductMemberships/HomeDurationsForMembership', { params: { EntityId: id } })
      .then((response: AxiosResponse<any>) => {
        return response.data
      })
      .catch((error: any) => {
        throw error;
      });

  return {
    getMemberships,
    getMembershipDurations

  };
}


export default useMembershipsService;