import {FaEnvelope, FaFacebookF, FaGlobe, FaLinkedin, FaTwitter} from "react-icons/fa";

export function SpeakerContact ({contact}: any) {
    return null;

    return (
        contact &&
        <div className="media-speakers-index">
            <ul>
                {contact.twitter &&
                    <li>
                        <a href={contact.twitter} target="_blank">
                            <FaTwitter/>
                        </a>
                    </li>
                }
                {contact.linkedIn &&
                    <li>
                        <a href={contact.linkedIn} target="_blank">
                            <FaLinkedin/>
                        </a>
                    </li>
                }
                {contact.website &&
                <li>
                    <a href={contact.website} target="_blank">
                        <FaGlobe/>
                    </a>
                </li>
                }
                {contact.facebook &&
                <li>
                    <a href={contact.facebook} target="_blank">
                        <FaFacebookF/>
                    </a>
                </li>
                }

                {contact.email &&
                <li>
                    <a href={"mailto:"+contact.email} >
                        <FaEnvelope/>
                    </a>
                </li>
                }
            </ul>
        </div>
    )


}