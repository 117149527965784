import React from "react";
import { Radio } from "@mui/material";

interface PaymentOptionProps {
  value: string;
  label: string;
  showLabel: boolean;
  image: string | undefined;
  images: string[] | undefined;
  selected: boolean;
  handleOpen: () => void;
  setSelectedOption: React.Dispatch<any>;
  direction: string;
  handleMethodSelection: React.Dispatch<any>;
}

const PaymentOptionComponent: React.FC<PaymentOptionProps> = ({
  value,
  label,
  showLabel,
  image,
  images,
  selected,
  setSelectedOption,
  direction,
  handleMethodSelection,
}) => {
  return (
    <div
      className={`sub-select-payment-method ${
        selected ? "active selected" : ""
      }`}
      onClick={handleMethodSelection}
      dir={direction}
    >
      <div className="input-payment-method">
        <h2>
          {/*<Radio*/}
          {/*    checked={selected}*/}
          {/*    // onChange={handleChange}*/}
          {/*    value={value}*/}
          {/*    name="pay-option"*/}
          {/*    inputProps={{ 'aria-label': 'A' }}*/}
          {/*/>*/}
          {showLabel && label}
        </h2>
        {image && <img loading="lazy" src={image} alt={label} />}
        {images && (
          <div
            style={{
              gap: "10px",
              display: "flex",
            }}
          >
            {images.map((im) => (
              <img loading="lazy" src={im} alt={label} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentOptionComponent;
