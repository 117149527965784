/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Container } from "@mui/material";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import useNewsAPI, { ICasesByCategory } from "../../api/ems/news";
import useCasesAPI from "../../api/ems/cases";
import { ICaseCategory } from "../../api/ems/schemas";
import NoData from "../../iComponents/NoData/NoData";
import FallbackImage from "../../assets/images/imagefallback.png";
import { formatDate } from "src/utils/dateUtils";
import { FaRegClock } from "react-icons/fa";

export default function Cases() {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const navigate = useNavigate();

  const { loadNews } = useNewsAPI();
  const { loadHomeCasesCategories } = useCasesAPI();
  const [loading, setLoading] = useState(false);

  const [casesCategories, setCasesCategories] = useState<ICaseCategory[]>([]);
  const [casesByCategory, setCasesByCategory] = useState<ICasesByCategory[]>(
    []
  );

  useEffect(() => {
    setLoading(true);
    loadHomeCasesCategories()
      .then((categories) => {
        setCasesCategories([...categories.items]);

        for (const cat of categories.items) {
          const catId = cat.id;

          loadNews(1, catId)
            .then((cases) => {
              const cbc: ICasesByCategory = {
                category: catId,
                cases: cases.items,
              };
              setCasesByCategory((old) => [...old, cbc]);
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  }, []);

  return (
    <div dir={direction}>
      <section className="articles mr-section">
        <Container maxWidth="xl" className="main-container">
          <div className="title-center">
            <h3>{t("casesArticles")}</h3>
            <h2>{t("fullLibrary")}</h2>
          </div>
          <div className="links-articles links-products-tabs">
            {casesCategories.length > 0 ? (
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                {casesCategories.map((cat, index) => (
                  <li className="nav-item">
                    <a
                      className={`nav-link ${index === 0 ? "active" : ""}`}
                      id={`pills-${cat.id}-tab`}
                      data-toggle="pill"
                      href={`#pills-${cat.id}`}
                      role="tab"
                      aria-controls={`pills-${cat.id}`}
                      aria-selected="false"
                    >
                      {t(cat.name)}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <NoData msg="There are no cases" />
            )}
          </div>
          <div className="main-articles-tabs">
            <div className="tab-content" id="pills-tabContent">
              {casesByCategory.map((cbc, index) => (
                <div
                  className={`tab-pane fade ${
                    index === 0 ? "show active" : ""
                  }`}
                  id={`pills-${cbc.category}`}
                  role="tabpanel"
                  aria-labelledby={`pills-${cbc.category}-tab`}
                >
                  {!loading && (
                    <div className="main-articles-index">
                      <div className="news-items-grid">
                        {cbc.cases.map((ca) => (
                          <div
                            className="product-card news-card cursor-pointer"
                            onClick={() => navigate(`/casesDetails/${ca?.id}`)}
                          >
                            <div style={ca?.media?.fullUrl?{
                              backgroundImage: `url(${ca?.media?.fullUrl})`,
                            }:{}} className="product-card-image">
                              <ul className="product-card-labels">
                                {ca?.tags && ca?.tags.length >= 1
                                  ? ca.tags.map((tag) => {
                                      return <li>{tag.name}</li>;
                                    })
                                  : null}
                              </ul>
                              <img
                                loading="lazy"
                                src={ca?.media?.fullUrl || FallbackImage}
                                alt={"asasas"}
                                style={{
                                  backgroundColor: ca?.media?.fullUrl
                                    ? "unset"
                                    : "#81c4ee0f",
                                }}
                              />
                            </div>
                            <div className="product-card-data">
                              <div className="product-card-data-top justify-content-start gap-2">
                                <div className="product-card-title-wrap">
                                  <h2 className="product-card-title">
                                    {ca?.title}
                                  </h2>
                                </div>

                                {ca?.content && (
                                  <div
                                    className="product-card-description"
                                    dangerouslySetInnerHTML={{
                                      __html: ca ? ca.content : "",
                                    }}
                                  />
                                )}
                              </div>
                              <div className="product-card-data-bot">
                                <div className="product-card-meta">
                                  <div className="asset-wrap">
                                    <FaRegClock
                                      color="#7cc2ed"
                                      className="asset-icon"
                                    />
                                    <span>{formatDate(ca?.postTime)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      {!cbc.cases ||
                        (cbc.cases.length === 0 && (
                          <NoData msg="There are no cases for this category" />
                        ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
