import React, { useContext, useEffect, useState } from "react";
import { Container } from "@mui/material";
import "./PrivacyPolicy.css";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext"; // Import your CSS file for styling
import useAxiosWithInterceptor from "src/api/useAxiosWithInterceptor";
import NoData from "src/iComponents/NoData/NoData";

export default function PrivacyPolicy() {
  const { axiosInstance } = useAxiosWithInterceptor();
  const { t, direction,lang } = useContext(LocaleContext) as LocaleContextProps;

  const [data, setData]: any = useState(null);

  useEffect(() => {
    axiosInstance
      .get("api/Home/PrivacyPolicy",{params:{isArabic:lang==='ar'}})
      .then((response: any) => setData(response.data))
      .catch((error: any) => setData(null));
  }, [lang]);

  return (
    <div dir={direction} className="privacy-policy-page">
      <section
        className={`privacy-policy mr-section p-4 col-10 col-md-8 col-xl-6 mx-auto border shadow-sm rounded`}
      >
        <div className="title-center">
          <h2>{t("Privacy Policy")}</h2>
        </div>
        <hr />
        <Container maxWidth="xl" className="main-container">
          {data?.length >= 1 ? (
            <div dangerouslySetInnerHTML={{ __html: data }} />
          ) : (
            <div className="empty-state-wrapper">
              <NoData msg={t("no_data")} />
            </div>
          )}
          
        </Container>
      </section>
    </div>
  );
}
