export const objectToFormData = function(
    obj: Record<string, any>,
    form?: FormData,
    namespace?: string
) {
    const fd = form || new FormData();
    let formKey;

    for (const property in obj) {
        if ({}.hasOwnProperty.call(obj, property)) {
            if (namespace) {
                if (!isNaN(Number(property))) {
                    formKey = namespace + "[" + property + "]";
                } else {
                    formKey = namespace + "." + property;
                }
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File,
            // use recursivity.
            if (
                typeof obj[property] === "object" &&
                !(obj[property] instanceof File)
            ) {
                objectToFormData(obj[property], fd, formKey);
            } else if (obj[property] instanceof File) {
                fd.append(formKey, obj[property], (obj[property] as File).name);
            } else {
                // if it's a string or a File object
                fd.append(formKey, obj[property]);
            }
        }
    }

    return fd;
};