import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  LocaleContext,
  LocaleContextProps,
} from "../../contexts/LocaleContext";
import useProductsServices from "../../api/useProductsServices";
import { CartContext } from "../../contexts/CartContext";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import useTicketsAPI from "../../api/ems/tickets";
import NoData from "../../iComponents/NoData/NoData";

const modalStyle = {
  // position: 'absolute' as 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
  overflowY: "overlay",
  maxHeight: "100vh",
};

interface Product {
  maxQTYPerOrder: any;
  id: number;
  name: string;
  description: string;
  locations: any[];
  category: {
    id: number;
    index: number;
    name: string;
    pcTypeId: number;
    pcType: string;
    changeable: boolean;
  };
  totalQuantity: number;
  minQuantityPerOrder: number;
  maximumQuantityPerOrder: number;
  price: {
    totalPriceWithoutDiscount: any;
    displayTotalPriceWithoutDiscount: any;
    displayPrice: string;
    displayDiscount: string;
    displayTotalPrice: string;
    amount: number;
    discount: number;
    isDiscountPercentage: boolean;
    taxesId: number[];
    taxes: string;
    totalPrice: number;
    vatAmount: number;
  };
  currencyId: number;
  taxId: number;
  images: any[];
}

interface AddSessionProps {
  handleModal: (open: boolean) => void;
}

const AddSession = ({ handleModal }: AddSessionProps) => {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const navigate = useNavigate();
  const { addToCart } = useContext(CartContext);
  const [sessions, setSessions] = useState<Product[]>([]);
  const [checked, setChecked] = React.useState([1]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const { loadAvailableSessions } = useTicketsAPI();

  const addSessiontoCard = () => {
    const selectedSessions = sessions.filter((session) =>
      checked.includes(session.id)
    );
    for (const session of selectedSessions) {
      const image =
        session.images && session.images.length > 0
          ? session.images[0].fullUrl
          : "";

      addToCart({
        id: session.id,
        count: 1,
        name: session.name,
        image: image,
        selectedCheckedInDate: null,
        selectedInstructorId: null,
        selectedLocationId: null,
        selectedAddonsIds: [],
        addons: [],
        speakers: [],
        locations: [],
        maxQTYPerOrder: session.maxQTYPerOrder,
        minQTYPerOrder: session.maxQTYPerOrder,
        price: session.price,
      });
    }
    navigate("/cart");
  };
  useEffect(() => {
    loadAvailableSessions()
      .then((data) => {
        const fetchedSession = data.items
          ? data.items.filter((item: any) => item.category.id === 4)
          : [];
        setSessions(fetchedSession);
      })
      .catch(() => toast.error(t("Error getting Sessions")));
  }, []);

  return (
    <div className="modal-container" dir={direction}>
      <Box sx={modalStyle}>
        <div className="animate__animated animate__fadeIn" id="">
          <div
            className="space-between"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ marginBottom: "10px" }}
            >
              {t("Select Session")}
            </Typography>

            <IconButton onClick={() => handleModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <br />

          <div className="container">
            <List
              dense
              sx={{
                width: "100%",
                maxWidth: "100%",
                bgcolor: "background.paper",
              }}
            >
              {sessions.length > 0 ? (
                sessions.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value.id}`;
                  return (
                    <ListItem
                      key={value.id}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(value.id)}
                          checked={checked.indexOf(value.id) !== -1}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton onClick={handleToggle(value.id)}>
                        <ListItemAvatar>
                          <Avatar
                            alt={`${value.name}`}
                            src={`${
                              value.images?.length >= 1 ? value.images[0] : ""
                            }`}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          id={labelId}
                          primary={value.name}
                          secondary={value.description}
                        />
                        <ListItemText
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: 1,
                          }}
                          id={labelId}
                          primary={t("Price") + " :"}
                          secondary={value.price.displayPrice}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })
              ) : (
                <NoData msg="there is no session available for now" />
              )}
            </List>
          </div>

          {sessions?.length > 0 && (
            <>
              <br />
              <div className={`form-group row `}>
                <div className="btn-edit-profile addCase-buttons">
                  <button
                    className={`ctm-btn2`}
                    onClick={() => handleModal(false)}
                  >
                    {t("Cancel")}
                  </button>
                  <div>
                    <button className={`ctm-btn`} onClick={addSessiontoCard}>
                      {t("Check out")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Box>
    </div>
  );
};

export default AddSession;
