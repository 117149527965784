import useAxiosWithInterceptor from "../useAxiosWithInterceptor";
import { AxiosResponse } from "axios";
import { PaginatedResult } from "./PaginatedResult";

const useOrdersAPI = () => {
  const { axiosInstance } = useAxiosWithInterceptor();

  const loadOrderById = (orderId: any): Promise<any> =>
    axiosInstance
      .get(`api/Orders/CurrentUserOrder/${orderId}`)
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const loadMyOrders = (): Promise<PaginatedResult<any>> =>
    axiosInstance
      .get("api/Orders/CurrentUserList")
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const printOrderInvoice = (invoiceId: number): any =>
    axiosInstance
      .get(`api/Invoices/InvoiceTemplate/${invoiceId}`)
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const getInvoiceByOrderId = (orderId: any): any =>
    axiosInstance
      .get(`api/Invoices/ByOrder/${orderId}`)
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  return { loadMyOrders, loadOrderById, printOrderInvoice, getInvoiceByOrderId };
};

export default useOrdersAPI;
