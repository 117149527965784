// documentContents.ts

const documentContents = [
  {
    title: "Our company starts its operations",
    date: "11 March 2020",
    shortDescription: "lorem ipusm lorem",

    content: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?


    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
    necessitatibus adipisci, ad alias, voluptate pariatur officia
    repellendus repellat inventore fugit perferendis totam dolor
    voluptas et corrupti distinctio maxime corporis optio?
                
               
                `,
  },
  {
    title: "First customer",
    date: "19 March 2020",
    shortDescription: "lorem ipusm lorem",

    content: `Quisque ornare dui nibh, sagittis egestas nisi luctus nec. Sed
                aliquet laoreet sapien, eget pulvinar lectus maximus vel.
                Phasellus suscipit porta mattis.`,
  },
  {
    title: "Our team exceeds 10 people",
    date: "24 June 2020",
    shortDescription: "lorem ipusm lorem",
    content: `Orci varius natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Nulla ullamcorper arcu lacus, maximus
                facilisis erat pellentesque nec. Duis et dui maximus dui aliquam
                convallis. Quisque consectetur purus erat, et ullamcorper sapien
                tincidunt vitae.`,
  },
  {
    title: "Earned the first million $!",
    date: "15 October 2020",
    shortDescription: "lorem ipusm lorem",
    content: `Nulla ac tellus convallis, pulvinar nulla ac, fermentum diam. Sed
                et urna sit amet massa dapibus tristique non finibus ligula. Nam
                pharetra libero nibh, id feugiat tortor rhoncus vitae. Ut suscipit
                vulputate mattis.`,
  },
];

export default documentContents;
