/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';

import PaymentMethod from '../../components/PaymentMethod/PaymentMethod';
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import "./Payment.css";
import { CartContext } from "../../contexts/CartContext";
import { UserContext, UserContextProps } from 'src/contexts/UserContext';
import useUserCartAPI from 'src/api/ems/user-cart';
import { synchronizeCarts } from '../Cart/utils';

export default function PaymentPage() {
    const {t, direction,isRtl} = useContext(LocaleContext) as LocaleContextProps;
    const { isAuthenticated } = useContext(UserContext) as UserContextProps;
    const { hasBookingItem } = useContext(CartContext);
    const {
        cartItems,
        setCartItems,
        setBackendPriceDetails,
      } = useContext(CartContext);
    const {
        loadReadyUserCart,
        addToUserCart,
        updateToUserCart,
        deleteFromUserCart,
    
      } = useUserCartAPI();


    useEffect(() => {
        if (isAuthenticated) {
          loadReadyUserCart().then((response) => {
            synchronizeCarts({
              backendCart: response,
              cartItems,
              setCartItems,
              addToUserCart,
              updateToUserCart,
              deleteFromUserCart,
              setBackendPriceDetails,
              loadReadyUserCart,
            });
          }).catch((error => console.error('ERROR', error)));
        }
      }, [isAuthenticated,isRtl]);
    return (
        <div dir={direction}>
            <section className="payment-page mr-section">
                <Container maxWidth="xl" className="main-container">
                    <div className="order-tracking">
                        <ul>
                            <li className="completed">
                                <Link to="/cart">
                                    <div className="img-order-tracking">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={27}
                                            height={27}
                                            fill="currentColor"
                                            className="bi bi-cart3"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                                            />
                                        </svg>
                                    </div>
                                    <h2>
                                        {t("Your Cart")} <span/>
                                    </h2>
                                </Link>
                            </li>
                            {hasBookingItem &&
                            <li className="completed">
                                <Link to="/booking">
                                    <div className="img-order-tracking">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={27}
                                            height={27}
                                            fill="currentColor"
                                            className="bi bi-calendar4-week"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z"
                                            />
                                        </svg>
                                    </div>
                                    <h2>
                                        {t("Preferences")} <span/>
                                    </h2>
                                </Link>
                            </li>
                            }
                            <li className="active">
                                <Link to="/payment">
                                    <div className="img-order-tracking">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={27}
                                            height={27}
                                            fill="currentColor"
                                            className="bi bi-credit-card"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"
                                            />
                                        </svg>
                                    </div>
                                    <h2>
                                        {t("Payment")} <span/>
                                    </h2>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <PaymentMethod/>
                </Container>
            </section>
        </div>
    );
}
