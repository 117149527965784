import React, { useContext, useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import toast from "react-hot-toast";

import { FormMode } from "./LoginSM";
import {
  LocaleContext,
  LocaleContextProps,
} from "../../contexts/LocaleContext";
import {
  ResetPasswordContext,
  ResetPasswordContextProps,
} from "../../contexts/ResetPasswordContext";
import useAuthServices from "src/api/useAuthServices";

export function OtpVerify({ routeBack, verifyType }: any) {
  const { t } = useContext(LocaleContext) as LocaleContextProps;
  const { infos, setInfos } = useContext(
    ResetPasswordContext
  ) as ResetPasswordContextProps;
  const [userInput, setUserInput] = useState("");
  const inputRefs: any = [useRef(), useRef(), useRef(), useRef()];
  const digitValidate = (_e: any, index: number) => {
    const enteredDigit = _e.target.value;
    setUserInput((prevInput) => {
      const updatedInput = prevInput.split("");
      updatedInput[index] = enteredDigit;
      return updatedInput.join("");
    });

    // Move focus to the next input if not the last one
    if (index < inputRefs.length - 1 && enteredDigit !== "") {
      inputRefs[index + 1].current.focus();
    }
  };

  const { verifyOTPCall, verifySignup } = useAuthServices();

  const verifyOTP = () => {
    // e.preventDefault();
    if (userInput.length === 4) {
      if (verifyType === FormMode.Verification) {
        verifyOTPCall(infos.email, infos.phone, userInput.toString())
          .then(() => {
            toast.success(t("Correct verification code"));
            routeBack();
          })
          .catch(() => toast.error(t("Invalid verification code")));
      }
      if (verifyType === FormMode.SignUpVerfiy) {
        verifySignup(infos.email, userInput.toString())
        .then(() => {
          toast.success(t("Correct verification code"));
          routeBack();
        })
        .catch(() => toast.error(t("Invalid verification code")));
      }

    }
  };

  // Set initial focus on the first input
  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  useEffect(() => {
    // Check if the last input is filled
    if (userInput.length === inputRefs.length) {
      verifyOTP();
      setInfos({...infos, token: userInput.toString()});
    }
  }, [userInput]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
      className="animate__animated animate__fadeIn"
      id="smForm"
    >
      <b>{t("Please enter verification code")}</b>
      <div className="otp_input">
        {inputRefs.map((ref: any, index: any) => (
          <input
            key={index}
            className="otp"
            type="text"
            onInput={(e) => digitValidate(e, index)}
            maxLength={1}
            ref={ref}
          />
        ))}
      </div>

      <motion.button
        onClick={(e) => {
            e.preventDefault();
            verifyOTP();
        }}
        className="LoginSMsigin-btn"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {t("Confirm")}
      </motion.button>
    </motion.div>
  );
}
