import React, {useState} from "react";
import Loading from "../components/Loading/Loading";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

interface LayoutProps {
    children: React.ReactNode;
}

export const GeneralLayout = ({children}: LayoutProps) => {
    const [loading, setLoading] = useState(false);

    return (
        <div>
            <Loading isLoading={loading} setIsLoading={setLoading}/>
            {!loading &&
                <>
                    <Header/>
                    {children}
                    <Footer/>

                </>
            }
        </div>
    );
}