import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBell, FaCompress, FaExpand, FaTimes } from "react-icons/fa";
import "animate.css/animate.min.css";
import "./CourseDetailsHeader.css";
import "react-dropdown/style.css";
import searchIcon from "./assets/searchIcon.svg";
import Notifications from "../Notifications/Notifications";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import BasicSelect from "../BasicSelect/BasicSelect";
import {SwipeableDrawer} from "@mui/material";
const CourseDetailsHeader: React.FC = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        // Handle dropdown click outside logic here
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () =>
      document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDrawer = () => {
    setShowNotifications(!showNotifications);
  };

  const handleFullscreenToggle = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
    setIsFullscreen((prev) => !prev);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-brand">
          {/* <div className="nav-logo">
            <img
              loading="lazy"
              src={logo}
              alt="logo"
              className="logo-img"
            />
          </div> */}
        </Link>
        <div className="nav-items">
          <button
            className="nav-button"
            id="FullScreenButton"
            onClick={handleFullscreenToggle}
          >
            {isFullscreen ? <FullscreenExitIcon fontSize={"large"} /> : <FullscreenIcon fontSize={"large"} />}
          </button>

          {/* <button
            className="nav-button cart-header"
            onClick={toggleDrawer}
          >
            <NotificationsIcon fontSize={"large"}/>
            <span className="cont-header">1</span>
          </button> */}

          {/* Language Dropdown */}
          <div ref={dropdownRef}>
            <BasicSelect />
            {/*<Dropdown*/}
            {/*  options={languageOptions}*/}
            {/*  value={selectedLanguage}*/}
            {/*  onChange={handleLanguageChange}*/}
            {/*  placeholder={t("Select language")}*/}
            {/*/>*/}
          </div>
          {/* End of Language Dropdown */}

          <button className="nav-button">
            <div className="input-container">
              <input
                type="text"
                name="text"
                className="input"
                placeholder="Search something..."
                value={searchInput}
                onChange={(e) => {
                  const input = e.target.value;
                  setSearchInput(input);

                  if (input.trim() === "") {
                    // setMatchedNotifications([]);
                  } else {
                    // const matched = notifications.filter(
                    //   (notification) =>
                    //     notification.content
                    //       .toLowerCase()
                    //       .includes(input.toLowerCase())
                    // );
                    // setMatchedNotifications(matched);
                  }
                }}
              />
              {/*<SearchIcon/>*/}
              <img
                loading="lazy"
                src={searchIcon}
                alt="searchIcon"
                className="icon"
              />
            </div>
          </button>
        </div>
      </div>


      <SwipeableDrawer
          open={showNotifications}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
      >
        <Notifications showNotifications={showNotifications} setShowNotifications={setShowNotifications}/>
      </SwipeableDrawer>

      {/*{showNotifications && (*/}
      {/*  <Notifications showNotifications={showNotifications} setShowNotifications={setShowNotifications}/>*/}
      {/*)}*/}
    </nav>
  );
};

export default CourseDetailsHeader;
