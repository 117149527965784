/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { motion } from 'framer-motion'; // Import from framer-motion
import avatar from '../../assets/svg/avatar.svg';
import { FaEdit } from 'react-icons/fa';
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import { UserContext, UserContextProps } from "../../contexts/UserContext";

interface MyAccountProps {
  showEditProfile: () => void;
}

const MyAccount: React.FC<MyAccountProps> = ({ showEditProfile }: MyAccountProps) => {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const { currentUser } = useContext(UserContext) as UserContextProps;


  return (
    <motion.div // Apply animation to the user information card
      // className="col-lg-8"
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      dir={direction}
    >
      {/*<motion.div // Add a new motion animation to the user details*/}
      {/*    initial={{opacity: 0, y: 20}}*/}
      {/*    animate={{opacity: 1, y: 0}}*/}
      {/*    transition={{duration: 0.5, delay: 0.2}}*/}
      {/*    // className="myprofile"*/}
      {/*>*/}
      <div className="title-myprofile">
        <h3>{t('My Profile :')}</h3>
        <a onClick={showEditProfile} className="edit-link" style={{ cursor: "pointer" }}>
          <FaEdit/> {t('Edit Profile')}
        </a>
      </div>

      <div className="details-myprofile">
        <div className="img-details-myprofile">
          <img src={currentUser?.image ?? avatar} alt="User Profile"/>
        </div>
        <ul style={{ listStyleType: "none", padding: 0 }} dir={direction}>
          <li>
            <span className="label">{t('FullName')} :</span>
            <span className="value">{currentUser?.unique_name}</span>
          </li>
          <li>
            <span className="label">{t('Bio')} :</span>
            <span className="value">{currentUser?.bio}</span>
          </li>
          <li>
            <span className="label">{t('Email')} :</span>
            <span className="value">{currentUser?.email}</span>
          </li>
          <li>
            <span className="label">{t('Mobile no.')} :</span>
            <span className="value">{currentUser?.mobilephone}</span>
          </li>
          <li>
            <span className="label">{t('Country')} :</span>
            <span className="value">{t('Saudi Arabia')}</span>
          </li>
          <li>
            <span className="label">{t('SCFHS ID')} :</span>
            <span className="value">{currentUser?.scfhsid}</span>
          </li>
        </ul>
      </div>
      {/*</motion.div>*/}
    </motion.div>
  );
};

export default MyAccount;
