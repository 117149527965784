import useAxiosWithInterceptor from "../useAxiosWithInterceptor";
import {PaginatedResult} from "./PaginatedResult";
import {ICase} from "./schemas";
import {AxiosResponse} from "axios";

export interface INews {
    id: number;
    pType: {
        id: number;
        name: string;
    };
    postCategory: {
        id: number;
        pType: string;
        name: string;
    };
    title: string;
    content: string;
    postTime: string;
    status: {
        id: number;
        name: string;
    };
    authors: {
        id: number;
        email: string | null;
        displayName: string | null;
    }[];
    commentsCount: number;
    likesCount: number;
    currentUserLike: boolean;
    tags: any[];
    directLink: any;
    youtubeLink: any;
    media: any;
    isPinned: boolean;
}

export interface ICasesByCategory {
    category: number;
    cases: INews[];
}


const useNewsAPI = () => {
    const {axiosInstance} = useAxiosWithInterceptor();


    const loadNews = async (pType: number, postCategoryId: number | null): Promise<PaginatedResult<INews>> => {
        let url = "api/Home/NewsCases?PTypeId="+pType;
        url += postCategoryId ? "&PostCategoryId=" + postCategoryId : "";
        const response = await axiosInstance.get(
            url,
        );
        return response.data;
    };


    const loadNewsDetails = (id: string | undefined): Promise<INews> =>
        axiosInstance
            .get("api/Home/NewsCases/"+id)
            .then((response: AxiosResponse<INews>) => response.data)
            .catch((error: any) => {
                throw error;
            });


    return { loadNews, loadNewsDetails };
};

export default useNewsAPI;
