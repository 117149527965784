import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/main.css'
import './styles/general.css'
import './styles/responsive.css'
import './styles/footer.css'
import './styles/ar.css'
import './styles/en.css'
import './styles/header.css'
import './styles/videos.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'aos/dist/aos.css';
import 'bootstrap'; // For bootstrap.min.js
import 'jssor-slider'; // For jssor.slider-28.0.0.min.js
import 'owl.carousel'; // For owl.carousel.min.js
import AOS from 'aos';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import './index.css';
import './styles/newStyle.scss';

import { BrowserRouter as Router } from "react-router-dom";
import { ScrollProvider } from "./contexts/ScrollContext";
import { LocaleProvider } from "./contexts/LocaleContext";
import { UserProvider } from "./contexts/UserContext";
import { CartProvider } from "./contexts/CartContext";
import { Toaster } from "react-hot-toast";
import { isMobile } from 'react-device-detect';
import { ServerProvider } from "./contexts/ServerContext";

AOS.init();
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

root.render(
  // <React.StrictMode>
  <Router basename={baseUrl || undefined}>
    <ServerProvider>
      <LocaleProvider>
        <ScrollProvider>
          <UserProvider>
            <CartProvider>
              <Toaster
                position={"top-center"}
                containerStyle={isMobile ? { top: '64px' } : {}}
                reverseOrder={false}
              />
              <App/>
            </CartProvider>
          </UserProvider>
        </ScrollProvider>
      </LocaleProvider>
    </ServerProvider>
  </Router>
  // </React.StrictMode>
);

reportWebVitals();
