import React, {useContext} from 'react';
import {LocaleContext, LocaleContextProps} from "../../contexts/LocaleContext";

interface CarouselItemProps {
  title: string;
  imgSrc: string;
  bgImgSrc: string;
}

const CarouselItem: React.FC<CarouselItemProps> = ({ title, imgSrc, bgImgSrc }) => {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  return (
    <div className="item" dir={direction}>
      <div className="sub-services-index">
        <div className="img-services-index">
          <img loading="lazy" src={imgSrc} alt={title} />
        </div>
        <h2 data-name={t(title)}>{t(title)}</h2>
        <div className="bg-img-services">
          <img loading="lazy" src={bgImgSrc} alt={title} />
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
