/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import comfirem from "./assets/comfirem.svg";
import { Container } from "@mui/material";
import { LocaleContext, LocaleContextProps } from "src/contexts/LocaleContext";
import { Cancel } from "@mui/icons-material";

export default function ConfirmPayment() {
  const location = useLocation();
  const { direction, t } = useContext(LocaleContext) as LocaleContextProps;
  const locationState = location.state

  const searchParams = new URLSearchParams(location.search);
  const id = locationState.id;
  const message = locationState.message;
  const status = locationState.status;


  return (
    <div>
      <section className="comfirm-payment mr-section" dir={direction}>
        <Container maxWidth="xl" className="main-container mt-2 d-flex justify-content-center align-items-center">

          {id && (
              <div className="main-comfirm-payment">
                <div className="img-comfirm-payment">
                  {status === "failed" ? (
                    <Cancel style={{ color: "red", fontSize: 60 }}/>
                  ) : (
                    <img loading="lazy" src={comfirem} alt=""/>
                  )}
                </div>
                {status === "failed" ? (
                  <>
                    <h2>Payment Failed</h2>
                    <p>{message}</p>
                  </>
                ) : (
                  <>
                    <h2>{t("Your Payment is Successful !")}</h2>
                    <h3>{t("Your request has been processed successfully.")}</h3>
                    <p>{t("You'll receive a confirmation email shortly.")}</p>
                  </>
                )}
                <Link to={status === "failed" ? '/payment' : '/myorders'} className="ctm-btn">
                  {t(status === "failed" ? "Return to payment" : "GO_TO_ORDER")}
                </Link>
              </div>
            )
          }

        </Container>
      </section>
    </div>
  );
}
