import { motion } from "framer-motion";
import React, { useContext, useState } from "react";
import { FormMode } from "./LoginSM";
import { MotionInput } from "../../iComponents/MotionInput";
import {
  LocaleContext,
  LocaleContextProps,
} from "../../contexts/LocaleContext";
import useAuthServices from "../../api/useAuthServices";
import toast from "react-hot-toast";
import {
  ResetPasswordContext,
  ResetPasswordContextProps,
} from "../../contexts/ResetPasswordContext";

export function ForgotPassword({ setError, setFormMode }: any) {
  const { t } = useContext(LocaleContext) as LocaleContextProps;
  const { sendforgetPasswordVerification } = useAuthServices();
  const { infos, setInfos } = useContext(
    ResetPasswordContext
  ) as ResetPasswordContextProps;

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const [forgotPasswordForm, setForgotPasswordForm] = useState({
    email: "",
    mobile: null,
  });

  const sendForgotPassVerification = (e: any) => {
    e.preventDefault();
    const errors = Object.values(validationErrors).every(
      (value) => value !== ""
    );

    if (!errors) {
      sendforgetPasswordVerification(forgotPasswordForm.email, null)
        .then((res) => {
          setError("");
          setValidationErrors({});
          setInfos({ ...infos, email: forgotPasswordForm.email, phone: null });
          toast.success(t("Verification code sent successfully!"));
          setFormMode(FormMode.Verification);
        })
        .catch((err) => {
          toast.error(t("Bad Request: Invalid request. Please try again."));
        });
    }
  };

  const handleChange = (e: any) => {
    setForgotPasswordForm({
      ...forgotPasswordForm,
      [e.target.name]: e.target.value,
    });

    setValidationErrors({
      ...validationErrors,
      [e.target.name]: e.target.value ? "" : "required",
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
      className="animate__animated animate__fadeIn"
      id="smForm"
    >
      <b>{t("Please enter your email to send verification code")}</b>
      <MotionInput
        form={forgotPasswordForm}
        name="email"
        placeholder={t("emailPlaceholder")}
        handleChange={handleChange}
        validationErrors={validationErrors}
      />

      {/*<MotionInput*/}
      {/*    form = {forgotPasswordForm}*/}
      {/*    name = "phoneNumber"*/}
      {/*    placeholder = {t("MobileNoPlaceholder")}*/}
      {/*    handleChange = {handleChange}*/}
      {/*    validationErrors = {validationErrors}*/}
      {/*/>*/}

      <motion.button
        onClick={sendForgotPassVerification}
        className="LoginSMsigin-btn"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {t("send")}
      </motion.button>

      <p className="LoginSMsignup-link">
        {t("rememberPassword")}

        <a
          style={{ color: "#398dde" }}
          onClick={() => setFormMode(FormMode.SignIn)}
        >
          {t("signIn")}
        </a>
      </p>
    </motion.div>
  );
}
