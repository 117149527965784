/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import useNewsAPI, { INews } from "../../api/ems/news";
import NoData from "../../iComponents/NoData/NoData";
import FallbackImage from "../../assets/images/imagefallback.png";
import { useNavigate } from "react-router";
import { FaRegClock } from "react-icons/fa";
import { formatDate } from "src/utils/dateUtils";

export default function NewsSection() {
  const { t, direction, lang } = useContext(LocaleContext) as LocaleContextProps;
  const { loadNews } = useNewsAPI();
  const navigate = useNavigate();

  const [news, setNews] = useState<INews[]>([]);
  const [pinnedNews, setPinnedNews] = useState<INews | null>(null);

  useEffect(() => {
    loadNews(0, null)
      .then((news) => {
        //TODO: pick pinned news from api
        setNews(news.items);
        setPinnedNews(news.items.filter((nws) => nws.isPinned)[0]);
      })
      .catch((err) => {
      });
  }, []);

  return (
    <div id="NewsSection">
      <section className="news-index mr-section " dir={direction}>
        <div className="main-container">
          <div className="title-center">
            <h3>{t("News")}</h3>
            <h2>{t("Latest News & Achievements")}</h2>
          </div>

          {news && news.length > 0 ? (
            <div className="homepage-news-wrapper">
              {pinnedNews ? (
                <div className="pinned-news-items">
                  <div
                    className="product-card news-card cursor-pointer"
                    onClick={() => navigate(`/newsDetails/${pinnedNews?.id}`)}
                  >
                    <div style={pinnedNews?.media?.fullUrl ? {
                      backgroundImage: `url(${pinnedNews?.media?.fullUrl})`,
                    } : {}} className="product-card-image">
                      <img
                        loading="lazy"
                        src={pinnedNews?.media?.fullUrl || FallbackImage}
                        alt={"asasas"}
                        style={{
                          backgroundColor: pinnedNews?.media?.fullUrl
                            ? "unset"
                            : "#81c4ee0f",
                        }}
                      />
                    </div>
                    <div className="product-card-data">
                      <div className="product-card-data-top">
                        <div className="product-card-title-wrap">
                          <h2 className="product-card-title">
                            {pinnedNews?.title}
                          </h2>
                        </div>

                        {pinnedNews?.content && (
                          <div
                            className="product-card-description"
                            dangerouslySetInnerHTML={{
                              __html: pinnedNews ? pinnedNews.content : "",
                            }}
                          />
                        )}
                      </div>
                      <div className="product-card-data-bot">
                        <div className="product-card-meta">
                          <div className="asset-wrap">
                            <FaRegClock
                              color="#7cc2ed"
                              className="asset-icon"
                            />
                            <span>
                              {pinnedNews && formatDate(pinnedNews?.postTime, lang)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className={`${pinnedNews ? 'news-items-list' : 'news-items-grid'}`}>
                {news.map((nws) => (
                  <div
                    className={`product-card news-card cursor-pointer ${pinnedNews ? 'horizontal' : ''}`}
                    onClick={() => navigate(`/newsDetails/${nws?.id}`)}
                  >
                    <div style={nws?.media?.fullUrl ? {
                      backgroundImage: `url(${nws?.media?.fullUrl})`,
                    } : {}} className="product-card-image">
                      <img
                        loading="lazy"
                        src={nws?.media?.fullUrl || FallbackImage}
                        alt={"asasas"}
                        style={{
                          backgroundColor: nws?.media?.fullUrl
                            ? "unset"
                            : "#81c4ee0f",
                        }}
                      />
                    </div>
                    <div className="product-card-data">
                      <div className="product-card-data-top justify-content-start gap-2">
                        <div className="product-card-title-wrap">
                          <h2 data-toggle="tooltip" data-placement="top" title={nws?.title}
                              className="product-card-title">{nws?.title}</h2>
                        </div>
                        <div className="product-card-meta">
                          <div className="asset-wrap">
                            <FaRegClock
                              color="#7cc2ed"
                              className="asset-icon"
                            />
                            <span>
                              {nws && formatDate(nws?.postTime, lang)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <NoData msg="There is no news yet"/>
          )}
        </div>
      </section>
    </div>
  );
}
