/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Partner } from "./Partner";
import "./partners.css";

import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import useSponsorsApi from "src/api/ems/sponsers";
import NoData from "src/iComponents/NoData/NoData";

declare global {
  interface Window {
    $: any;
  }
}

export default function PartnersSection() {
  const { t, isRtl, direction } = useContext(LocaleContext) as LocaleContextProps;
  const { loadSponsersHome } = useSponsorsApi();
  const [sponsors, setSponsors] = useState<any[]>([]);


  useEffect(() => {
    loadSponsersHome()
      .then((response) => {
        setSponsors(response);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div>
      <section className="partners-index mr-section" dir={direction}>
        <div className="main-container">
          <div className="title-center">
            <h3>{t("partners")}</h3>
          </div>

          <div className="main-partners-index">
            {sponsors.length > 0 ? (
              <>
                <OwlCarousel
                  loop={true}
                  autoplay
                  className="owl-theme maincarousel"
                  id="slider-partners"
                  margin={16}
                  center
                  dots={false}
                  rewind={isRtl}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    450: {
                      items: 2,
                    },
                    768: {
                      items: 4,
                    },
                    1000: {
                      items: 5,
                    },

                  }}
                >
                  {sponsors.map((sponsor) => (
                    <Partner
                      key={sponsor.id}
                      title={sponsor.name}
                      img={sponsor?.logo?.fullUrl}
                      details={sponsor.description}
                    />))}
                </OwlCarousel>
              </>

            ) : (
              <NoData msg="There is no Sponsors yet"/>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
