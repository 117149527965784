import React, { useContext } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import SidebarMyaccount from "../components/SidebarMyaccount/SidebarMyaccount";
import { Container } from "@mui/material";
import { LocaleContext, LocaleContextProps } from "../contexts/LocaleContext";

export const AccountLayout = ({ children }: any) => {

  const { direction } = useContext(LocaleContext) as LocaleContextProps;
  /*const { currentUser, setCurrent } = useContext(UserContext) as UserContextProps;
  const { getProfile } = useAuthServices();*/
  /*useEffect(() => {
    currentUser && getProfile(currentUser?.nameid).then(
      data => {
        setCurrent(prevState => ({ ...prevState!, image: data.image?.fullUrl }))
      }
    )
  }, [currentUser])*/


  return (
    <div>
      <Header/>
      <div>

        <section className="main-myaccount mr-section " dir={direction}>
          <Container maxWidth="xl" className="main-container">
            {/* <div className="profile-layout-wrap">
              <div className="profile-layout-sidebar"><SidebarMyaccount/></div>
              <div className="profile-layout-content">{children}</div>
            </div> */}
            <div className="main-myaccount">
              <div className="profile-layout-wrap">
                <div className="profile-layout-sidebar">
                  <SidebarMyaccount/>
                </div>
                <div className="profile-layout-content">
                  {children}
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Footer/>
    </div>
  );
}