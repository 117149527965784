import useAxiosWithInterceptor from "../useAxiosWithInterceptor";
import { AxiosResponse } from "axios";
import { PaginatedResult } from "./PaginatedResult";
import { IPerson, ISpeaker } from "./schemas";
import { ScheduleItem } from "../../components/DateTimePicker/DateTimePicker";

const speakersURL = 'api/Speakers/WithoutEvent';
const speakersScheduleURL = '/api/Speakers/Schedule/'

const useSpeakersAPI = () => {
    const {axiosInstance} = useAxiosWithInterceptor();

    const loadHomeSpeakers = (request?:{pageIndex?:number,limit?:number}) : Promise<PaginatedResult<ISpeaker>> =>
        axiosInstance
            .get("api/Home/Speakers", {params:request})
            .then((response: AxiosResponse<PaginatedResult<ISpeaker>>) => response.data)
            .catch((error: any) => {
                throw error;
            });

    const loadSpeakers = (): Promise<PaginatedResult<IPerson>> =>
        axiosInstance
            .get(speakersURL)
            .then((response: AxiosResponse<any>) => response.data)
            .catch((error: any) => {
                throw error;
            });

  const loadSpeakerSchedule = (id:number): Promise<ScheduleItem[]> =>
    axiosInstance
      .get(`${speakersScheduleURL}${id}`)
      .then((response: AxiosResponse) => response.data)
      .catch((error: any) => {
        throw error;
      });

    return { loadSpeakers, loadHomeSpeakers,loadSpeakerSchedule };
};

export default useSpeakersAPI;
