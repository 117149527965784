import React, {useRef} from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from '@ckeditor/ckeditor5-build-classic';

const EditorInput = ({content, changeContent}) => {
    const editorRef = useRef();
    return (
        <CKEditor
            ref={editorRef}
            id="CKCaseEditor"
            editor={Editor}
            config={{
                extraPlugins: [uploadPlugin],
            }}

            data={content}
            onChange={(event, editor) => {
                const data = editor.getData();
                changeContent(data);
            }}
        />
    );
};

export default EditorInput;

const uploadAdapter = (loader) => {
    return {
        upload: async () => loader.file
            .then(file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve({default: reader.result});
                };
                reader.onerror = error => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            })),
        abort: () => {
        }
    };
};

function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
    };
}
