import React, { useContext, useState } from 'react';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { makeStyles } from '@mui/styles';
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 120,
  },
  formControl: {
    width: '100%',
    position: 'relative', 
  },
  select: {
    boxShadow: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    zIndex: 0,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    margin: '0 8px',
  },
}));

export default function LanguageSelector() {
  const { lang, changeLang, t, direction, i18n } = useContext(LocaleContext) as LocaleContextProps;

  const classes = useStyles();
  // const { i18n } = useTranslation();

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    changeLang(selectedValue);
    i18n.changeLanguage(selectedValue).then(()=>localStorage.setItem('selectedLanguage', selectedValue));
  };

  return (
    <Box className={classes.root} dir={direction}>
      <FormControl className={classes.formControl}>
        {/* <InputLabel id="language-selector-label">{t('Select Language')}</InputLabel> */}
        <Select
          dir={direction}
          labelId="language-selector-label"
          id="language-selector"
          value={lang || ''}
          onChange={handleChange as any}
          className={classes.select}
          onClick={() => setIsSelectOpen(true)}
          onClose={() => setIsSelectOpen(false)}
          classes={{icon:classes.icon}}
        >
          <MenuItem value="en" className={classes.menuItem} dir={direction}>
            {/*<LanguageIcon className={classes.icon} />*/}
            <span>
              {t('English')}
            </span>
          </MenuItem>
          <MenuItem
            value="ar" className={`${classes.menuItem}`} dir={direction}>
            <span>
              {t('العربية')}
            </span>
          </MenuItem>
        </Select>
        <AnimatePresence>
          {isSelectOpen && (
            <motion.div
              className={classes.backdrop}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          )}
        </AnimatePresence>
      </FormControl>
    </Box>
  );
}
