/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'

import ar1 from './assets/ar1.png'

import ah1 from './assets/ah1.png'
import { Container } from '@mui/material'
import {
  FaFacebookF,
  FaInstagramSquare,
  FaLinkedinIn,
  FaRegClock,
  FaRegUserCircle,
  FaShareAlt,
  FaSnapchat,
  FaTwitter
} from 'react-icons/fa';
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useCasesAPI from "../../api/ems/cases";
import { ICase } from "../../api/ems/schemas";
import { formatDate } from "../../utils/dateUtils";
import n1Image from "../AchievementsDetails/assets/n3.png";


export default function CasesDetails() {
  const { id } = useParams();
  const { t, direction, lang } = useContext(LocaleContext) as LocaleContextProps;
  const { loadCaseDetails } = useCasesAPI();

  const [caseDetails, setCaseDetails] = useState<ICase | null>(null);


  useEffect(() => {
    if (id) {
      loadCaseDetails(id)
        .then(data => setCaseDetails(data))
        .catch(err => toast.error(t("Couldn't load case with ID" + id)));
    }
  }, [])



  return (
    <div dir={direction}>
      <section className="articles-details mr-section mx-auto w-75">
        <Container maxWidth="xl" className="main-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-articles-details">

                <h1 className="mt-0">
                  {caseDetails
                    ? caseDetails.title
                    : t('The Title Of This Event')}
                </h1>
                <div className="data-and-article-author">
                  <ul className="mb-0">
                    <li className="fs-5">
                      <FaRegClock
                        color="#7cc2ed"
                        className="asset-icon"
                      />{" "}
                      {caseDetails
                        ? formatDate(caseDetails.postTime, lang)
                        : t('Sun, 12 May 2023')}
                    </li>
                    {caseDetails &&
                    caseDetails.authors && caseDetails.authors[0] &&
                    caseDetails.authors[0].displayName ? (
                      <li className="fs-5">
                        <FaRegUserCircle/> {t('Author')} :{' '}
                        {caseDetails.authors[0].displayName}
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>

                <div style={{
                  backgroundImage: `url(${caseDetails && caseDetails.media
                    ? caseDetails.media.fullUrl
                    : n1Image})`,
                }} className="img-achievements-details my-4">
                  <img
                    loading="lazy"
                    src={
                      caseDetails && caseDetails.media
                        ? caseDetails.media.fullUrl
                        : n1Image
                    }
                    alt=""
                  />
                  <ul className="labels-wrap">
                      {caseDetails &&
                        caseDetails.tags.map((tag: any) => <li className="bg-dark">{tag.name}</li>)}
                        {
                          caseDetails && <li>{caseDetails.postCategory.name}</li>
                        }
                    </ul>
                </div>
                <div className="text-achievements-details ">
                  <div className="p-1 ">
                    <div
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: caseDetails
                          ? caseDetails.content
                          : t('lorem'),
                      }}
                    />
                  </div>

                </div>
                {/*<h2>Fig.1 :</h2>*/}
                {/*<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem*/}
                {/*    Ipsum has been the industry's standard dummy text ever since the 1500s, when an*/}
                {/*    unknown printer took a galley of type and scrambled it to make a type specimen*/}
                {/*    book.</p>*/}
                {/*<img loading="lazy" src={ard1} alt=""/>*/}
                {/*<h2>Fig.2 :</h2>*/}
                {/*<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem*/}
                {/*    Ipsum has been the industry's standard dummy text ever since the 1500s, when an*/}
                {/*    unknown printer took a galley of type and scrambled it to make a type specimen*/}
                {/*    book.</p>*/}
                {/*<img loading="lazy" src={ard2} alt=""/>*/}
                {/*<ul>*/}
                {/*    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.*/}
                {/*    </li>*/}
                {/*    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.*/}
                {/*    </li>*/}
                {/*    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.*/}
                {/*    </li>*/}
                {/*    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.*/}
                {/*    </li>*/}
                {/*    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.*/}
                {/*    </li>*/}
                {/*</ul>*/}
                <div className="share-articles-details share-protact-detalis d-none">
                  <h3><FaShareAlt/> {t('Share')} : </h3>
                  <ul>
                    <li><a href=""> <FaTwitter/> </a></li>
                    <li><a href=""> <FaInstagramSquare/></a></li>
                    <li><a href=""> <FaSnapchat/> </a></li>
                    <li><a href=""> <FaFacebookF/></a></li>
                    <li><a href=""> <FaLinkedinIn/></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none">
            <div className="sub-articles-details">
              <div className="about-author-articles">
                <h2>{t('About Author')}</h2>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img className='round'
                       style={{
                         height: '100px',
                         width: '100px',
                         borderRadius: '50px',
                         marginRight: '10px'
                       }}
                       loading="lazy"
                       src={caseDetails && caseDetails.authors && caseDetails.authors[0] ? caseDetails.authors[0].picture : ah1}
                       alt="person"/>
                  <h3
                    style={{ margin: '5px' }}>{caseDetails && caseDetails.authors && caseDetails.authors[0] ? caseDetails.authors[0].displayName : "Marcin Krupinski"}</h3>
                </div>
              </div>
              <div className="related-cases">
                <h2>Related Cases</h2>
                <ul>
                  <li>
                    <a href="">
                      <div className="img-related-cases">
                        <img loading="lazy" src={ar1} alt=""/>
                      </div>
                      <div className="text-related-cases">
                        <h3>A successful transitional and final
                          rehabilitation for a patient with
                          obstructive sleep apnea</h3>
                        <span>23 May 2023</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <div className="img-related-cases">
                        <img loading="lazy" src={ar1} alt=""/>
                      </div>
                      <div className="text-related-cases">
                        <h3>A successful transitional and final
                          rehabilitation for a patient with
                          obstructive sleep apnea</h3>
                        <span>23 May 2023</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <div className="img-related-cases">
                        <img loading="lazy" src={ar1} alt=""/>
                      </div>
                      <div className="text-related-cases">
                        <h3>A successful transitional and final
                          rehabilitation for a patient with
                          obstructive sleep apnea</h3>
                        <span>23 May 2023</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </Container>
      </section>
    </div>
  )
}
