import { DateCalendar, LocalizationProvider, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Badge, Box, IconButton, Modal, Typography } from "@mui/material";
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import { endOfDay, endOfMonth, format, getDate, getDaysInMonth, isBefore, parse, startOfMonth } from 'date-fns';
import arLocale from 'date-fns/locale/ar';
import enLocale from 'date-fns/locale/en-US';
import { TimeLineGroup } from "../Timeline/TimeLineGroup";
import { SessionItem } from "../Timeline/SessionItem";

const MonthCalendar = ({ month, events }: any) => {
  const [eventsByDay, setEventsByDay] = useState<any>({});
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectDay, setSelectedDay] = useState<any>(undefined);
  const monthDate = parse(month, "MM-yyyy", new Date());

  const { t, isRtl,direction } = useContext(LocaleContext) as LocaleContextProps

  function ServerDay(props: PickersDayProps<Date> & { highlightedDays?: number[] }) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth && highlightedDays.indexOf(getDate(props.day)) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        classes={{ badge: isBefore(day, (endOfDay(new Date()))) ? "bg-secondary text-white bg-opacity-75" : "" }}
        className={`cursor-pointer`}
        color={isBefore(day, (endOfDay(new Date()))) ? undefined : "info"}
        badgeContent={isSelected ? eventsByDay[getDate(props.day)].length : undefined}
        onClick={() => {
          {
            if (isSelected) {
              setSelectedDay(getDate(day));
              setIsModalOpen(true);
            } else {
              toast(t("No events for the selected date"))
            }
          }
        }}
      >
        <PickersDay {...other} disableMargin selected={false} outsideCurrentMonth={outsideCurrentMonth} day={day}/>
      </Badge>
    );
  }

  useEffect(() => {
    setLoading(true)
    const eventsByDay: any = {};
    const days: number[] = []
    Array.from(Array(getDaysInMonth(monthDate)).keys()).forEach(day => {
      eventsByDay[day + 1] = events.filter((event: any) => {
        return getDate(new Date(event.startDateTime)) === day + 1
      })
      if (eventsByDay[day + 1].length > 0) {
        days.push(day + 1)
      }
    })
    setHighlightedDays(days)
    setEventsByDay(eventsByDay)
    setLoading(false)

  }, [events, month])

  return (
    <LocalizationProvider adapterLocale={isRtl ? arLocale : enLocale} dateAdapter={AdapterDateFns}>
      <DateCalendar
        loading={loading}
        minDate={startOfMonth(monthDate)}
        maxDate={endOfMonth(monthDate)}
        defaultCalendarMonth={monthDate}
        renderLoading={() => <DayCalendarSkeleton/>}
        views={['day']}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          } as any,
        }}
        sx={{
          '& .MuiPickersArrowSwitcher-root': {
            visibility: 'hidden',
          },
        }}
      />

      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setSelectedDay(undefined)
        }}

        aria-labelledby="timeline-modal"
        id="timelineModal"

      >
        <div style={{ width: 'fit-content' }}
             dir={direction}
             className="h-100 mx-auto d-flex justify-content-center align-items-center">
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: "15px",
            }}
          >
            <div
              className="row"
              style={{
                maxWidth: "1500px",
                minWidth: "100%",
                maxHeight: "600px",
                padding: "20px",
                display: "flex",
                justifyContent: "center",
                overflowY: "auto"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h4"
                  style={{ marginBottom: "10px" }}
                >
                  {selectDay && month && format(parse(`${selectDay?.toLocaleString('en-US', { minimumIntegerDigits: 2 })}-${month}`, "dd-MM-yyyy", new Date()), "dd MMM yyyy",{locale:isRtl?arLocale:enLocale})}
                </Typography>

                <IconButton onClick={() => setIsModalOpen(false)}>
                  <CloseIcon/>
                </IconButton>
              </div>

              <TimeLineGroup>
                {(selectDay ? eventsByDay[selectDay] : []).map((session: any) => (
                  <SessionItem
                    key={session.id}
                    session={session}
                  />
                ))}

              </TimeLineGroup>

            </div>
          </Box>
        </div>
      </Modal>
    </LocalizationProvider>
  );

}


export default MonthCalendar;