import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom"; // Import NavLink
import {
  LocaleContext,
  LocaleContextProps,
} from "../../contexts/LocaleContext";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./ProfileBox.css";

export default function ProfileBox() {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const { currentUser, handleLogout: handleLogoutGlobal } = useContext(
    UserContext
  ) as UserContextProps;
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const { handleLogout } = useContext(UserContext) as UserContextProps;
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutConfirmation = () => {
    handleLogoutGlobal();
    setLogoutModalOpen(false);
  };
  return (
    <>
      <div dir={direction}>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title={t("Profile settings")}>
            <Button
              onClick={handleOpenUserMenu}
              sx={{ p: 0 }}
              className={"avatarBtn"}
            >
              <div data-testid="svg-usericon" className="css-xsh90p">
                {currentUser && currentUser.image ? (
                  <img
                    className="user-profile-image"
                    src={currentUser?.image}
                    alt="User Profile"
                  />
                ) : (
                  <svg
                    aria-hidden="true"
                    fill="none"
                    focusable="false"
                    height="36"
                    viewBox="0 0 24 24"
                    width="36"
                    className="css-amu4db"
                    id="cds-react-aria-2"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.034 2.5a4.467 4.467 0 10.002 8.934 4.467 4.467 0 00-.002-8.934zM6.568 6.966a5.467 5.467 0 1110.934.002 5.467 5.467 0 01-10.934-.002zM15.868 13.348a.5.5 0 01.386-.048c4.093 1.17 6.246 4.961 6.246 8.7h-1c0-3.323-1.87-6.593-5.324-7.68a8.845 8.845 0 01-4.162 1.057h-.004a8.93 8.93 0 01-4.185-1.056C4.39 15.408 2.5 18.679 2.5 22h-1c0-3.74 2.178-7.53 6.245-8.7a.5.5 0 01.386.047 7.916 7.916 0 003.881 1.03 7.834 7.834 0 003.856-1.03z"
                      fill="currentColor"
                    ></path>
                  </svg>
                )}
              </div>
              <span id="ProfileName" style={{ padding: "5px" }}>
                {currentUser?.firstName}
              </span>
              <ExpandMoreIcon />
            </Button>
          </Tooltip>
          <Menu
            dir={direction}
            sx={{ mt: "50px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            className="profile-box"
          >
            <MenuItem onClick={handleCloseUserMenu}>
              <NavLink
                to="/MyAccount"
                className={({ isActive }) =>
                  isActive ? "profile-nav-active" : ""
                }
              >
                <Typography textAlign="center">{t("Account")}</Typography>
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleCloseUserMenu}>
              <NavLink
                to="/myCourses"
                className={({ isActive }) =>
                  isActive ? "profile-nav-active" : ""
                }
              >
                <Typography textAlign="center">{t("Courses")}</Typography>
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleCloseUserMenu}>
              <NavLink
                to="/myorders"
                className={({ isActive }) =>
                  isActive ? "profile-nav-active" : ""
                }
              >
                <Typography textAlign="center">{t("Orders")}</Typography>
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleCloseUserMenu}>
              <a
                onClick={() => setLogoutModalOpen(true)}
                style={{ cursor: "pointer" }}
              >
                <Typography textAlign="center">{t("Log out")}</Typography>
              </a>
            </MenuItem>
          </Menu>
        </Box>
      </div>

      <Modal
        open={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        aria-labelledby="logout-modal-title"
        aria-describedby="logout-modal-description"
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            maxWidth: 400,
            mx: "auto",
            mt: 6,
            background: "white",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h5" id="logout-modal-title" gutterBottom>
            {t("Are you sure you want to log out?")}
          </Typography>
          <Button
            onClick={handleLogoutConfirmation}
            variant="contained"
            color="primary"
          >
            {t("Yes, Log out")}
          </Button>
          <Button
            onClick={() => setLogoutModalOpen(false)}
            variant="outlined"
            sx={{ ml: 2 }}
          >
            {t("Cancel")}
          </Button>
        </Box>
      </Modal>
    </>
  );
}
