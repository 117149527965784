import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TamaraWidgetComponent = ({ amount }: any) => {
  const { i18n } = useTranslation();


  useEffect(() => {


    if ((window as any).TamaraProductWidget) {
      (window as any).TamaraProductWidget.init({
        lang: i18n.language,
        currency: "SA",
        publicKey: ""
      });
      (window as any).TamaraProductWidget.render()
    }

    /*(window as any).tamaraWidgetConfig = {
        lang: i18n.language,
        country: "SA",
        publicKey: "" // Add your public key here,

      };
    (window as any).TamaraWidgetV2.refresh();*/

  }, [i18n.language, amount]);
  // @ts-ignore
  // const TamaraWidget = <tamara-widget type="tamara-summary" inline-type='2' amount={amount ? amount.toString() : ''} /> as any;
  const TamaraWidget = <div
    className="tamara-product-widget"
    data-lang={i18n.language}
    data-price={amount ? amount.toString() : ''}
    data-currency="SAR"
    data-payment-type="installment"
    data-disable-installment="false"
    data-disable-paylater="false"
    data-number-of-installments="4"
  />

  return (
    <div>
      {TamaraWidget}
    </div>
  );
}

export default TamaraWidgetComponent;