import React, { useContext, useEffect } from "react";
import "./PaymentModalStyles.css";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";


const acceptedMoyasarMethods = [
  "stcpay",
  "applepay",
  "creditcard",
  "masterCard",
  "visa",
  "mada",
  "amex",
];

export function PaymentModal({
                               paymentGateways,
                               amount,
                               selectedMethod,
                               open,
                               option,
                               handleClose,
                               cartData,
                             }: any) {
  const { lang, direction, t } = useContext(
    LocaleContext
  ) as LocaleContextProps;

  const { payableAmount, code } = cartData;
  const { publishKey } = selectedMethod;
  const redirectEnvs = {
    local: 'http://localhost:3001/RedirectConfirmPayment',
    prod: `${window.location.origin}/RedirectConfirmPayment`,
  };

  useEffect(() => {
    if (open && acceptedMoyasarMethods.includes(option)) {
      // @ts-ignore
      window?.Moyasar.init({
        element: ".mysr-form",
        amount: payableAmount * 100,
        currency: "SAR",
        description: `Moyasar Payment #${code}`,
        publishable_api_key: publishKey,
        callback_url: redirectEnvs.prod,
        methods: [
          acceptedMoyasarMethods.indexOf(option) > 1
            ? "creditcard"
            : option,
        ],
        apple_pay:
          option === "applepay" || option === "Apple Pay"
            ? {
              country: "SA",
              label: "Awesome Cookie Store",
              validate_merchant_url:
                "https://api.moyasar.com/v1/applepay/initiate",
            }
            : null,
        language: lang,
      });
    }

  }, [open]);


  return (
    <div className="modal-container" dir={direction}>
      <div style={{ background: "white", width: "500px" }}>
        <IconButton onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        <div
          className="mysr-form"
          style={{ background: "white", margin: "30px" }}
        />
      </div>
    </div>
  );
}
