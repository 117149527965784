import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import DayComponent from "./DayComponent";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Button, Typography } from "@mui/material";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import 'moment/locale/ar'
import 'moment/locale/en-gb'

export interface ScheduleItem {
  from: string;
  to: string;
  dayOfWeek: {
    id: number;
    name: string;
  }
}

export interface DateTimePickerProps {
  date?: moment.Moment;
  setDate: (date?: moment.Moment) => void;
  schedule: ScheduleItem[];
  speakerExist?:boolean;
}

moment.tz.setDefault("Asia/Riyadh");


const timeFormat = 'HH:mm';

const DateTimePicker = ({ date, setDate, schedule,speakerExist }: DateTimePickerProps) => {
  const { isRtl, lang, t } = useContext(LocaleContext) as LocaleContextProps;
  const [selectedDate, setSelectedDate] = useState(date);
  const [currentDate, setCurrentDate] = useState(moment())
  const [selectedTime, setSelectedTime] = useState(date ? date.format(timeFormat) : '');
  const RightArrow = isRtl ? FaChevronLeft : FaChevronRight;
  const LeftArrow = isRtl ? FaChevronRight : FaChevronLeft;

  useEffect(() => {
    moment.locale(lang)
    setCurrentDate(prevState => moment(prevState.toDate(), false))

  }, [lang])

  useEffect(() => {
    setSelectedTime(date ? moment(date).locale('en').format(timeFormat) : '')
  }, [date])


  const timeSlots = Array.from(Array(8).keys()).map((value) => {
      return moment('08:00', timeFormat).add(value, 'hour').locale('en').format(timeFormat)
    }
  );
  const handleTimeClick = (time: string) => {
    setSelectedTime(time);
    const momentTime = moment(time, timeFormat);
    const momentDate = moment(selectedDate);
    momentDate.set({ hour: momentTime.hour(), minute: momentTime.minute() })
    setDate(momentDate)

  };
  const handleSelectDate = (date: moment.Moment) => {
    setSelectedTime('');
    setDate(undefined)
    setSelectedDate(date)
  }

  return (
    <div style={{ margin: 'auto' }} className='d-flex flex-column  align-items-center p-4 col-11'>
      {speakerExist && schedule.length === 0 &&<span style={{ margin: 'auto' }} className='text-center mx-auto fs-5 text-danger'>
        {t('There is no available slot time for the selected speaker')}
      </span>}
      <div
        className='d-flex flex-md-row flex-xl-row flex-lg-row flex-column-reverse justify-content-between align-items-center w-100 px-2'>
      <span>
        {currentDate.month() === moment(currentDate).add(6, 'day').month() ?
          `${currentDate.format('MMMM')} ${moment(currentDate).locale('en').format('YYYY')}` :
          `${currentDate.format('MMMM')}/${moment(currentDate).add(6, 'day').format('MMMM')} ${moment(currentDate).add(6, 'day').locale('en').format("YYYY")}`}
      </span>
        <Button
          disabled={selectedDate?.isSame(moment(), 'day') && currentDate.isSame(moment(), 'day')}
          variant={'text'}
          onClick={() => {
            setCurrentDate(moment())
            setSelectedDate(moment())
          }}
        >
          {t('Today')}
        </Button>
      </div>
      <div className={`d-flex flex-row justify-content-center align-items-end gap-2 p-2 w-100`}>
        <LeftArrow onClick={() => setCurrentDate(prevState => moment(prevState).subtract(1, 'week'))}
                   className='pb-2 text-primary cursor-pointer'
                   style={{ minWidth: "11%" }}
                   size={32}
        />
        {Array.from(Array(7).keys()).map(value => {
          const date = moment(currentDate).startOf('week').add(value, 'day')

          return <DayComponent
            key={value}
            date={date}
            setSelectedDate={handleSelectDate}
            disabled={moment(date).locale('en').format('dddd') === 'Friday' || moment(date).locale('en').format('dddd') === 'Saturday' || date.isSameOrBefore(moment().startOf('day'))}
            isSelected={selectedDate?.isSame(date, 'day')}
          />

        })}
        <RightArrow onClick={() => setCurrentDate(prevState => moment(prevState).add(1, 'week'))}
                    className='pb-2 text-primary cursor-pointer '
                    style={{ minWidth: "11%" }}
                    size={32}/>
      </div>
      <Typography variant="h6" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
        {t('Available Time Slots')}
      </Typography>
      <div style={{ margin: 'auto', overflowX: 'hidden' }} className='px-3 w-100'>
        <div style={{ whiteSpace: 'nowrap', overflowX: 'auto' }} className={'py-2 row gap-2 flex-nowrap'}>
          {timeSlots.map((time, index) => {
            const currentSchedule = schedule.find(value => value.dayOfWeek.name === moment(selectedDate)?.locale("en").format('dddd'))
            let disabled = speakerExist;
            if (currentSchedule) {
              const fromTime = moment(currentSchedule?.from, "HH:mm:ss");

              const toTime = moment(currentSchedule?.to, "HH:mm:ss");

              disabled = moment(time, timeFormat).isBefore(fromTime) || moment(time, timeFormat).isSameOrAfter(toTime)
            }


            return (
              <Button
                disabled={!selectedDate || moment().isSame(selectedDate, 'day') && moment(time, timeFormat).isSameOrBefore(moment(), 'hour') || disabled}
                key={index}
                className='col d-inline-block float-none'
                variant={selectedTime === time ? 'contained' : 'outlined'}
                onClick={() => handleTimeClick(time)}
              >
                {time}
              </Button>
            )
          })}
        </div>
      </div>
    </div>
  );

}

export default DateTimePicker;