/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Loading from '../../components/Loading/Loading';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import ar1 from './assets/ar1.png';
import ah2 from './assets/ah2.png';
import {Container} from '@mui/material';
import {FaFacebookF, FaInstagramSquare, FaLinkedinIn, FaSnapchat, FaTwitter} from 'react-icons/fa';

export default function AuthorsDetails() {
    const {t, i18n} = useTranslation();

    const handleChangeLanguage = (language: string | undefined) => {
        i18n.changeLanguage(language);
    };

    const isArabic = i18n.language === 'ar';
    const direction = isArabic ? 'rtl' : 'ltr';

    return (
        <div>
            <section className="authors-details pg-section" dir={direction}>
                <Container maxWidth="xl" className="main-container">
                    <div className="main-authors-details">
                        <div className="sub-authors-details">
                            <div className="img-authors-details">
                                <img loading="lazy" src={ah2} alt=""/>
                            </div>
                            <div className="text-authors-details">
                                <h3>{t('StyleItaliano founder')}</h3>
                                <h2>{t('Angelo Putignano')}</h2>
                                <div className="share-authors-details share-protact-detalis">
                                    <ul>
                                        <a href="#">

                                            <FaTwitter/>
                                        </a>
                                        <a href="#">

                                            <FaInstagramSquare/>
                                        </a>
                                        <a href="#">

                                            <FaSnapchat/>
                                        </a>
                                        <a href="#">

                                            <FaFacebookF/>
                                        </a>
                                        <a href="#">

                                            <FaLinkedinIn/>
                                        </a>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p>
                            • M.D. degree and D.D.S. post graduate certificate from University of Ancona-Italy • Full
                            professor in Restorative Dentistry at School of Dentistry Polytecnique University of
                            Marche-Ancona-Italy • Head of Operative dentistry and Endodontics department at School of
                            Dentistry Polytecnique University of Marche-Ancona-Italy • Dean School of Dental Hygienist
                            Polytecnique University of Marche-Ancona-Italy • Active Member Italian Society of Operative
                            Dentistry (SIDOC) •Founding Member Italian Academy of Esthetic Dentistry (IAED) • Active
                            Member European Academy of Esthetic Dentistry (EAED) • Private practice limited to
                            Restorative Dentistry in Ancona , Italy • He is co- author of the book “Adhesive Dentistry:
                            the Key to success” edited by Quintessence International. • Co-Author of “Indirect
                            Conservative Restoration of Posterior teeth” (UTET 2008) • Prof. Putignano is lecturing
                            internationally on adhesive &amp; aesthetic restorations.
                        </p>
                    </div>
                    <div className="author-clinical-cases ">
                        <h2>{t("Authors' Clinical Cases")}</h2>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="sub-articles">
                                    <Link to="/newsDetails">
                                        <div className="img-sub-articles">
                                            <img loading="lazy" src={ar1} alt=""/>
                                        </div>
                                        <div className="text-articles">
                                            <h3>{t('Indirect')}</h3>
                                            <h2>
                                                {t('A successful transitional and final rehabilitation for a patient with obstructive sleep apnea Community')}
                                            </h2>
                                            <p>Community</p>
                                            <span className="data-articles"> {t('23 May 2023')} </span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            {/* Add the other articles here */}
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    );
}
