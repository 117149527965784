/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Container } from "@mui/material";
import { motion } from "framer-motion";

import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import useMembershipsService from "../../api/useMembershipsService";
import MembershipCard from "./MembershipCard";
import useAuthServices from "../../api/useAuthServices";

export interface IMembership {
  id: number;
  name: string;
  description: string;
  startDateTime: string;
  endDateTime: string;
  durations: IDuration[];
}

export interface IDuration {
  id: number;
  durationInDays: number;
  checkInLimits: number;
  autoApplySmallCoupon: boolean;
  autoApplyBigCoupon: boolean;
  isActive: boolean;
  price: IPrice;
  displayTotalPrice: string;
}

export interface IPrice {
  displayPrice: string;
  displayDiscount: string;
  amount: number;
  discount: number;
  isDiscountPercentage: boolean;
  tax: any;
  totalPrice: number;
  displayTotalPriceWithoutDiscount: string;
  displayTotalPrice: string;
}

export default function Memberships() {
  const { direction, t } = useContext(LocaleContext) as LocaleContextProps;
  const { isAuthenticated } = useContext(UserContext) as UserContextProps;
  const [memberships, setMemberships] = useState<IMembership[]>([]);
  const [currentMembership, setCurrentMembership] = useState<any>();

  const { getMemberships, getMembershipDurations } = useMembershipsService();
  const { getCurrentMembership } = useAuthServices();

  const fetchCurrentMembership = () => {
    getCurrentMembership().then((data) => {
      const current = data.find(
        (membership: any) => membership.subscriptionStatus.name === "Active"
      );
      setCurrentMembership(current);
    });
  };

  useEffect(() => {
    getMemberships().then(async (data) => {
      const fetchedMemberships = await Promise.all(
        data.items?.map(async (membership: IMembership) => {
          const durations = (await getMembershipDurations(membership.id)).items;
          return { ...membership, durations };
        })
      );
      setMemberships(fetchedMemberships);
    });
    if (isAuthenticated) {
      fetchCurrentMembership();
    }
  }, []);

  return (
    <motion.li
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      dir={direction}
    >
      <section className="memberships mr-section" dir={direction}>
        <Container maxWidth="xl" className="main-container">
          <div className="title-center">
            <h3>{t("NAJ priced your way")}</h3>
            <h2>
              {t("Trusted by thousands, NAJ powers teams all around the world")}
            </h2>
          </div>
          <div className="main-memberships">
            <div className="row justify-content-center">
              {memberships.sort((a) =>
                currentMembership?.membership.id === a.id ? 1 : -1
              ).map((membership) => (
                <MembershipCard
                  key={membership.id}
                  membership={membership}
                  currentDuration={
                    currentMembership &&
                    membership.durations?.find(
                      (d) =>
                        d.durationInDays === currentMembership.durationInDays
                    )
                  }
                  selected={currentMembership?.membership.id === membership.id}
                />
              ))}
            </div>
          </div>
        </Container>
      </section>
    </motion.li>
  );
}
