/* eslint-disable @typescript-eslint/no-unused-vars */
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Loading from '../../components/Loading/Loading';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ah2 from './assets/ah2.png';
import {Container} from '@mui/material';

export default function Authors() {
    const {t, i18n} = useTranslation();

    const handleChangeLanguage = (language: string | undefined) => {
        i18n.changeLanguage(language);
    };

    const isArabic = i18n.language === 'ar';
    const direction = isArabic ? 'rtl' : 'ltr';

    return (
        <div>
            <section className="authors mr-section" dir={direction}>
                <Container maxWidth="xl" className="main-container">
                    <div className="title-center">
                        <h3>{t('Authors')}</h3>
                        <h2>{t('Styleitaliano Authors')}</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-easing="linear"
                             data-aos-duration={700}>
                            <div className="sub-authors">
                                <Link to="/authorsDetails">
                                    <div className="img-sub-authors">
                                        <img loading="lazy" src={ah2} alt=""/>
                                    </div>
                                    <h2>{t('Marcin Krupinski')}</h2>
                                    <p>{t('StyleItaliano founder')}</p>
                                </Link>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-easing="linear"
                             data-aos-duration={700}>
                            <div className="sub-authors">
                                <Link to="/authorsDetails">
                                    <div className="img-sub-authors">
                                        <img loading="lazy" src={ah2} alt=""/>
                                    </div>
                                    <h2>{t('Marcin Krupinski')}</h2>
                                    <p>{t('StyleItaliano founder')}</p>
                                </Link>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-easing="linear"
                             data-aos-duration={700}>
                            <div className="sub-authors">
                                <Link to="/authorsDetails">
                                    <div className="img-sub-authors">
                                        <img loading="lazy" src={ah2} alt=""/>
                                    </div>
                                    <h2>{t('Marcin Krupinski')}</h2>
                                    <p>{t('StyleItaliano founder')}</p>
                                </Link>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-easing="linear"
                             data-aos-duration={700}>
                            <div className="sub-authors">
                                <Link to="/authorsDetails">
                                    <div className="img-sub-authors">
                                        <img loading="lazy" src={ah2} alt=""/>
                                    </div>
                                    <h2>{t('Marcin Krupinski')}</h2>
                                    <p>{t('StyleItaliano founder')}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    );
}
