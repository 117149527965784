import { useEffect } from 'react';

const useOutsideClick = (ref: React.RefObject<HTMLElement>, onOutsideClick: () => void) => {
  const handleOutsideClick = (event:any) => {
    if (ref.current && !ref.current.contains(event.target) ) {
        onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
};

export default useOutsideClick;
