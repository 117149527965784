import { List, ListItem } from "@mui/material";
import mainLogo from "./assets/Logo.svg";
import { Link } from "react-router-dom";
import React, { useContext } from "react";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import { useNavigate } from "react-router";
import "./DrawerMenu.css";

export function DrawerMenu ({handleMenuClose} : any){
    const {t, direction} = useContext(LocaleContext) as LocaleContextProps;
    const navigate = useNavigate();

    const handleItemClick = (path: string) => {
        handleMenuClose();
        navigate(path);
    };

    return (
        <List
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                minWidth: "400px",
            }}
        >
            <ListItem
                className="animate__animated  animate__fadeIn"

                button
                style={{
                    textAlign: "center",
                    padding: "1rem 0",
                    color: "#000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "3rem",
                }}
                onClick={handleMenuClose}
            >
                <img loading="lazy" src={mainLogo} alt="logo"/>
            </ListItem>

            <ListItem
                className="listItemCls animate__animated  animate__fadeIn"
                button
                onClick={() => handleItemClick('/')}
            >
                <Link style={{ width: "100%" }} to="/">
                    {t("Home")}
                </Link>
            </ListItem>

            <ListItem
                className="listItemCls animate__animated  animate__fadeIn"
                button
                onClick={() => handleItemClick('/page/About')}
            >
                <Link style={{ width: "100%" }} to="/page/About">
                    {t("about_us")}
                </Link>
            </ListItem>

            <ListItem
                className="listItemCls animate__animated  animate__fadeIn"
                button
                onClick={() => handleItemClick('/products')}
            >
                <Link style={{ width: "100%" }} to="/products">
                    {t("Products")}
                </Link>
            </ListItem>

            <ListItem
                className="listItemCls animate__animated  animate__fadeIn"
                button
                onClick={() => handleItemClick('/speakers')}
            >
                <Link style={{ width: "100%" }} to="/speakers">
                    {t("speakers")}
                </Link>
            </ListItem>

            <ListItem
                className="listItemCls animate__animated  animate__fadeIn"
                button
                onClick={() => handleItemClick('/news')}
            >
                <Link style={{ width: "100%" }} to="/news">
                    {t("News")}
                </Link>
            </ListItem>
            <ListItem
              className="listItemCls animate__animated  animate__fadeIn"
              button
              onClick={() => handleItemClick('/cases')}
            >
                <Link style={{ width: "100%" }} to="/cases">
                    {t("casesArticles")}
                </Link>
            </ListItem>

            <ListItem
                className="listItemCls animate__animated  animate__fadeIn"
                button
                onClick={() => handleItemClick('/partners')}
            >
                <Link style={{ width: "100%" }} to="/partners">
                    {t("partners")}
                </Link>
            </ListItem>



            <ListItem
                className="listItemCls animate__animated  animate__fadeIn"
                button
                onClick={() => handleItemClick('/memberships')}
            >
                <Link style={{ width: "100%" }} to="/memberships">
                    {t("Memberships")}
                </Link>
            </ListItem>

            <ListItem
                className="listItemCls animate__animated  animate__fadeIn"
                button
                onClick={() => handleItemClick('/page/Contact')}
            >
                <Link style={{ width: "100%" }} to="/page/Contact">
                    {t("contactUs")}
                </Link>
            </ListItem>

        </List>
    )
}