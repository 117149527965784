/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useOrdersAPI from "../../api/ems/orders";
import {currencyFormate, formatSimpleDate} from "../../utils/dateUtils";
import {LocaleContext,} from "../../contexts/LocaleContext";
import printJs from 'print-js';


export default function OrdersDetails() {
  const { t, lang } = useContext(LocaleContext);
  const params = useParams();
  const orderId = params.id;
  const [invoice, setInvoice] = useState(null);


  const { loadOrderById, getInvoiceByOrderId, printOrderInvoice } =
    useOrdersAPI();

  const [order, setOrder] = useState();

  const fetchOrders = () => {
    loadOrderById(orderId)
      .then((order) => {
        setOrder(order);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchOrders();
    setInvoice(null);
    getInvoiceByOrderId(orderId)
      .then((response) => {
        setInvoice(response || null);
      })
      .catch((error) => {
        setInvoice(null);
      });
  }, []);

  const handlePrint = ()=>{
    printOrderInvoice(invoice?.code).then((response) =>{
      printJs({printable:response,type:'raw-html'})
    });
  };



  return (
    <div className="main-order-details">
      <iframe id="ifmcontentstoprint" style={{height: 0, width: 0, position: 'absolute'}}/>

      <div className="title-order-details">
        <h2>
          {t("Order no.")}{order?.id}
        </h2>
      </div>
      <div className="tabs-order-details">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="pills-1-tab"
              data-toggle="pill"
              href="#pills-1"
              role="tab"
              aria-controls="pills-1"
              aria-selected="true"
            >
              {t("Order Details")}
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              className="nav-link"
              id="pills-2-tab"
              data-toggle="pill"
              href="#pills-2"
              role="tab"
              aria-controls="pills-2"
              aria-selected="false"
            >
              {t('products')}
            </a>
          </li> */}
          <li className="nav-item">
            <a
              className="nav-link"
              id="pills-3-tab"
              data-toggle="pill"
              href="#pills-3"
              role="tab"
              aria-controls="pills-3"
              aria-selected="false"
            >
              {t("Invoice")}
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div className="order-detail">
            <div className="details-myprofile">
              <h2>{t("Customer")}</h2>
              <ul>
                <li>
                  {" "}
                  {t("FullName")} :<span> {order?.client.displayName}</span>
                </li>
                <li>
                  {" "}
                  {t("E-mail")} :<span>{order?.client.email}</span>
                </li>
              </ul>
            </div>
            <div className="status-updated">
              <div className="sub-order-detail">
                <h2>{t("Order Status")} :</h2>
                <ul>
                  <li>
                    <span className={`stats ${order?.status?.id === 2 ? 'payment-accepted' : 'onpre-order'}`}>
                      {order?.status.name}
                    </span>
                  </li>
                </ul>
              </div>
              {/* <div className="sub-order-detail">
                <h2>{t("Updated on")} :</h2>
                <ul>
                  <li> {formatSimpleDate(order?.updatedDate, lang)}</li>
                </ul>
              </div> */}
            </div>
            <div className="details-myprofile">
              {order?.payments &&
                order?.payments.map((payment) => (
                  <>
                    <h2>{t("Payment")} :</h2>

                    <ul>
                      <li>
                        {" "}
                        {t("Payment Method")} :
                        <span>{payment.paymentMethod?.name}</span>
                      </li>
                      <li>
                        {" "}
                        {t("Amount")} :<span>
                        {`${currencyFormate(payment.amount)} ${t('SAR')}`}

                        </span>
                      </li>
                      <li>
                        {t("Date")} :{" "}
                        <span> {formatSimpleDate(payment.date, lang)}</span>
                      </li>
                    </ul>
                  </>
                ))}
            </div>
          </div>
        </div>
        {/* <div
          className="tab-pane fade"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-2-tab"
        >
          <div className="prodect-profile">
            <table className="table">
              <tbody>
              {order?.products &&
              order.products.map((product: any) => (
                <tr>
                  <td>
                    <div className="prodect-cart-page">
                      <div className="img-prodect-cart-page">
                        <img
                          loading="lazy"
                          src={product.media?.fullUrl || FallbackImage}
                          alt=""
                        />
                      </div>
                      <div className="text-prodect-cart-page">
                        <h3>{product.name}</h3>
                        
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="code-product"> {product.id}</div>
                  </td>
                  <td>
                    <div className="price-product-cart">
                      <h3>{product.price} SAR</h3>
                      <span className="old-price">
                            {product.subTotal} SAR
                          </span>
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div> */}
        <div
          className="tab-pane fade"
          id="pills-3"
          role="tabpanel"
          aria-labelledby="pills-3-tab"
        >
          <div className="invoice">
            <div className="details-invoice">
              <ul>
                <li>
                  {t("Date")}: {formatSimpleDate(order?.createDate, lang)}
                </li>
                {invoice && (
                  <li>
                    <div className="normal-btn" onClick={handlePrint}>
                      Print
                    </div>
                  </li>
                )}
              </ul>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>{t("Product")}</th>
                  <th>{t("Code")}</th>
                  <th>{t("Price")}</th>
                </tr>
              </thead>
              <tbody>
                {order?.products &&
                  order.products.map((product) => (
                    <tr>
                      <td>{product.name}</td>
                      <td> {product.id}</td>
                      <td>
                      {`${currencyFormate(product.price)} ${t('SAR')}`}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="invoice-price">
              <ul>
                <li>
                  <h3>{t("Subtotal")}</h3>{" "}
                  <span>
                    {`${currencyFormate(order?.subTotal)} ${t('SAR')}`}
                  </span>
                </li>
                <li>
                  <h3>{t("Tax")}</h3>{" "}
                  <span>
                    {" "}
                    {`${currencyFormate(order?.totalVATAmount)} ${t('SAR')}`}

                  </span>
                </li>
                <li>
                  <h3>{t("Discount")}</h3>{" "}
                  <span>
                    -{`${currencyFormate(order?.totalDiscounts)} ${t('SAR')}`}

                  </span>
                </li>
                <li>
                  <h3>{t("Total")}</h3>{" "}
                  <span>
                    {" "}
                    {`${currencyFormate(order?.total)} ${t('SAR')}`}

                  </span>
                </li>
              </ul>
            </div>
            {/* <p>
              {t("Please pay within 15 days, Thank You for your business.")}
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
