import React, { useContext } from 'react';
import './ServicesSection.css';
import OwlCarousel from 'react-owl-carousel';
import CarouselItem from './CarouselItem';

import s1 from './assets/s1.png';
import s2 from './assets/s2.png';
import s3 from './assets/s3.png';
import a1 from './assets/a1.png';

import p2 from './assets/p2.png';
import p5 from './assets/p5.png';
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";

const ServicesSection: React.FC = () => {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;


  return (
    <section className="services-index pg-section" dir={direction}>
      <div className="main-container">
        <div className="title-center">
          <h3>{t('Services')}</h3>
        </div>
        <div className="main-services-index">
          <OwlCarousel
            className="owl-theme maincarousel"
            id="slider-services"
            loop={true}
            nav
            margin={20}
            items={4}
            autoplayTimeout={4000}
            autoplayHoverPause={true}
            autoplay={false}
            dots={false}
            smartSpeed={1000}
            responsiveClass={true}
            responsive={{
              0: {
                items: 1,
                smartSpeed: 400,
                dots:true
              },
              700: {
                items: 3,
              },
              1000: {
                items: 4,
              },
            }}
          >
            <CarouselItem title={'Individual Training'} imgSrc={s1} bgImgSrc={a1} />
            <CarouselItem title={'Group Learning'} imgSrc={s2} bgImgSrc={p5} />
            <CarouselItem title={'Online workshop'} imgSrc={s3} bgImgSrc={p2} />
            <CarouselItem title={'Onsite workshop'} imgSrc={s3} bgImgSrc={p2} />
            <CarouselItem title={'Group Learning'} imgSrc={s2} bgImgSrc={p5} />
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
