/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import n1Image from "./assets/n3.png";
import n3 from "./assets/n3.png";
import p4Image from "./assets/p4.png";
import p6Image from "./assets/p6.png";
import { Container } from "@mui/material";
import {
  FaFacebookF,
  FaInstagramSquare,
  FaLinkedinIn,
  FaRegClock,
  FaRegUserCircle,
  FaShareAlt,
  FaSnapchat,
  FaTwitter,
} from "react-icons/fa";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import toast from "react-hot-toast";
import useNewsAPI, { INews } from "../../api/ems/news";
import v1 from "../../assets/svg/v1.svg";
import { formatDate } from "../../utils/dateUtils";

export default function AchievementsDetails() {
  const { id } = useParams();
  const { t, direction, lang } = useContext(
    LocaleContext
  ) as LocaleContextProps;

  const { loadNewsDetails, loadNews } = useNewsAPI();

  const [newsDetails, setNewsDetails] = useState<INews | null>(null);

  const [loading, setLoading] = useState(false);

  const [news, setNews] = useState<INews[]>([]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      loadNewsDetails(id)
        .then((data) => setNewsDetails(data))
        .catch((err) => toast.error(t("Couldn't load case with ID" + id)))
        .finally(() => setLoading(false));

      loadNews(0, null)
        .then((news) => setNews(news.items))
        .catch((err) => {
        });
    }
  }, []);

  return (
    <div>
      <section
        className="achievements-details mr-section mx-auto w-75"
        dir={direction}
      >
        <Container maxWidth="xl">
          <div className="row">
            <div className="col-lg-12">
              {!loading && (
                <div className="main-achievements-details">

                  <h1 className="mt-0">
                    {newsDetails
                      ? newsDetails.title
                      : t("The Title Of This Event")}
                  </h1>
                  <div className="data-and-article-author">
                    <ul className="mb-0">
                      <li className="fs-5">
                        <FaRegClock
                          color="#7cc2ed"
                          className="asset-icon"
                        />{" "}
                        {newsDetails
                          ? formatDate(newsDetails.postTime, lang)
                          : t("Sun, 12 May 2023")}
                      </li>
                      {newsDetails &&
                      newsDetails.authors &&
                      newsDetails.authors[0]?.displayName ? (
                        <li className="fs-5">
                          <FaRegUserCircle/> {t("Author")} :{" "}
                          {newsDetails.authors[0].displayName}
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>

                  <div style={{
                    backgroundImage: `url(${newsDetails && newsDetails.media
                      ? newsDetails.media.fullUrl
                      : n1Image})`,
                  }} className="img-achievements-details my-4">
                    <img
                      loading="lazy"
                      src={
                        newsDetails && newsDetails.media
                          ? newsDetails.media.fullUrl
                          : n1Image
                      }
                      alt=""
                    />

                    <ul className="labels-wrap">
                      {newsDetails &&
                      newsDetails.tags.map((tag) => <li>{tag.name}</li>)}
                      {
                        newsDetails && <li>{newsDetails.postCategory.name}</li>
                      }
                    </ul>
                  </div>
                  <div className="text-achievements-details ">
                    <div className="p-1 ">
                      <div
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: newsDetails
                            ? newsDetails.content
                            : t("lorem"),
                        }}
                      />
                    </div>
                  </div>
                  <div className="siderimg-achievements">
                    <div
                      className="owl-carousel owl-theme maincarousel"
                      id="siderimg-achievements"
                    >
                      <div className="item">
                        <div className="img-siderimg-achievements">
                          <img loading="lazy" src={p4Image} alt=""/>
                        </div>
                      </div>
                      <div className="item">
                        <div className="img-siderimg-achievements">
                          <img loading="lazy" src={p6Image} alt=""/>
                        </div>
                      </div>
                      <div className="item">
                        <div className="img-siderimg-achievements">
                          <img loading="lazy" src={p4Image} alt=""/>
                        </div>
                      </div>
                      <div className="item">
                        <div className="img-siderimg-achievements">
                          <img loading="lazy" src={p6Image} alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="share-protact-detalis d-none">
                    <h3>
                      <FaShareAlt/> {t("Share")} :{" "}
                    </h3>
                    <ul>
                      <li>
                        <a href="">
                          <FaTwitter/>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <FaInstagramSquare/>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <FaSnapchat/>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <FaFacebookF/>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <FaLinkedinIn/>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="d-none">
              <div className="latest-posts">
                <div className="title-achievements-details">
                  <h2>{t("Latest Posts")}</h2>
                </div>
                {/*<div className="search-achievements">*/}
                {/*    <form>*/}
                {/*        <input type="text" className="form-control" name="search"*/}
                {/*               placeholder={t('Search for articles  ..')}/>*/}
                {/*        <button><img loading="lazy" src="images/search2.svg" alt=""/></button>*/}
                {/*    </form>*/}
                {/*</div>*/}
                <div className="more-news-index more-latest-posts ">
                  <ul>
                    {news &&
                    news.map((nws) => (
                      <li>
                        <div className="img-more-news-index">
                          <Link to={`/newsDetails/${nws.id}`}>
                            <img
                              loading="lazy"
                              src={nws.media ? nws.media.fullUrl : n3}
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="text-more-news-index">
                          <h2>{nws.title}</h2>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: nws ? nws.content : "",
                            }}
                          />
                          <Link to={`/newsDetails/${nws.id}`}>
                            {t("Read More")}
                          </Link>
                        </div>
                      </li>
                    ))}
                    {/*<li>*/}
                    {/*    <div className="img-more-news-index">*/}
                    {/*        <a className="lvideo"*/}
                    {/*           data-url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4">*/}
                    {/*            <img loading="lazy" src={n3} alt=""/>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*    <div className="text-more-news-index">*/}
                    {/*        <h2>{t('The Title Of This Event')}</h2>*/}
                    {/*        <p>{t("It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout ..")}</p>*/}
                    {/*        <a href="">{t('Read More')}</a>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                  </ul>
                </div>
                <div className="banner-achievements d-none">
                  <h3>{t("30 Days Free Trial")}</h3>
                  <h2>{t("For New Members")}</h2>
                  <a href="" className="ctm-btn">
                    {t("Join With Us")}
                  </a>
                  <div className="img-banner-achievements">
                    <object data={v1}>
                      <img loading="lazy" src={v1} alt=""/>
                    </object>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
