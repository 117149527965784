import React, {createContext, useRef, useState, useEffect, Ref} from 'react';
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";

type ScrollToRefFunction = () => void;

export interface ScrollContextProps {
    scrollToRef: ScrollToRefFunction;
    sectionRef: Ref<any>;
}

const ScrollContext = createContext<ScrollContextProps | undefined>(undefined);

const ScrollProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const navigate = useNavigate()
    const sectionRef = useRef<HTMLDivElement>(null);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const scrollToRef: ScrollToRefFunction = () => {
        if(sectionRef.current){
            sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
        } else {
            navigate('/products');
        }
    };

    return (
        <ScrollContext.Provider value={{ scrollToRef, sectionRef }}>
            {children}
            <ScrollToTopButton />
        </ScrollContext.Provider>
    );
};

export { ScrollContext, ScrollProvider };
