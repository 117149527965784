import React, { useContext } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ServerContext, ServerContextProps, ServerStatus } from "../../contexts/ServerContext";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import { Button } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap:1
};

const ServerDownModal = () => {
  const {status} = useContext(ServerContext) as ServerContextProps
  const { t,direction,changeLang,lang } = useContext(LocaleContext) as LocaleContextProps;

  return (
    <Modal

      open={status === ServerStatus.DOWN}
      onClose={() => {}}
      aria-labelledby="server-down-modal"
      aria-describedby="server-down-message"
      style={{ backdropFilter: 'blur(5px)',direction:direction as any }}
    >
      <Box sx={style}>
        <div className='d-flex justify-content-between align-items-center w-100'>

        <h2 id="server-down-modal">{t("Server Down")}</h2>
          <span className='link-primary cursor-pointer' onClick={event => {
            changeLang(lang==='en'?'ar':'en')
          }}>{lang === 'en'?t('العربية'):t('English')}</span>
        </div>
        <p  id="server-down-message">{t("Server is down. Please try refreshing the page.")}</p>
        <Button className={'align-self-center mx-auto'} variant={"text"}  onClick={() => window.location.reload()}>{t("Refresh")}</Button>
      </Box>
    </Modal>
  );
};

export default ServerDownModal;