/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Memberships from "./Memberships";

export default function MyMemberships() {
  return (
    <div>
      <Memberships/>
    </div>
  );
}
