/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Container } from "@mui/material";
import { CartSummary } from "../../components/CartSummary/CartSummary";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { CartContext } from "../../contexts/CartContext";
import NoData from "../../iComponents/NoData/NoData";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import useUserCartAPI from "../../api/ems/user-cart";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { synchronizeCarts, synchronizeCartsOffline } from "../Cart/utils";
import BookingItem from "./BookingItem";

export default function Booking() {
  const navigate = useNavigate();
  const { t, direction,isRtl } = useContext(LocaleContext) as LocaleContextProps;
  const { isAuthenticated } = useContext(UserContext) as UserContextProps;
  const {
    loadReadyUserCart,
    addToUserCart,
    updateToUserCart,
    deleteFromUserCart,
    loadReadyAnonymousCart,
  } = useUserCartAPI();
  const { cartItems, setCartItems, setBackendPriceDetails } = useContext(CartContext);
  const [shouldDisableCart, setShouldDisableCart] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      loadReadyUserCart()
        .then((response) => {
          synchronizeCarts({
            backendCart: response,
            cartItems,
            setCartItems,
            addToUserCart,
            updateToUserCart,
            deleteFromUserCart,
            setBackendPriceDetails,
            loadReadyUserCart,
          }).catch((error => console.error('ERROR', error)));
        })
        .catch((err) => {
          toast.error(
            "Cart services not available at the moment, please try again later."
          );
        })
        .finally(() => setIsLoading(false));
    } else {
      loadReadyAnonymousCart(
        cartItems.map((item) => ({
          productId: item.id,
          qty: item.count,
        }))
      )
        .then((response) => {
          setCartItems(synchronizeCartsOffline(cartItems, response, setBackendPriceDetails));
        })
        .catch(() => {
          setShouldDisableCart(true);
          toast.error(
            "Cart services not available at the moment, please try again later."
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [isAuthenticated,isRtl]);

  const hasDateRequired = cartItems?.some(
    (item) => item?.isDateRequired === true
  );
  
  return (
    <div dir={direction}>
      <section className="booking-page cart-page mr-section">
        <Container maxWidth="xl" className="main-container">
          <div className="order-tracking">
            <ul>
              <li className="completed">
                <Link to="/cart">
                  <div className="img-order-tracking">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={27}
                      height={27}
                      fill="currentColor"
                      className="bi bi-cart3"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                      />
                    </svg>
                  </div>
                  <h2>
                    {t("Your Cart")}
                    <span />
                  </h2>
                </Link>
              </li>
              <li className="active">
                <Link to="/booking">
                  <div className="img-order-tracking">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={27}
                      height={27}
                      fill="currentColor"
                      className="bi bi-cart3"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                      />
                    </svg>
                  </div>
                  <h2>
                    {t("Preferences")}
                    <span />
                  </h2>
                </Link>
              </li>
              <li>
                {hasDateRequired ? (
                  <div className="fake-link">
                    <div className="img-order-tracking">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={27}
                        height={27}
                        fill="currentColor"
                        className="bi bi-credit-card"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"
                        />
                      </svg>
                    </div>
                    <h2>
                      {t("Payment")}
                      <span />
                    </h2>
                  </div>
                ) : (
                  <Link to="/payment">
                    <div className="img-order-tracking">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={27}
                        height={27}
                        fill="currentColor"
                        className="bi bi-credit-card"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"
                        />
                      </svg>
                    </div>
                    <h2>
                      {t("Payment")}
                      <span />
                    </h2>
                  </Link>
                )}
              </li>
            </ul>
          </div>

          <div className="main-booking-page">
            <div className="row">
              <div className="col-lg-7">
                {isLoading ? (
                  <div className="form-booking-page">
                    <p>Getting cart to be ready.</p>
                  </div>
                ) : (
                  <div className="form-booking-page">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {cartItems && cartItems.length > 0 ? (
                        cartItems.map((item) => (
                          <BookingItem
                            bookItem={item}
                            key={item.id}
                            setCartItems={setCartItems}
                            setBackendPriceDetails={setBackendPriceDetails}
                            cartItems={cartItems}
                          />
                        ))
                      ) : (
                        <NoData msg={"There is no item in your cart"} />
                      )}
                    </LocalizationProvider>
                  </div>
                )}
              </div>

              <div
                
                className="col-lg-5 cart-summary-sticy"
              >
                <CartSummary
                  disableConfirm={shouldDisableCart}
                  confirmMsg={"Check out"}
                  handleConfirm={() => {
                    if (hasDateRequired) {
                      toast.error("Please complete required inputs.");
                    } else {
                      navigate("/payment");
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
