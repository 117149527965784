import React, { useContext, useState } from 'react';
import a1 from './assets/a1.png';
import a2 from './assets/a2.png';
import a3 from './assets/a3.png';
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";

export default function AboutUsSection() {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const [animationClass, setAnimationClass] = useState('');


  return (
    <div>
      <section className={`aboutus-index mr-section ${animationClass}`} dir={direction}>
        <div className="row align-items-end">
          <div className="col-lg-6">
            <div className={`text-aboutus-index ${animationClass} text-animation-1`}>
              <div className="title-start">
                <h3>{t('About us')}</h3>
              </div>
              <p>{t("We're a specialized Center that offers educational services to all Dental students in Saudi Arabia ..")}</p>
              <div className="img-aboutus-index">
                <img loading="lazy" src={a1} alt="img" />
                <img loading="lazy" src={a2} alt="img" />
                <img loading="lazy" src={a3} alt="img" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="more-aboutus-index">
              <div className={`mission-index ${animationClass} text-animation-2`} data-aos="fade-left" data-aos-easing="linear" data-aos-duration={400}>
                <div className="title-mission">
                  <h2>{t('Mission')}</h2>
                  <p>{t('Uplift education experience for passionate dental community ..')}</p>
                </div>
                <ul className="list-aboutus-index">
                <li>{t('najTrainingDescription')}</li>
                </ul>
              </div>
              <div className={`vission mission-index ${animationClass} text-animation-3`} data-aos="fade-left" data-aos-easing="linear" data-aos-duration={400}>
                <div className="title-mission">
                  <h2>{t('Vission')}</h2>
                  <p>{t('Distinguished as a lead provider in the finest educational experience in dentistry ..')}</p>
                </div>
                <ul className="list-aboutus-index">
                  <li>{t('NAJ training will be well-known in dentistry ecosystem.')}</li>
                  <li>{t('najTrainingLeader')}</li>
<li>{t('najTrainingQuality')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
