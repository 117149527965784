import React, { useContext, useEffect, useState } from "react";
import { Container } from "@mui/material";
import { LocaleContext, LocaleContextProps } from "src/contexts/LocaleContext";
import useAxiosWithInterceptor from "src/api/useAxiosWithInterceptor";
import NoData from "src/iComponents/NoData/NoData";

export default function Terms() {
  const { axiosInstance } = useAxiosWithInterceptor();
  const { t, direction,isRtl } = useContext(LocaleContext) as LocaleContextProps;

  const [data, setData]: any = useState(null);

  useEffect(() => {
    axiosInstance
      .get("api/Home/TermsOfUse",{params:{isArabic:isRtl}})
      .then((response: any) => setData(response.data))
      .catch((error: any) => setData(null));
  }, []);

  return (
    <div>
      <div dir={direction} className="privacy-policy-page">
        <section
          className={`privacy-policy mr-section p-4 col-10 col-md-8 col-xl-6 mx-auto border shadow-sm rounded`}
        >
          <div className="title-center">
            <h2>{t('Terms')}</h2>
          </div>
          <hr />
          <Container maxWidth="xl" className="main-container">
            {data?.length >= 1 ? (
              <div dangerouslySetInnerHTML={{ __html: data }} />
            ) : (
              <div className="empty-state-wrapper">
                <NoData msg={t("no_data")} />
              </div>
            )}
          </Container>
        </section>
      </div>
    </div>
  );
}
