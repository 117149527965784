/* eslint-disable @typescript-eslint/no-unused-vars */
import {motion} from 'framer-motion'; // Import from framer-motion

// Import the images from the assets folder
import React, {useContext, useState} from "react";
import {LocaleContext, LocaleContextProps} from "../../contexts/LocaleContext";
import {FaTimes} from "react-icons/fa";
import useAuthServices from "../../api/useAuthServices";
import toast from 'react-hot-toast';
import {UserContext, UserContextProps} from "../../contexts/UserContext";
import {MotionInput} from "../../iComponents/MotionInput";

export default function ChangePassword({showEditPassword}: any) {
    const {t, direction, isRtl} = useContext(LocaleContext) as LocaleContextProps;
    const {currentUser} = useContext(UserContext) as UserContextProps;

    const {changePassword} = useAuthServices();

    const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
    const requiredError = t("This field is required");

    const [formData, setFormData] = useState({
        old_password: '',
        new_password: '',
        confirm_new_password: ''
    });

    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        setValidationErrors({
            ...validationErrors,
            [e.target.name]: e.target.value ? "" : requiredError,
        });
    };

    const handleChangePassword = (e: any) => {
        e.preventDefault();
        if(currentUser?.nameid){
            changePassword(currentUser?.nameid, formData.old_password, formData.new_password)
            .then(()=>{
                toast.success(t("Password updated successfully!"));
                showEditPassword(false);
            })
            .catch(()=>{
                toast.error(t("Error changing password"))
            });
        }
    }

    return (
        <motion.div
            initial={{opacity: 0, x: -50}}
            animate={{opacity: 1, x: 0}}
            transition={{duration: 0.5}}
            dir={direction}>
            <div className="title-myprofile">
                <h3>{t('Change Password :')}</h3>
                <a onClick={showEditPassword} className="edit-link" style={{cursor: "pointer"}}>
                    <FaTimes/> {t('Cancel')}
                </a>
            </div>
            <div className="form-edit-profile">
                <form>

                    <MotionInput
                        form = {formData}
                        name = "old_password"
                        type="password"
                        placeholder={t('Old Password')}
                        handleChange = {handleChange}
                        validationErrors = {validationErrors}
                        className="row input-form"
                    />

                    <MotionInput
                        form = {formData}
                        name = "new_password"
                        type="password"
                        placeholder={t('New Password')}
                        handleChange = {handleChange}
                        validationErrors = {validationErrors}
                        className="row input-form"
                    />

                    <MotionInput
                        form = {formData}
                        name = "confirm_new_password"
                        type="password"
                        placeholder={t('Confirm New Password')}
                        handleChange = {handleChange}
                        validationErrors = {validationErrors}
                        className="row input-form"
                    />

                    <div className="form-group row">
                        <div className="btn-change-password">
                            <button className={`ctm-btn2 float-${isRtl ? 'start' : 'end'}`} onClick={handleChangePassword}>{t('Save')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </motion.div>
    );
}
