/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaCalendar, FaMapMarkerAlt, FaUserCircle } from "react-icons/fa";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { Box, Button, Menu, MenuItem, Modal } from "@mui/material";

import AddSession from "./AddSession";
import useTicketsAPI from "../../api/ems/tickets";
import { formatSimpleDate } from "../../utils/dateUtils";
import NoData from "src/iComponents/NoData/NoData";
import { FaPrint, FaTicket } from "react-icons/fa6";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

const MenuListItem = ({ item, openTicket }: any) => {
  const { lang } = useContext(LocaleContext) as LocaleContextProps;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <motion.li
      key={item.id}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >

      <div className="text-bookings">
        <h2>{item.product?.name}</h2>
        {item.instructor && <h4>
            <FaUserCircle/>{" "}
          {item.instructor?.firstName + " " + item.instructor?.lastName}
        </h4>}
        <h6>
          <FaCalendar/>
          {formatSimpleDate(item.checkedInDate, lang)}
        </h6>
      </div>
      <div className="dots-bookings">
        {openTicket && <FaTicket
            color="#214899"
            size={22}
            cursor="pointer"
            onClick={() => openTicket(item)}
        />}
        {item.location && <FaMapMarkerAlt
            color="#214899"
            size={22}
            cursor="pointer"
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${item.location?.points?.latitude},${item.location?.points?.longitude}`
              )
            }
        />}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "150px",
              boxShadow: "0 0 5px #00000029",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem component={Link} to="/booking" onClick={handleClose}>
            Edit
          </MenuItem>
        </Menu>
      </div>
    </motion.li>
  );
};
export default function MySessions() {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const { currentUser } = useContext(UserContext) as UserContextProps
  const today = new Date();

  const { loadMySessions } = useTicketsAPI();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openTicketModal, setOpenTicketModal] = useState(false);

  const [sessions, setSessions] = useState<any[]>([]);
  const [selectedSession, setSelectedSession] = useState<any>()

  const printRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const previousTickets = sessions.filter((obj) => {
    const checkedInDate = moment(obj.checkedInDate)
    return checkedInDate.isBefore(moment());
  });

  const upcomingSessions = sessions.filter((obj) => !previousTickets.some(prev => prev.id === obj.id));

  const openTicket = (session: any) => {
    setSelectedSession(session);
    setOpenTicketModal(true)
  }

  const fetchTickets = () => {
    loadMySessions()
      .then((resp) => {
        if (resp.items && resp.items.length > 0) setSessions(resp.items);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    fetchTickets();
  }, []);


  const joinNames = (...names: string[]) => {
    return names.filter((name) => name && name !== "").join(" ");
  };

  return (
    <motion.li
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      dir={direction}
    >
      <div className="bookings" dir={direction}>
        <div className="tabs-bookings">
          <ul
            className="nav nav-tabs nav-fill mb-3"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active fs-5"
                id="pills-1-tab"
                data-toggle="pill"
                href="#pills-1"
                role="tab"
                aria-controls="pills-1"
                aria-selected="true"
              >
                {t("Upcoming Sessions")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link fs-5"
                id="pills-2-tab"
                data-toggle="pill"
                href="#pills-2"
                role="tab"
                aria-controls="pills-2"
                aria-selected="false"
              >
                {t("Previous Sessions")}
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-1"
            role="tabpanel"
            aria-labelledby="pills-1-tab"
          >
            <div className="main-bookings">
              {upcomingSessions && upcomingSessions.length >= 1 ? (
                <ul>
                  {upcomingSessions.map((item) => (
                    <MenuListItem key={item.id} item={item} openTicket={openTicket}/>
                  ))}
                </ul>
              ) : (
                <NoData msg={t("There is no sessions available")}/>
              )}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-2"
            role="tabpanel"
            aria-labelledby="pills-2-tab"
          >
            <div className="main-bookings">
              {previousTickets && previousTickets.length >= 1 ? (
                <ul>
                  {previousTickets.map((item) => (
                    <MenuListItem key={item.id} item={item} openTicket={openTicket}/>
                  ))}
                </ul>
              ) : (
                <NoData msg="There is no sessions available"/>
              )}
            </div>
          </div>
          <Modal open={openAddModal}>
            <AddSession handleModal={setOpenAddModal}/>
          </Modal>
          <Modal
            open={openTicketModal}
            onClose={() => {
              setOpenTicketModal(false)
              setSelectedSession(undefined)
            }}

          >
            <div style={{ width: 'fit-content' }}
                 className='h-100 mx-auto d-flex justify-content-center align-items-center' dir={direction}>
              <Box sx={modalStyle}>
                <div className='w-100 d-flex flex-row justify-content-center align-items-center mb-2'>
                  <Button onClick={handlePrint}><FaPrint size={26}/> {t('print')}</Button>

                </div>
                <div ref={printRef}
                     className='w-100 d-flex flex-row justify-content-center align-items-center flex-column gap-2 print'>
                  <span>User Id : {currentUser?.nameid}</span>
                  <div>
                    <span>{t('Name')} :</span><span> {joinNames(currentUser?.firstName ?? '', currentUser?.secondName ?? '', currentUser?.thirdName ?? '', currentUser?.lastName ?? '')}</span>
                  </div>
                  <div><span>{t('Session')} {t('Name')} :</span><span> {selectedSession?.product?.name}</span></div>
                  {selectedSession?.instructor &&
                  <div className='ticket-instructor d-flex flex-column justify-content-center align-items-center'><span
                      className="text-nowrap">{t('Instructor')} :</span><span
                      className='text-center text-wrap'> {joinNames(selectedSession.instructor.title, selectedSession.instructor.firstName, selectedSession.instructor.secondName, selectedSession.instructor.thirdName, selectedSession.instructor.lastName)}</span>
                  </div>}
                  <div><span>{t('Session')} :</span><span> {selectedSession?.product?.orderProductId}</span></div>
                  <QRCode size={128} value={selectedSession?.product?.securityStamp ?? ''}/>

                </div>
              </Box>
            </div>
          </Modal>
        </div>
        {/* <div className="and-booking">
        <a className="ctm-btn2" onClick={() => setOpenAddModal(true)}>
          {" "}
          {t("NEW")}{" "}
        </a>
      </div> */}
      </div>
    </motion.li>
  );
}

const modalStyle = {
  // position: 'absolute' as 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: "background.paper",
  borderRadius: 0,
  boxShadow: 24,
  p: 4,
  overflowY: "overlay",
  maxHeight: "100vh",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
};
