import React, { useContext } from "react";
import logoF from "./assets/Logo-f.svg";
import puy1 from "./assets/puy1.png";
import puy2 from "./assets/puy2.png";
import puy3 from "./assets/puy3.png";
import puy4 from "./assets/puy4.png";
import puy5 from "./assets/puy5.png";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";
import { FaFacebookF, FaInstagram, FaTwitter, } from "react-icons/fa";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import { extractYear } from "../../utils/dateUtils";

interface FooterProps {
  // Add any props the component requires here
}

const Footer: React.FC<FooterProps> = () => {
  const { t, direction, lang } = useContext(LocaleContext) as LocaleContextProps;

  return (
    <div>
      <footer>
        <div className="footer">
          <Container dir={direction} maxWidth="xl">
            <div className="main-footer">
              <div className="row align-items-start">
                <div className="col-lg-3">
                  <div className="logo-footer">
                    <Link to="/">
                      <img loading="lazy" src={logoF} alt=""/>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="sub-footer">
                    <Link to="/page/About">
                      <h2>{t("About Naj")}</h2>
                      <p>
                        {t(
                          "We're a specialized Center that offers educational services to all Dental students in Saudi Arabia .."
                        )}
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="sub-footer">
                    <Link to="/products">
                      <h2>{t("Our Courses")}</h2>
                      <p>
                        {t(
                          "Choose from over 5000 online video courses with new additions published every month ."
                        )}
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="sub-footer">
                    <Link to="/page/Contact">
                      <h2>{t("Contact Us")}</h2>
                      <p>
                        {t("Need anything ? Get in touch and we can help .")}
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="paymenyt-footer">
              <div className="sub-paymenyt-footer">
                <ul>
                  <li>
                    <img loading="lazy" src={puy1} alt=""/>
                  </li>
                  <li>
                    <img loading="lazy" src={puy2} alt=""/>
                  </li>
                  <li>
                    <img loading="lazy" src={puy3} alt=""/>
                  </li>
                  <li>
                    <img loading="lazy" src={puy4} alt=""/>
                  </li>
                  <li>
                    <img loading="lazy" src={puy5} alt=""/>
                  </li>
                </ul>
              </div>
            </div>
            <div className="end-footer">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="sub-end-footer">
                    <div className="element-footer">
                      <ul>
                        <li>
                          <Link to="/page/PrivacyPolicy">
                            {t("Privacy Policy")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/page/TermsOfUse">{t("Terms")}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="copy-right">
                    <p>&copy;{extractYear(new Date().toLocaleDateString(), lang) + t("copyRight")}</p>
                    <div className="media-footer">
                      <ul>
                        <li>
                          <a href="https://twitter.com/najtraining">
                            <FaTwitter/>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/najtraining">
                            <FaInstagram/>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/p/NAJ-Training-Center-100071833284006">
                            <FaFacebookF/>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
