import humanizeDuration from "humanize-duration";
import moment from "moment";
import * as React from "react";
import { useContext } from "react";
import { TimeLineItem } from "./TimeLineItem";
import EventSpeakers from "../../pages/Events/EventSpeakers";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import { Link } from "react-router-dom";

export interface SessionItemProps {
  session: any;
}

export const SessionItem: React.FC<SessionItemProps> = props => {
  const { session:item } = props;
  const { lang } = useContext(LocaleContext) as LocaleContextProps;


  const sessionStartTime = moment(item.startDateTime);
  const sessionEndTime = moment(item.endDateTime);
  const sessionDuration = moment.duration(
    sessionEndTime.diff(sessionStartTime)
  );


  return (
    <TimeLineItem
      key={item.id}
      startTime={sessionStartTime}
      endTime={sessionEndTime}
    >
      <div className="d-flex justify-content-between">
        <div>
          {item.isCourse ? <Link to={'/ProductDetails/' + item.id}>
            <h5>
              {item.name}
            </h5>
          </Link> : <h5>
            {item.title}
          </h5>}

          <h6>
            ({humanizeDuration(
            sessionDuration.asMilliseconds(),
            {
              round: true,
              units: ["d", "h", "m"],
              language: lang
            }
          )})
          </h6>
          <p>
            <EventSpeakers
              speakers={item.isCourse ? item.instructors : item.speakers}/>
          </p>
        </div>
        {!item.isCourse && item.location && <div>
          <span className="text-muted">{item.location}</span>
        </div>}


      </div>
    </TimeLineItem>
  );
};
