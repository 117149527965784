import React, { Fragment, useContext, useEffect, useState } from "react";
import { IDuration, IMembership } from "./Memberships";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import Select, { SingleValue } from "react-select";
import { Alert, Modal } from "@mui/material";
import DoneIcon from "@mui/icons-material/CheckCircle";
import "./style.css";
import { CartContext } from "src/contexts/CartContext";
import { UserContext, UserContextProps } from "src/contexts/UserContext";
import LoginSM, { FormMode } from "../LoginSM/LoginSM";
import CloseIcon from "@mui/icons-material/Close";

interface IMembershipCardProps {
  membership: IMembership;
  currentDuration?: IDuration;
  selected?: boolean;
}

const MembershipCard = ({
  membership,
  currentDuration,
  selected,
}: IMembershipCardProps) => {
  const { addToCart, cartItems, removeFromCart } = useContext(CartContext);
  const { isAuthenticated } = useContext(UserContext) as UserContextProps;
  const isPlanExist = cartItems.find((item: any) => item.id === membership.id);
  const [selectedDuration, setSelectedDuration] = useState<IDuration>(
    currentDuration ?? membership.durations[0] ?? null
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const { t, isRtl } = useContext(LocaleContext) as LocaleContextProps;
  const durations = membership.durations.map((duration) => ({
    id: duration.id,
    value: duration.durationInDays
      ? `${duration.durationInDays} ${t("Days")}`
      : t("Unlimited"),
  }));

  const onSelectDuration = (
    duration: SingleValue<{ id: number; value: string }>
  ) => {
    const durationSelected = membership.durations.find(
      (d) => d.id === duration?.id
    );
    durationSelected && setSelectedDuration({ ...durationSelected });
  };

  useEffect(() => {
    if (isPlanExist) {
      const durationSelected = membership.durations.find(
        (d) => d.id === isPlanExist?.membershipDurationId
      );
      durationSelected && setSelectedDuration({ ...durationSelected });
    }
  }, [isPlanExist]);

  const handleUpgrade = () => {
    if (isAuthenticated) {
      addToCart({
        id: membership.id,
        name: membership.name,
        count: 1,
        image: null,
        totalPrice: selectedDuration.price.totalPrice,
        selectedCheckedInDate: null,
        selectedInstructorId: null,
        selectedLocationId: null,
        selectedAddonsIds: [],
        addons: [],
        speakers: [],
        locations: [],
        maxQTYPerOrder: null,
        minQTYPerOrder: null,
        price: selectedDuration.price,
        membershipDurationId: selectedDuration.id,
      });
    } else {
      setModalOpen(true);
    }
  };
  const handleRemove = () => {
    removeFromCart(membership.id);
  };

  return (
    <div className="col-lg-6 col-md-6 col-sm-6 membership-card">
      <div className={`sub-memberships ${selected ? "selected" : ""}`}>
        <div className="text-memberships">
          <h2 className={'text-center'}>{t(membership.name)}</h2>
          <div className="price-memberships">
            {selectedDuration?.price?.discount > 0 && (
              <span className="old-price">
                {selectedDuration?.price.displayTotalPriceWithoutDiscount}
              </span>
            )}
            <h2>{selectedDuration?.price?.displayTotalPrice}</h2>
          </div>

          <div style={{height:"200px"}} className={'text-justify overflow-auto membership-details'}
            dangerouslySetInnerHTML={{ __html: t(membership.description) }}
          />
          <div className="text-center">
            {durations.length > 0 ? (
              <Fragment>
                <label style={{ padding: "0" }}>{t("duration")} :</label>
                {durations.length === 1 ? (
                  <p>{durations[0].value}</p>
                ) : (
                  <Select
                    isDisabled={selected}
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={false}
                    isRtl={isRtl}
                    placeholder={t("duration")}
                    name="category"
                    value={{
                      id: selectedDuration.id,
                      value: `${selectedDuration.durationInDays} ${t("Days")}`,
                    }}
                    onChange={(val) => onSelectDuration(val)}
                    options={durations}
                    getOptionLabel={(option) => option.value}
                    getOptionValue={(option) => "" + option.id}
                  />
                )}
              </Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
        {selected ? (
          <Fragment>
            <div className="btn-memberships">
              <Alert color="info" style={{ width: "100%" }}>
                {t("Current")}
              </Alert>
            </div>
            <DoneIcon className="selected-icon" />
          </Fragment>
        ) : (
          <div className="btn-memberships">
            {isPlanExist ? (
              <div
                className="ctm-btn2"
                onClick={handleRemove}
                style={{ cursor: "pointer" }}
              >
                {t("remove_from_cart")}
              </div>
            ) : (
              <div
                className="ctm-btn2"
                onClick={handleUpgrade}
                style={{ cursor: "pointer" }}
              >
                {t("Subscribe")}
              </div>
            )}
          </div>
        )}
      </div>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="login-modal"
          id="loginModal"
        >
          <div onClick={(e) => e.stopPropagation()}>
            <CloseIcon
              onClick={() => setModalOpen(false)}
              id="CloseIcon"
              style={{
                color: "#fff",
                cursor: "pointer",
                position: "absolute",
                top: "1rem",
                right: "1rem",
                height: "40",
                width: "40",
              }}
            />

            <LoginSM mode={FormMode.SignUp} />
          </div>
        </Modal>
      )}
    </div>
  );
};
export default MembershipCard;
