import { motion } from "framer-motion";
import LegalText from "./LegalText/LegalText";
import React, { useContext, useState } from "react";
import { FormMode } from "./LoginSM";
import {
  LocaleContext,
  LocaleContextProps,
} from "../../contexts/LocaleContext";
import useAuthServices from "../../api/useAuthServices";
import { MotionInput } from "../../iComponents/MotionInput";
import "./LoginSM.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FullName } from "../../iComponents/FullName";
import { IPhoneInput } from "../../iComponents/PhoneInput/PhoneInput";
import {
  ResetPasswordContext,
  ResetPasswordContextProps,
} from "src/contexts/ResetPasswordContext";

export function SignUpForm({ setIsSubmitting, setError, setFormMode }: any) {
  const { infos, setInfos } = useContext(
    ResetPasswordContext
  ) as ResetPasswordContextProps;
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const { signup } = useAuthServices();
  const navigate = useNavigate();

  const requiredError = t("This field is required");

  const [signUpForm, setSignUpForm] = useState({
    email: "",
    firstName: "",
    secondName: "",
    thirdName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});

  const handleChange = (e: any) => {
    setSignUpForm({
      ...signUpForm,
      [e.target.name]: e.target.value,
    });

    setValidationErrors({
      ...validationErrors,
      [e.target.name]: e.target.value ? "" : requiredError,
    });
  };

  const handleSignUp = (e: any) => {
    e.preventDefault();
    const errors = Object.values(validationErrors).every(
      (value) => value !== ""
    );

    if (!errors) {
      setIsSubmitting(true);
      signup({
        ...signUpForm,
        // phoneNumber: signUpForm.phoneNumber.replaceAll(' ','-')
        //     .replaceAll('+', '')
      })
        .then(() => {
          setError("");
          setValidationErrors({});
          toast.success(t("Account added successfully!"));
          setIsSubmitting(false);
          setInfos({ ...infos, email: signUpForm.email });
          setFormMode(FormMode.SignUpVerfiy);
        })
        .catch((error) => {
          setIsSubmitting(false);
          setError(error?.response?.data?.detail || t("SignUp Failed"))

        });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
      className="animate__animated animate__fadeIn"
      id="smForm"
    >
      <FullName
        form={signUpForm}
        handleChange={handleChange}
        validationErrors={validationErrors}
      />

      <MotionInput
        form={signUpForm}
        name="email"
        placeholder={t("emailPlaceholder")}
        handleChange={handleChange}
        validationErrors={validationErrors}
      />

      <IPhoneInput
        form={signUpForm}
        name="phoneNumber"
        handleChange={handleChange}
        placeholder={t("MobileNoPlaceholder")}
        validationErrors={validationErrors}
        direction={direction}
      />
      {/*<MotionInput*/}
      {/*    form = {signUpForm}*/}
      {/*    name = "phoneNumber"*/}
      {/*    placeholder = {t("MobileNoPlaceholder")}*/}
      {/*    handleChange = {handleChange}*/}
      {/*    validationErrors = {validationErrors}*/}
      {/*/>*/}

      <MotionInput
        form={signUpForm}
        name="password"
        type="password"
        placeholder={t("passwordPlaceholder")}
        handleChange={handleChange}
        validationErrors={validationErrors}
      />

      <MotionInput
        form={signUpForm}
        name="confirmPassword"
        type="password"
        placeholder={t("confirmPasswordPlaceholder")}
        handleChange={handleChange}
        validationErrors={validationErrors}
      />

      <motion.button
        onClick={handleSignUp}
        className="LoginSMsigin-btn"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {t("signUpButton")}
      </motion.button>
      <p className="LoginSMsignup-link">
        {t("alreadyHaveAccount")}

        <a
          style={{ color: "#398dde" }}
          onClick={() => {
            setError("");
            setFormMode(FormMode.SignIn);
          }}
        >
          {t("signIn")}
        </a>
      </p>

      {/*<AuthProviders/>*/}

      {/*<br/>*/}

      <LegalText />
    </motion.div>
  );
}
