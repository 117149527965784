/* eslint-disable jsx-a11y/anchor-is-valid */
import SubHeaderSection from "../../components/SubHeaderSection/SubHeaderSection";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import ProductsSection from "../../components/ProductsSection/ProductsSection";
import SpeakersSection from "../../components/SpeakersSection/SpeakersSection";
import BannerSection from "../../components/BannerSection/BannerSection";
import NewsSection from "../../components/NewsSection/NewsSection";
import PartnersSection from "../../components/Partners/PartnersSection";
import { Container } from "@mui/material";
import Announcement from "src/components/Announcement/Announcement";
import { useContext } from "react";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";

export default function Home() {
    const { t,isRtl } = useContext(LocaleContext) as LocaleContextProps;
    return (
        <>
            <main id="app">
                <Announcement />
                <div id="content">
                    <Container maxWidth="xl">
                        <SubHeaderSection/>
                    </Container>
                    {/*<CounterSection/>*/}
                    <Container maxWidth="xl">
                        <ServicesSection/>
                        <ProductsSection/>
                        <SpeakersSection/>
                        <BannerSection/>
                        <NewsSection/>
                        <PartnersSection/>

                    </Container>
                </div>
            </main>
        </>
    );
}
