/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { ScrollContext, ScrollContextProps } from "../../contexts/ScrollContext";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import useProductsAPI from "../../api/ems/products";
import "./ProductsSection.css";
import { IProductCategory, IProductsByCategory } from "../../api/ems/schemas";
import { ProductCategoriesTabs } from "./ProductCategoriesTabs";

export default function ProductsSection() {
    const {t, direction,isRtl} = useContext(LocaleContext) as LocaleContextProps;
    const {sectionRef} = useContext(ScrollContext) as ScrollContextProps;


    const [productsCategories, setProductsCategories] = useState<IProductCategory[]>([]);
    const [productsByCategory, setProductsByCategory] = useState<IProductsByCategory[]>([]);
    const [loading,setLoading] = useState(true);

    const {loadProductsCategories, loadProductsByCategory} = useProductsAPI();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const categories = await loadProductsCategories();
                setProductsCategories(categories);

                if (categories && categories.length >= 1) {
                    categories.forEach(category => {
                        const categoryId = category.id;
                        loadProductsByCategory(categoryId).then(response => {
                            const productByCategory: IProductsByCategory = { category: categoryId, products: response };
                            setProductsByCategory((old) => [...old, productByCategory]);
                        }).catch(() => {
                            setProductsByCategory((old) => [...old, { category: categoryId, products: [] }]);
                        })
                    });
                }
                
            } catch (err) {
                console.error(err);
            }
        };

        fetchData().then(value => {
            setLoading(false)
        });
    }, [isRtl]);


    return (
        <div ref={sectionRef} dir={direction}>
            <section className="products-index mr-section">
                <div className="main-container">
                    <div className="title-center">
                        <h3>{t("Products")} </h3>
                    </div>
                    <ProductCategoriesTabs loading={loading} productsCategories={productsCategories} productsByCategory={productsByCategory}/>
                </div>
            </section>
        </div>
    );
}
