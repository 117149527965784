import React, { createContext, Dispatch, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const localeUtils = {
    EN: 'en',
    AR: 'ar',
    RTL: 'rtl',
    LTR: 'ltr',
};

export interface LocaleContextProps {
    t: any;
    i18n: any;
    lang: string;
    changeLang: Dispatch<string>;
    direction:  string;
    isRtl: boolean
}

const LocaleContext = createContext<LocaleContextProps | undefined>(undefined);

const LocaleProvider = ({ children } : any) => {

    const { t, i18n } = useTranslation();
    const [langParam, setLangParam] = useSearchParams({ lang: localStorage.getItem("selectedLanguage") || localeUtils.EN });
    const [lang, changeLang] = useState(langParam.get('lang') || localStorage.getItem("selectedLanguage") || localeUtils.EN);
    const [isRtl, setIsRtl] = useState(false);

    useEffect(()=>{
        i18n.changeLanguage(lang);
        const rootElement = document.getElementById('rootHTML');
        if (rootElement) {
            if (lang === localeUtils.AR) {
                rootElement.dir = localeUtils.RTL;
                setIsRtl(true);
            } else {
                rootElement.dir = localeUtils.LTR;
                setIsRtl(false);
            }
        }
        // setLangParam(lang);
    }, [lang])

    return (
        <LocaleContext.Provider
            value={{
                t: t,
                i18n: i18n,
                lang: lang,
                changeLang: changeLang,
                direction: lang === localeUtils.AR ? localeUtils.RTL : localeUtils.LTR,
                isRtl: isRtl//lang === localeUtils.AR,
            }}
        >
            {children}
        </LocaleContext.Provider>
    );
}

export { LocaleProvider, LocaleContext };