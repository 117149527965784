import {useContext} from 'react';
// import { PhoneInput } from 'react-international-phone';
// import 'react-international-phone/style.css';
import ar from 'react-phone-number-input/locale/ar.json'
import en from 'react-phone-number-input/locale/en.json'

import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'

import 'react-phone-number-input/style.css'
import "./index.css";
import {LocaleContext, LocaleContextProps} from "../../contexts/LocaleContext";

export const IPhoneInput = ({form, name, placeholder, handleChange, validationErrors, className, direction} : any) => {

    const {isRtl} = useContext(LocaleContext) as LocaleContextProps;

    return (
        <div className={`form-group ${className}`} style={{padding: "0 10px"}} dir={direction}>
            <label htmlFor={name} style={{padding: "0"}}>{placeholder} :</label>

            <PhoneInput
                labels={isRtl ? ar : en}
                placeholder={placeholder}
                className="LoginSMinput"
                defaultCountry="SA"
                value={form[name]}
                onChange={(phone)=>handleChange({target:{
                        name: name,
                        value: phone
                }})}
                style={(form[name] && !isValidPhoneNumber(form[name])) || (validationErrors && validationErrors[name]) ? {borderColor:'red'}:{}}
            />

            {/*<PhoneInput*/}
            {/*    placeholder={placeholder}*/}
            {/*    className="LoginSMinput"*/}
            {/*    defaultCountry="sa"*/}
            {/*    value={form[name]}*/}
            {/*    onChange={(phone)=>handleChange({target:{*/}
            {/*        name: name,*/}
            {/*        value: phone*/}
            {/*    }})}*/}

            {/*    style={{height: "60px", padding: "0"}}*/}
            {/*/>*/}
        </div>
    );
};