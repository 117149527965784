/* eslint-disable jsx-a11y/anchor-is-valid */
import { IconButton, Menu, MenuItem, Modal } from "@mui/material";
import "animate.css";
import React, { useContext, useEffect, useState } from "react";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { motion } from "framer-motion";
import { FaEdit } from "react-icons/fa";
import "./Cases.css";
import { AddEditCase } from "./AddEditCase";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useCasesAPI from "../../api/ems/cases";
import { ICase } from "../../api/ems/schemas";
import NoData from "../../iComponents/NoData/NoData";
import { formatSimpleDate } from "src/utils/dateUtils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import toast from "react-hot-toast";

enum CaseStatus {
  Accepted = "Accepted",
  Pending = "Pending",
  Drafted = "Draft",
  Rejected = "Rejected",
}

const CaseRow = ({ data, showCaseInfo, setCaseToEdit, setShowDelete, setSelectedCase }: any) => {
  const { t, lang } = useContext(LocaleContext) as LocaleContextProps;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCaseStatusClass = (caseStatus: string) => {
    if (caseStatus === CaseStatus.Accepted) {
      return "payment-accepted";
    } else if (caseStatus === CaseStatus.Pending) {
      return "payment-pending";
    } else if (caseStatus === CaseStatus.Drafted) {
      return "payment-drafted";
    } else {
      return "payment-error";
    }
  };

  return (
    <tr>
      <td>{data.id}</td>
      <td>{data.title}</td>
      <td>{data.postCategory.name}</td>
      {/*<td>{data.postCategory.pType}</td>*/}
      <td>
        <p style={{ marginBottom: 0 }} className={`stats ${getCaseStatusClass(data.status.name)}`}>
          {t(data.status.name)}
        </p>
      </td>
      <td>{formatSimpleDate(data?.postTime, lang)}</td>
      <td>
        <IconButton
          aria-label="more"
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon/>
        </IconButton>
        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className="toolbar-menu"
        >
          {
            data.status.id === 1 && (
              <MenuItem onClick={() => {
                handleClose();
                setCaseToEdit(data);
              }}>{t("Edit")}</MenuItem>
            )
          }

          <MenuItem onClick={() => {
            setSelectedCase(data);
            setShowDelete(true);
            handleClose();
          }}>{t("Delete")}</MenuItem>
        </Menu>
      </td>
    </tr>
  );
};

export default function MyCases() {
  const { t, direction } = useContext(
    LocaleContext
  ) as LocaleContextProps;

  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showInfos, setShowInfos] = useState(false);
  const [selectedCase, setSelectedCase] = useState<ICase | null>(null);

  const [cases, setCases] = useState<ICase[] | null>(null);
  const [caseToEdit, setCaseToEdit] = useState<ICase | null>(null);


  const { loadCases, deleteCase } = useCasesAPI();

  const fetchCases = () => {
    loadCases()
      .then((data) => {
        setCases(data.items);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchCases();
  }, []);


  const showCaseInfo = (ccase: ICase) => {
    setSelectedCase(ccase);
    setShowInfos(true);
  };

  const handleClose = (success: boolean) => {
    setShowAdd(false);
    setCaseToEdit(null);
    if (success) {
      fetchCases();
    }
  };

  const handleCourseDelete = () => {
    deleteCase(selectedCase?.id).then(() => {
      fetchCases();
      setShowDelete(false);
    }).catch((error) => toast.error(
      error?.response?.data?.detail ||
      "Something went wrong. please try again later."
    ));
  }
  return (
    <>
      <div className="myCasesContainer">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          dir={direction}
        >
          <div className="title-myprofile">
            <h3>{t("My Cases")}</h3>
            <a
              className="edit-link"
              onClick={() => setShowAdd(true)}
              style={{ cursor: "pointer" }}
            >
              <FaEdit/> {t("Add Case")}
            </a>
          </div>
          <br/>
          <div className="orders" dir={direction}>
            <div className="table-order h-100">

              {(!cases || cases.length === 0) ? (
                  <NoData msg="There are no cases"/>
                ) :
                <table className="table animate__fadeIn animate__animated ">
                  <thead>
                  <tr>
                    <th scope="col">{t("Case no.")}</th>
                    <th scope="col">{t("Case title")}</th>
                    <th scope="col">{t("Case category")}</th>
                    {/*<th scope="col">{t('Case tag')}</th>*/}
                    <th scope="col">{t("Case status")}</th>
                    <th scope="col">{t("Case date")}</th>
                    <th scope="col"></th>
                  </tr>
                  </thead>
                  <tbody>
                  {cases &&
                  cases.map((data, index) => (
                    <CaseRow
                      key={index}
                      data={data}
                      setCaseToEdit={setCaseToEdit}
                      showCaseInfo={showCaseInfo}
                      setShowDelete={setShowDelete}
                      setSelectedCase={setSelectedCase}
                    />
                  ))}
                  </tbody>
                </table>}
            </div>
          </div>
        </motion.div>
        {(showAdd || !!caseToEdit) && (
          <Modal
            open={showAdd || !!caseToEdit}
            onClose={() => setShowAdd(false)}
          >
            <AddEditCase
              caseToEdit={caseToEdit}
              handleClose={(success: boolean) => handleClose(success)}
            />
          </Modal>
        )}


        {showDelete && (
          <Modal
            open={showDelete}
            onClose={() => setShowDelete(false)}
            aria-labelledby="logout-modal-title"
            aria-describedby="logout-modal-description"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              dir={direction}
              sx={{
                p: 4,
                maxWidth: 500,
                minHeight: 100,
                mx: "auto",
                mt: 6,
                background: "white",
                borderRadius: "8px",
              }}
            >
              <Typography variant="h5" id="logout-modal-title" gutterBottom>
                {t("Are you sure you want to delete ?")}
              </Typography>
              <Typography>
                <Button variant="contained" color="error" onClick={handleCourseDelete}>
                  {t("Yes, delete case")}
                </Button>
                <Button
                  variant="outlined"
                  sx={{ mx: 2 }}
                  onClick={() => setShowDelete(false)}
                >
                  {t("Cancel")}
                </Button>
              </Typography>
            </Box>
          </Modal>
        )}

        {showInfos && (
          <Modal
            open={showInfos}
            onClose={() => setShowInfos(false)}
            aria-labelledby="logout-modal-title"
            aria-describedby="logout-modal-description"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              dir={direction}
              sx={{
                p: 4,
                minWidth: 300,
                minHeight: 150,
                mx: "auto",
                mt: 6,
                background: "white",
                borderRadius: "8px",
              }}
            >
              <Typography variant="h5" id="logout-modal-title" gutterBottom>
                {t("Case info")} :
              </Typography>
              {selectedCase && (
                <>
                  <Typography>
                    <strong>
                      {selectedCase.status.name === CaseStatus.Accepted
                        ? t("Case has been accepted")
                        : ""}
                      {selectedCase.status.name === CaseStatus.Rejected
                        ? t("Case has been rejected")
                        : ""}
                      {selectedCase.status.name === CaseStatus.Pending
                        ? t("Case has been sent")
                        : ""}
                    </strong>
                  </Typography>
                  <Typography>{t("Contact us for more infos")}</Typography>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ ml: 2 }}
                  onClick={() => setShowInfos(false)}
                >
                  {t("Cancel")}
                </Button>
              </div>
            </Box>
          </Modal>
        )}
      </div>
    </>
  );
}
