import React, { useContext } from "react";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { CartContext } from "../../contexts/CartContext";
import CircularProgress from "@mui/material/CircularProgress";
import { currencyFormate } from "src/utils/dateUtils";

interface CartSummaryProps {
  confirmMsg: string;
  handleConfirm: () => void;
  disableConfirm?: boolean;
  isLoading?: boolean;
}

export const CartSummary = ({
  confirmMsg,
  handleConfirm,
  disableConfirm,
  isLoading,
}: CartSummaryProps) => {
  const { t } = useContext(LocaleContext) as LocaleContextProps;
  const { cartItems, coupons, removeCoupon, backendPriceDetails } =
    useContext(CartContext);
  const disableConfirmBtn = disableConfirm;
  const totalItems = cartItems?.reduce((total, item) => total + item.count, 0);

  return (
    <div className="cart-summary">
      <div className="title-cart-summary">
        <h2>{t("Cart Summary")}</h2>
      </div>
      <div className="paymentItems">
        <ul>
          <li>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ gap: "8px" }}
            >
              {t('Subtotal')} ({totalItems})
            </div>

            <div>
              <span id="sub_total">{backendPriceDetails.subTotal}</span>
            </div>
          </li>
          {backendPriceDetails.totalProductsDiscountsAmount ? (
            <li>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "8px" }}
              >
                {t('Discount')}
              </div>

              <div style={{ color: "red" }}>
                <span id="sub_total" style={{ color: "red" }}>
                  - {backendPriceDetails.totalProductsDiscountsAmount}
                </span>
              </div>
            </li>
          ): null}
          {coupons.map((coupon: any) => (
            <li key={coupon.id}>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "8px" }}
              >
                {coupon.couponCode}
                <span
                  onClick={() => {
                    removeCoupon(coupon);
                  }}
                  style={{ cursor: "pointer", color: "red", fontSize: "12px" }}
                >
                  Remove
                </span>
              </div>
              <div style={{ color: "red" }}>
                <span style={{ color: "red" }}>
                  - {currencyFormate(coupon.couponDiscountAmount)}
                </span>{" "}
                {t('SAR')}
              </div>
            </li>
          ))}
          <li>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ gap: "8px" }}
            >
              {t('all_taxes')}
            </div>
            <div>
              <span id="sub_total">{backendPriceDetails.totalVatAmount}</span>
            </div>
          </li>
          <li>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ gap: "8px" }}
            >
              {t('other_taxes')}
            </div>
            <div>
              <span id="sub_total">{backendPriceDetails.totalOtherTaxesAmount}</span>
            </div>
          </li>
        </ul>
      </div>
      <div className="total-price-cart-page">
        <h3>
          {t("Grand Total")}
          <div>
            <span id="total"> {backendPriceDetails.finalPrice}</span> 
          </div>
        </h3>
        <button
          className={disableConfirmBtn ? "ctm-btn2" : "ctm-btn"}
          style={{
            width: "100%",
            display: isLoading ? "flex" : "inline-block",
            alignItems: "center",
            justifyContent: "center",
            gap: isLoading ? "8px" : "0",
            opacity: disableConfirmBtn ? '.3' : 1,
          }}
          onClick={handleConfirm}
          disabled={disableConfirmBtn}
        >
          {isLoading && <CircularProgress size={26} />}
          {t(confirmMsg)}
        </button>
      </div>
    </div>
  );
};
