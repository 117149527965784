import useAxiosWithInterceptor from "../useAxiosWithInterceptor";
import { AxiosResponse } from "axios";

const sponsersHome = '/api/Home/Sponsors';

const useSponsorsApi = () => {
  const { axiosInstance } = useAxiosWithInterceptor();

  const loadSponsersHome = (): Promise<[]> =>
    axiosInstance
      .get(sponsersHome)
      .then((response: AxiosResponse<any>) => response.data.items)
      .catch((error: any) => {
        throw error;
      });



  return { loadSponsersHome };
};

export default useSponsorsApi;
