import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from './assets/Logo.svg';
import padlock from './assets/padlock.png';
import { Container } from '@mui/material';
import { motion } from 'framer-motion';

export default function ForgetPasssword() {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const direction = isArabic ? 'rtl' : 'ltr';


  return (
    <div>
      <section className="forget-passsword login-page" dir={direction}>
          <div className="row pg_none">
            <motion.div
              className="col-lg-7 pg_none"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              <div className="title-login">
                <h2>{t('NAJ Training Center')}</h2>
                <p>
                  {t(
                    "We're a specialized Center that offers educational services to all Dental students in Saudi Arabia .."
                  )}
                </p>
              </div>
            </motion.div>
            <motion.div
              className="col-lg-5"
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <div className="main-passsword main-login">
                <div className="logo-login">
                  <Link to="/">
                    <img loading="lazy" src={logo} alt={t('NAJ Training Center')} />
                  </Link>
                </div>
                <div className="text-login-main">
                  <h2>{t('Change Password')}</h2>
                  <p>
                    {t('Already a member?')}
                    <Link to="/login">{t('Login')}</Link>
                  </p>
                </div>
                <div className="form-login">
                  <form>
                    <motion.div
                      className="input-form"
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.5, delay: 0.4 }}
                    >
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder={t('New Password')}
                      />
                      <img loading="lazy" src={padlock} alt={t('Password')} />
                    </motion.div>
                    <motion.div
                      className="input-form"
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.5, delay: 0.6 }}
                    >
                      <input
                        type="password"
                        className="form-control"
                        name="confirm_password"
                        placeholder={t('Confirm New Password')}
                      />
                      <img loading="lazy" src={padlock} alt={t('Confirm Password')} />
                    </motion.div>
                    <motion.div
                      className="btn-form-login"
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.5, delay: 0.8 }}
                    >
                      <button className="ctm-btn2">{t('Save')}</button>
                    </motion.div>
                  </form>
                </div>
              </div>
            </motion.div>
          </div>
      </section>
    </div>
  );
}
