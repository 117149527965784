import React from 'react';
import { useTranslation } from 'react-i18next';
import './LegalText.css';

const LegalText: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <p className="legal-text">
        {t('legalText')}  <a href="/page/PrivacyPolicy">{t('Privacy Policy')}</a> {t('and')} <a href="/page/TermsOfUse">{t('Terms & Conditions')}</a>
      </p>
    </>
  );
};

export default LegalText;
