import { AxiosResponse } from "axios";
import useAxiosWithInterceptor from "./useAxiosWithInterceptor";

const changePasswordUrl = "api/TenantUser/ChangePassword"; //""/Account/Password";
const editProfileUrl = "api/TenantUser/EditProfile";
const getProfileUrl = "/api/TenantUser/CurrentUserProfile";
const forgotPasswordVerification = "api/Authorization/ForgetPassword";
const currentMembershipPlan = "/api/TenantUser/CurrentUserMembershipsPlans";

const useAuthServices = () => {
  const { axiosInstance } = useAxiosWithInterceptor();

  const signup = (data: any): Promise<any> =>
    axiosInstance
      .post("api/TenantUser/Register", data)
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const login = (username: string, password: string): Promise<any> =>
    axiosInstance
      .post("/connect/token", {
        username,
        password,
      })
      .then((response: AxiosResponse<any>) => {
        axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`;
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });

  const changePassword = (
    oAuthId: string,
    oldPassword: string,
    newPassword: string
  ): Promise<any> =>
    axiosInstance
      .put(changePasswordUrl, { oAuthId, oldPassword, newPassword })
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const editProfile = (id: string, data: any): Promise<any> =>
    axiosInstance
      .put(
        editProfileUrl, //.concat(`/${id}`)
        { ...data, CurrentUserId: id },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const getProfile = (): Promise<any> =>
    axiosInstance
      .get(getProfileUrl)
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const getCurrentMembership = (): Promise<any> =>
    axiosInstance
      .get(currentMembershipPlan)
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const sendforgetPasswordVerification = (
    email: string | null,
    mobile: string | null
  ): Promise<any> =>
    axiosInstance
      .post(forgotPasswordVerification, {
        email,
        mobile,
      })
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const forgetPassword = (email: string, mobile: string): Promise<any> =>
    axiosInstance
      .post("/api/Authorization/ForgetPassword", {
        email,
        mobile,
      })
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const resetPassword = (
    email: string | null,
    mobile: string | null,
    otp: string,
    newPassword: string,
    confirmPassword: string
  ): Promise<any> =>
    axiosInstance
      .post("/api/Authorization/ResetPassword", {
        email,
        mobile,
        otp,
        newPassword,
        confirmPassword,
      })
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const verifyOTPCall = (email: any, mobile: any, otp: any): Promise<any> =>
    axiosInstance
      .post("/api/Authorization/VerifyResetPasswordOTP", {
        email,
        mobile,
        otp,
      })
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const verifySignup = (email: any, otp: any): Promise<any> =>
    axiosInstance
      .post("/Connect/ConfirmEmail", {
        email,
        otp,
      })
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const resendConfirmationEmail = (email: any): Promise<any> =>
    axiosInstance
      .post("/Connect/ResendConfirmEmail", {
        email,
      })
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  return {
    signup,
    login,
    changePassword,
    editProfile,
    sendforgetPasswordVerification,
    forgetPassword,
    verifyOTPCall,
    resetPassword,
    getProfile,
    getCurrentMembership,
    verifySignup,
    resendConfirmationEmail
  };
};

export default useAuthServices;
