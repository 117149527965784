import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { Link } from "react-router-dom";
import { Drawer } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import CartItem from "./CartItem";
import { CartContext } from "../../contexts/CartContext";
import NoData from "src/iComponents/NoData/NoData";
import useUserCartAPI from "src/api/ems/user-cart";
import { UserContext, UserContextProps } from "src/contexts/UserContext";
import { synchronizeCarts } from "src/pages/Cart/utils";

interface CartDrawerProps {
  closeDrawerCart: () => void;
  anchor2: any;
  isCartOpen: boolean;
  setCartOpen: (open: boolean) => void;
}

export function CartDrawer({
  closeDrawerCart,
  anchor2,
  isCartOpen,
  setCartOpen,
}: CartDrawerProps) {
  const { t, direction,isRtl } = useContext(LocaleContext) as LocaleContextProps;
  const { isAuthenticated } = useContext(UserContext) as UserContextProps;

  const {
    loadReadyUserCart,
    reviewReadyCartCoupons,
    addToUserCart,
    updateToUserCart,
    deleteFromUserCart,
  } = useUserCartAPI();

  const {
    cartItems,
    setCartItems,
    addToCart,
    removeFromCart,
    backendPriceDetails,
    setBackendPriceDetails,
  } = useContext(CartContext);

  useEffect(() => {
    if (isAuthenticated) {
      loadReadyUserCart().then((response) => {
        synchronizeCarts({
          backendCart: response,
          cartItems,
          setCartItems,
          addToUserCart,
          updateToUserCart,
          deleteFromUserCart,
          setBackendPriceDetails,
          loadReadyUserCart,
        });
      }).then(()=>
        reviewReadyCartCoupons().then(response=>{
          setBackendPriceDetails({ ...backendPriceDetails, coupons:response.map(value => ({
              couponCode: value.couponCode,
              discountAmount: value.couponDiscountAmount
            })) })
        } ).catch((error => console.error('ERROR', error)))
      ).catch((error => console.error('ERROR', error)));
    }
  }, [isAuthenticated,isRtl]);

  return (
    <Drawer
      anchor={anchor2}
      open={isCartOpen}
      onClose={() => setCartOpen(false)}
      id="menuDrawer"
      dir={direction}
    >
      <CancelPresentationIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "2rem",
          left: "1rem",
          zIndex: "9999",
        }}
        onClick={() => closeDrawerCart()}
      />
      <div
        id="shopping_cart"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          minWidth: "350px",
          padding: "2rem 0",
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <h3 style={{ marginBottom: "1rem" }}>{t("shopping_cart")}</h3>

        {cartItems?.length <= 0 && (
          <div style={{ width: "100%" }}>
            <NoData msg={t('cart_is_empty')} />
          </div>
        )}
        <div
          className="cart-list-warp"
          style={{ width: "100%", overflowY: "auto" }}
        >
          {cartItems?.length > 0 && (
            <ul>
              {cartItems.map((item) => (
                <li key={item.id}>
                  <CartItem
                    item={item}
                    onAdd={addToCart}
                    onRemove={removeFromCart}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>

        {cartItems?.length > 0 && (
          <div className="btns-cart-index" style={{ marginTop: "1rem" }}>
            <div style={{ zIndex: "9999", width: "100%", padding: "0 16px" }}>
              <Link to="/cart" onClick={closeDrawerCart}>
                <button
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    borderRadius: "5px",
                    gap: "16px",
                  }}
                  className="ctm-btn d-flex align-items-center justify-content-between"
                >
                  <span>{t("check_out")}</span>
                  <span>
                    {backendPriceDetails.finalPrice}
                  </span>
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
}
