/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { Container } from "@mui/material";
import speakersAPI from "../../api/ems/speakers";
import { Speaker } from "../../components/SpeakersSection/Speaker";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import NoData from "../../iComponents/NoData/NoData";
import CircularProgress from "@mui/material/CircularProgress";


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));


export default function Speakers() {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0)
  const { loadHomeSpeakers } = speakersAPI();
  const [speakers, setSpeakers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const loadSpeakers = () => {
    setLoading(true)
    loadHomeSpeakers({ pageIndex: page,limit:10 })
      .then(data => {

        if (data.items && data.items.length > 0) {
          const existingSpeaker = page === 1 ? [] : [...speakers]
          setSpeakers([...existingSpeaker, ...data.items])
          page === 1 && setCount((data as any).totalItemsCount)
        }


      })
      .catch((err) => {
        console.error(err)
      }).finally(() => {
        setLoading(false)
      }
    );
  }

  useEffect(() => {
    loadSpeakers();
  }, [page])


  return (
    <div dir={direction}>
      <section className="speakers mr-section">
        <Container maxWidth="xl" className="main-container">
          <div className="title-center">
            <h3>{t('speakers')}</h3>
            <h2>{t('meetTheSpeakers')}</h2>

          </div>
          <div className="row d-flex justify-content-center">
            {/*<div*/}
            {/*    className="col-lg-3  col-md-6"*/}
            {/*    data-aos="fade-up"*/}
            {/*    data-aos-easing="linear"*/}
            {/*    data-aos-duration={500}*/}
            {/*>*/}
            {/*    <div className="sub-speakers-index">*/}
            {/*        <div className="img-speakers-index">*/}

            {/*            <StyledBadge*/}
            {/*                overlap="circular"*/}
            {/*                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}*/}
            {/*                variant="dot"*/}
            {/*            >*/}
            {/*                <img loading="lazy" src={sp1} alt=""/>*/}
            {/*            </StyledBadge>*/}

            {/*        </div>*/}
            {/*        <div className="text-speakers-index">*/}
            {/*            <h2>Dr. Faisal Almutairi</h2>*/}
            {/*            <p>Pediatric Dentistry Specialist</p>*/}
            {/*        </div>*/}
            {/*        <div className="media-speakers-index">*/}
            {/*            <ul>*/}
            {/*                <li>*/}
            {/*                    <a href="">*/}

            {/*                        <FaTwitter/>*/}
            {/*                    </a>*/}
            {/*                </li>*/}
            {/*                <li>*/}
            {/*                    <a href="">*/}

            {/*                        <FaInstagram/>*/}
            {/*                    </a>*/}
            {/*                </li>*/}
            {/*                <li>*/}
            {/*                    <a href="">*/}

            {/*                        <FaSnapchatGhost/>*/}
            {/*                    </a>*/}
            {/*                </li>*/}
            {/*                <li>*/}
            {/*                    <a href="">*/}

            {/*                        <FaFacebookF/>*/}
            {/*                    </a>*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*        <div className="links-speakers-index">*/}
            {/*            <ul>*/}
            {/*                <li>*/}
            {/*                    <a href="">*/}

            {/*                        <img loading="lazy" src={sp01} alt=""/>*/}
            {/*                    </a>*/}
            {/*                </li>*/}
            {/*                <li>*/}
            {/*                    <a href="">*/}

            {/*                        <img loading="lazy" src={sp02} alt=""/>*/}
            {/*                    </a>*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}


            {speakers.length > 0 ? speakers.map((speaker) =>
              <Speaker speaker={speaker}/>
            ) : <NoData msg="There is no speakers yet"/>}
            <div className="col-lg-12">
              {loading && <div className="d-flex col-lg-12 w-100 align-items-md-center justify-content-center">
                  <CircularProgress size={64}/>
              </div>}
              {speakers.length < count &&
              <div className="btn-speakers">
                  <a onClick={() => setPage(prevState => prevState + 1)} className="ctm-btn2 cursor-pointer">
                    {t('viewMore')}
                  </a>
              </div>
              }
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
