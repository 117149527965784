import { useContext } from 'react';
import axios, { AxiosInstance } from 'axios';
import { LocaleContext, LocaleContextProps } from "../contexts/LocaleContext";
import { ServerContext, ServerContextProps, ServerStatus } from "../contexts/ServerContext";

export const BASE_URL = 'https://emsapi.shamilapp.com'

const useAxiosWithInterceptor = (): { axiosInstance: AxiosInstance } => {
  const { isRtl } = useContext(LocaleContext) as LocaleContextProps;
  const {setStatus} = useContext(ServerContext) as ServerContextProps

  const token = localStorage.getItem("najSecretKey") || '';
  const refreshToken = localStorage.getItem("najRefreshToken") || '';

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 20000 ,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Accept-Language': isRtl ?"ar-SA":"en-US",
      'X-Version': 1,
    },
  });

  const refresh = () =>
    axios.post(`${BASE_URL}/Connect/Refresh`, { refreshToken }).then(response => response.data)

  axiosInstance.interceptors.request.use(config => {
    if (config.url?.toLowerCase().includes('/home') || config.url?.toLowerCase().includes('/homeevents')) {
      delete config.headers['Authorization'];
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });

  axiosInstance.interceptors.response.use(response =>{
    setStatus(ServerStatus.UP);
    return response;
  } , async error => {
    const originalRequest = error.config;
    if(!error.response){
      setStatus(ServerStatus.DOWN);
    }
    else {
      setStatus(ServerStatus.UP);
      if (error.response.status === 401 && !originalRequest.url?.toLowerCase().includes('/home/') && !originalRequest.url?.toLowerCase().includes('/homeevents/')) {
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const { accessToken, refreshToken: newRefreshToken } = await refresh();
            localStorage.setItem("najSecretKey", accessToken);
            localStorage.setItem("najRefreshToken", newRefreshToken)
            axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;
            delete originalRequest.headers
            return axiosInstance.request(originalRequest);
          } catch (refreshError) {
            localStorage.removeItem("najSecretKey")
            localStorage.removeItem("najRefreshToken")
            window.location.href = "/login";
            return Promise.reject(refreshError);
          }
        }
        localStorage.removeItem("najSecretKey")
        localStorage.removeItem("najRefreshToken")
        window.location.href = "/login";

      }
    }
    return Promise.reject(error);

  });

  return { axiosInstance };
};

export default useAxiosWithInterceptor;
