import React, {createContext, useState} from "react";

interface Infos{
    token: string;
    email: string | null;
    phone: string | null;
}
export interface ResetPasswordContextProps {
    infos: Infos,
    setInfos : (infos : Infos) => void
}

const ResetPasswordContext = createContext<ResetPasswordContextProps | undefined>(undefined);

const ResetPasswordProvider = ({ children } : any) => {

    const [infos, setInfos] = useState<Infos>(
        {
            token: '',
            email: '',
            phone: null
        }
    );

    return (
        <ResetPasswordContext.Provider
            value={{
                infos,
                setInfos
            }}
        >
            {children}
        </ResetPasswordContext.Provider>
    );
}

export { ResetPasswordProvider, ResetPasswordContext };