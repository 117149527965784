import React, { useRef, useState } from 'react';
import Card from '@mui/material/Card';
import './VideoCard.css'; // Import the CSS file for styling

function VideoCard() {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [volume, setVolume] = useState<number>(1);

  const handleVolumeChange = (value: string) => {
    const newVolume = parseFloat(value);
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  return (
    <Card className="video-card">
      <iframe
        title="YouTube Video"
        width="560"
        height="600"
        src="https://www.youtube.com/embed/Ec-kmBtWDUM"
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        className="video"
      />
   
    </Card>
  );
}

export default VideoCard;
