import React, { useEffect, useState } from 'react';
import CircularProgress from "@mui/material/CircularProgress";

const Loader = ({ children }: { children: React.ReactNode }) => {
  const [isPageRendered, setIsPageRendered] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      console.log("redered")
      setIsPageRendered(true);
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <>
      {isPageRendered ? children :
        <div className="d-flex h-100 w-100 align-items-md-center justify-content-center">
          <CircularProgress size={64}/>
        </div>}
    </>
  );
};

export default Loader;
