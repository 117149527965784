import {motion} from "framer-motion";
import React from "react";

export function MotionInput({form, name, type = "text", placeholder, handleChange, validationErrors, className} : any){

    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name} style={{padding: "0"}}>{placeholder} :
                {/*{validationErrors[name] && (*/}
                {/*    <p style={{ color: "red", fontSize:"small", margin:"0"}}>{validationErrors[name]}</p>*/}
                {/*)}*/}
            </label>
            <motion.input
                placeholder={placeholder}
                className="LoginSMinput"
                value={form[name]}
                name={name}
                onChange={handleChange}
                type={type}
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{delay: 0.4, duration: 0.3}}
                style={validationErrors && validationErrors[name] ? {borderColor:'red'}:{}}
            />
        </div>
    )
}