/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useRef, useState } from "react";
import "./Header.css";
import SearchSvg from "./assets/search.svg";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import { Container, Drawer, Fade, List, ListItem, Modal } from "@mui/material";
import mainLogo from "./assets/Logo.svg";
import BasicSelect from "../BasicSelect/BasicSelect";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AppsIcon from "@mui/icons-material/Apps";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import Login from "src/pages/Login/Login";
import LoginSM from "src/pages/LoginSM/LoginSM";
import CloseIcon from "@mui/icons-material/Close";
import { ScrollContext, ScrollContextProps, } from "../../contexts/ScrollContext";
import useOutsideClick from "../../hooks/useOutsideClick";

import ProfileBox from "./ProfileBox";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import { DrawerMenu } from "./DrawerMenu";
import { CartDrawer } from "../Cart/CartDrawer";
import { NotificationDrawer } from "./NotificationDrawer";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { CartContext } from "../../contexts/CartContext";
import useProductsAPI from "../../api/ems/products";
import { IProductByCategory } from "../../api/ems/schemas";
import PendingIcon from "@mui/icons-material/Pending";
import { useNavigate } from "react-router";

export default function Header() {
  const { t, lang, direction, isRtl } = useContext(
    LocaleContext
  ) as LocaleContextProps;
  const { isAuthenticated } = useContext(UserContext) as UserContextProps;
  const { scrollToRef } = useContext(ScrollContext) as ScrollContextProps;
  const { cartItems } = useContext(CartContext);
  const navigate = useNavigate();

  const { searchProduct } = useProductsAPI();
  const [filteredProducts, setFilteredProducts] = useState<IProductByCategory[]>([]);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isCartOpen, setCartOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [searching, setSearching] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const searchHeaderRef = useRef(null);
  // hide search

  useOutsideClick(searchHeaderRef, () => {
    setOpen(false);
  });

  const isArabic = lang === "ar";
  const direction2 = isArabic ? "ltr" : "rtl";

  const toggleNotificationDrawer = () => {
    setShowNotifications(!showNotifications);
  };

  const handleCartToggle = () => {
    setCartOpen(!isCartOpen);
  };

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMobileMenuToggle = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const closeDrawer = () => {
    setShowMobileMenu(false);
  };

  const closeDrawerCart = () => {
    setCartOpen(false);
  };

  const [searchInput, setSearchInput] = useState("");

  const anchor = lang === "en" ? "left" : "right";
  const anchor2 = lang === "en" ? "right" : "left";

  const handleSearchInputChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    setSearchInput(value);
    if (value && value.length > 2) {
      setSearching(true);
      searchProduct(value)
        .then((products) => {
          setFilteredProducts([...products]);
          setSearching(false);
        })
        .catch((err) => setSearching(false));
      setOpen(true);
    } else {
      setFilteredProducts([]);
    }

    // const filtered = coursesData.filter((course: { title: string }) =>
    //     course.title.toLowerCase().includes(value.toLowerCase())
    // );
    // setFilteredCourses(filtered as any);
    setOpen(true);
    setIsBlurred(true);
  };

  const handleSearchClick = (e: any) => {
    e.preventDefault();
    if (filteredProducts.length > 0) {
      navigate(`/ProductDetails/${filteredProducts[0].id}`)
    }
  }

  const [isBlurred, setIsBlurred] = useState(false);

  return (
    <div
      id="mainHeader"
      style={{ position: "relative", maxWidth: "unset" }}
      dir={direction}
    >
      <div style={{ width: "100%" }}>
        <Drawer
          anchor={anchor}
          open={showMobileMenu}
          onClose={() => setShowMobileMenu(false)}
        >
          <img
            loading="lazy"
            onClick={() => closeDrawer()}
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              zIndex: "9999",
            }}
            width="34"
            height="34"
            src="https://img.icons8.com/sf-black-filled/64/close-window.png"
            alt="close-window"
          />

          <List
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              minWidth: "400px",
            }}
          >
            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{
                textAlign: "center",
                padding: "1rem 0",
                color: "#000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "3rem",
              }}
              onClick={closeDrawer}
            >
              <img loading="lazy" src={mainLogo} alt="logo"/>
            </ListItem>

            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{ textAlign: "center", padding: "1rem 0", color: "#000" }}
              onClick={closeDrawer}
            >
              <Link style={{ width: "100%" }} to="/">
                {t("Home")}
              </Link>
            </ListItem>

            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{ textAlign: "center", padding: "1rem 0", color: "#000" }}
              onClick={closeDrawer}
            >
              <Link style={{ width: "100%" }} to="/page/About">
                {t("aboutUs")}
              </Link>
            </ListItem>

            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{ textAlign: "center", padding: "1rem 0", color: "#000" }}
              onClick={closeDrawer}
            >
              <Link style={{ width: "100%" }} to="/products">
                {t("Products")}
              </Link>
            </ListItem>

            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{ textAlign: "center", padding: "1rem 0", color: "#000" }}
              onClick={closeDrawer}
            >
              <Link style={{ width: "100%" }} to="/speakers">
                {t("speakers")}
              </Link>
            </ListItem>
            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{ textAlign: "center", padding: "1rem 0", color: "#000" }}
              onClick={closeDrawer}
            >
              <Link style={{ width: "100%" }} to="/news">
                {t("News")}
              </Link>
            </ListItem>

            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{ textAlign: "center", padding: "1rem 0", color: "#000" }}
              onClick={closeDrawer}
            >
              <Link style={{ width: "100%" }} to="/cases">
                {t("casesArticles")}
              </Link>
            </ListItem>

            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{ textAlign: "center", padding: "1rem 0", color: "#000" }}
              onClick={closeDrawer}
            >
              <Link style={{ width: "100%" }} to="/partners">
                {t("partners")}
              </Link>
            </ListItem>


            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{ textAlign: "center", padding: "1rem 0", color: "#000" }}
              onClick={closeDrawer}
            >
              <Link style={{ width: "100%" }} to="/memberships">
                {t("Memberships")}
              </Link>
            </ListItem>

            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{ textAlign: "center", padding: "1rem 0", color: "#000" }}
              onClick={closeDrawer}
            >
              <Link style={{ width: "100%" }} to="/page/Contact">
                {t("Contactus")}
              </Link>
            </ListItem>

            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{ textAlign: "center", padding: "1rem 0", color: "#000" }}
              onClick={closeDrawer}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <BasicSelect/>
              </div>
            </ListItem>

            {!isAuthenticated && <ListItem
                className="animate__animated  animate__fadeIn"
                button
                style={{
                  textAlign: "center",
                  padding: "1rem 0",
                  color: "#000",
                }}
                onClick={closeDrawer}
            >
                <Link id="loginBtnSideBar" style={{ width: "100%" }} to="/login">
                    <div className="login-res">
                        <Link to="/login">{t("loginSignup")}</Link>
                    </div>
                </Link>
            </ListItem>}

            <ListItem
              className="animate__animated  animate__fadeIn"
              button
              style={{ textAlign: "center", padding: "1rem 0", color: "#000" }}
              onClick={closeDrawer}
            >
              <Link style={{ width: "100%" }} to="https://najtraining.com/become-instructor">
                <div className="res-element">
                  <a href="https://najtraining.com/become-instructor">{t("BecomeanInstructor")}</a>
                </div>
              </Link>
            </ListItem>
          </List>
        </Drawer>

        <NotificationDrawer
          anchor={anchor2}
          toggleDrawer={toggleNotificationDrawer}
          showNotifications={showNotifications}
          setShowNotifications={setShowNotifications}
        />

        {isCartOpen && (
          <CartDrawer
            closeDrawerCart={closeDrawerCart}
            anchor2={anchor2}
            isCartOpen={isCartOpen}
            setCartOpen={setCartOpen}
          />
        )}

        <div id="mainContainerHeader" style={{ width: "100%" }}>
          <Container
            style={{ maxWidth: "unset" }}
            maxWidth="xl"
            className={`header ${isMenuOpen ? "active" : ""}`}
          >
            <div className="top-par">
              <div className="">
                <div className="row align-items-center">
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-4">
                    <div className="sub-top-par">
                      <div className="logo">
                        <Link to="/">
                          <Logo/>
                        </Link>
                      </div>
                      <div className="categories-header">
                        <div className="menu-div">
                          <div className="content" id="times-ican">
                            <a
                              onClick={handleMenuToggle}
                              // href={"/"}
                              title="Navigation menu"
                              className="navicon"
                              aria-label="Navigation"
                            >
                              <MenuIcon fontSize={"large"}/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-10 col-lg-10 col-md-10 col-sm-9 col-8">
                    <div className="element-header">
                      <div
                        className="search-header"
                        dir={direction2}
                        ref={searchHeaderRef}
                      >
                        <form>
                          <input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder={t("Search for courses...")}
                            value={searchInput}
                            onChange={handleSearchInputChange}
                            dir={direction}
                            onFocus={() => setOpen(true)}
                          />

                          <button onClick={handleSearchClick} disabled={searching}>
                            {searching ? (
                              <PendingIcon/>
                            ) : (
                              <img
                                loading="lazy"
                                src={SearchSvg}
                                alt="search"
                              />
                            )}
                          </button>
                        </form>

                        {filteredProducts.length >= 1 && (
                          <div id="filteredCourse">
                            <Fade in={open}>
                              <div
                                className="search-result-content"
                                dir={direction}
                              >
                                <div className="result-list">
                                  {filteredProducts.map((product: any) => {
                                    return (
                                      <div
                                        className="result-item"
                                        key={product.id}
                                      >
                                        <strong className="result-item-title">
                                          <Link
                                            to={`/ProductDetails/${product.id}`}
                                          >
                                            {product.name}
                                          </Link>
                                        </strong>

                                        <div className="result-item-price">
                                          {product?.price?.discount > 0 && (
                                            <span className="prev-price">
                                              {
                                                product.price
                                                  ?.displayTotalPriceWithoutDiscount
                                              }
                                            </span>
                                          )}

                                          <span className="current-price">
                                            {product.price.displayTotalPrice}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </Fade>
                          </div>
                        )}
                      </div>
                      <div className="icons-top-par">
                        <ul>
                          {/*<CategoriesNav categories={categories} />*/}
                          <li>
                            <div
                              className="categories-nav"
                              dir={direction}
                              id="CategoriesDiv"
                              onClick={scrollToRef}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.5rem",
                                color: "#000",
                              }}
                            >
                              <span>
                                <AppsIcon fontSize={"large"}/>
                              </span>
                              <span>{t("Courses")}</span>
                            </div>
                          </li>

                          <Link to={"/Schedule"}>
                            <div
                              id="EventsDiv"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.5rem",
                                color: "#000",
                              }}
                            >
                              <span>
                                <CalendarMonthIcon fontSize={"large"}/>
                              </span>
                              <span>{t("Schedule")}</span>
                            </div>
                          </Link>
                          <li
                            style={{ cursor: "pointer" }}
                            className="language1"
                          >
                            <BasicSelect/>
                          </li>

                          <li style={{ cursor: "pointer" }}>
                            <a
                              onClick={handleCartToggle}
                              className="cart-header"
                            >
                              <ShoppingCartIcon fontSize={"large"}/>

                              <span className="cont-header">
                                {cartItems.length}
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="search-res-header"
                              data-toggle="modal"
                              data-target=".search-header-modal"
                            >
                              <SearchIcon fontSize={"large"}/>
                              <img loading="lazy" src={SearchSvg} alt=""/>
                            </a>
                          </li>

                          <li>
                            <div className="mobile-navicon">
                              <a
                                onClick={handleMobileMenuToggle}
                                title="Navigation menu"
                                className="navicon"
                                aria-label="Navigation"
                              >
                                <MenuIcon fontSize={"large"}/>
                              </a>
                            </div>
                          </li>
                          {/* {isAuthenticated && (
                            <li style={{ cursor: "pointer" }}>
                              <a
                                onClick={toggleNotificationDrawer}
                                className="cart-header"
                              >
                                <NotificationsIcon fontSize={"large"} />
                                <span className="cont-header">1</span>
                              </a>
                            </li>
                          )} */}
                        </ul>
                      </div>

                      <div>
                        {isAuthenticated ? (
                          <ProfileBox/>
                        ) : (
                          <div className="links-login">
                            <a
                              dir={direction}
                              onClick={() => setModalOpen(true)}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                color: "#000",
                              }}
                            >
                              <span>{t("Login")}</span>
                            </a>
                          </div>
                        )}

                        <Modal
                          open={isModalOpen}
                          onClose={() => setModalOpen(false)}
                          aria-labelledby="login-modal"
                          id="loginModal"
                        >
                          <div onClick={(e) => e.stopPropagation()}>
                            <CloseIcon
                              onClick={() => setModalOpen(false)}
                              id="CloseIcon"
                              style={{
                                color: "#fff",
                                cursor: "pointer",
                                position: "absolute",
                                top: "1rem",
                                right: "1rem",
                                height: "40",
                                width: "40",
                              }}
                            />

                            <LoginSM/>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Drawer anchor={anchor} open={isMenuOpen} onClose={handleMenuClose}>
              <DrawerMenu handleMenuClose={handleMenuClose}/>
            </Drawer>
          </Container>
        </div>
      </div>
    </div>
  );
}
