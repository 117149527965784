import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { FaCheckCircle, FaMapMarkerAlt } from "react-icons/fa";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useUserCartAPI from "src/api/ems/user-cart";
import { UserContext, UserContextProps } from "src/contexts/UserContext";
import { LocaleContext, LocaleContextProps } from "src/contexts/LocaleContext";
import FallbackImage from "../../../assets/images/imagefallback.png";
import useProductsAPI from "../../../api/ems/products";
import moment from "moment";
import { createTheme } from "@mui/material";
import DateTimePickerCustom, { ScheduleItem } from '../../../components/DateTimePicker/DateTimePicker'
import { joinNames } from "../../../utils/nameUtils";
import useSpeakersAPI from "../../../api/ems/speakers";

const BookingItem = ({
                       bookItem,
                       setCartItems,
                       setBackendPriceDetails,
                       cartItems
                     }: any) => {
  const {
    uuid,
    id,
    name,
    count,
    categoryId,
    locations,
    speakers,
    addons,
    selectedAddonsIds,
    selectedCheckedInDate,
    coupon
  } = bookItem;
  const { t, direction, isRtl } = useContext(LocaleContext) as LocaleContextProps;
  const rtlTheme = createTheme({
    direction: direction as any,
  });
  const [shouldRenderItem, setShouldRenderItem] = useState(categoryId === 4 || addons.length >= 1);
  const { updateToUserCart, loadReadyUserCart, loadReadyAnonymousCart, reviewReadyCartCoupons } = useUserCartAPI();
  const { loadSpeakerSchedule } = useSpeakersAPI();
  const { isAuthenticated } = useContext(UserContext) as UserContextProps;
  const { loadProductDetails } = useProductsAPI()
  const [category, setCategory] = useState(categoryId)
  const [speakerSchedule, setSpeakerSchedule] = useState<ScheduleItem[]>([])

  const [showContent, setShowContent] = useState(true);
  const [initialState, setInitialState] = useState({
    selectedAddonsIds: selectedAddonsIds || [],
    selectedCheckedInDate: selectedCheckedInDate
      ? selectedCheckedInDate
      : null,
    selectedInstructorId:
      speakers && speakers.length >= 1 ? speakers[0].id : null,
    selectedLocationId:
      locations && locations?.length >= 1
        ? locations[0].id
        : null,
    couponCode: coupon ? coupon.code : ""
  });

  useEffect(() => {
    setCartItems((prevState: any) => {
      const existItemIndex = prevState.findIndex(
        (state: any) => state.id === id
      );
      if (existItemIndex !== -1) {
        const updatedCartItems = [...prevState];
        updatedCartItems[existItemIndex] = {
          ...prevState[existItemIndex],
          selectedAddonsIds: initialState.selectedAddonsIds,
          selectedCheckedInDate: initialState.selectedCheckedInDate,
          selectedInstructorId: initialState.selectedInstructorId,
          selectedLocationId: initialState.selectedLocationId,
          isDateRequired:
            categoryId === 4 && !initialState.selectedCheckedInDate,
        };
        return updatedCartItems;
      }
    });
    if (initialState.selectedInstructorId) {
      loadSpeakerSchedule(initialState.selectedInstructorId).then(value => {
        setSpeakerSchedule(value)
      })
    }

    if (!category) {
      loadProductDetails(id).then(value => {
        setCategory(value.productCategory.id)
        setShouldRenderItem(value.productCategory.id === 4 || addons.length >= 1)
      })
    }


  }, [initialState, isRtl]);


  const handleUpdate = (key: any, value: any) => {
    if (isAuthenticated) {
      updateToUserCart({
        uuid,
        productId: id,
        qty: count,
        checkedInDate: initialState.selectedCheckedInDate,
        locationId: initialState.selectedLocationId,
        instructorId: initialState.selectedInstructorId,
        addons: initialState.selectedAddonsIds,
        couponCode: initialState.couponCode,
        [key === 'selectedCheckedInDate' ? 'checkedInDate' : key]: value,
      })
        .then((res) => {
          loadReadyUserCart()
            .then((response: any) => {
              setBackendPriceDetails({
                subTotal: response.subTotal,
                totalProductsDiscountsAmount:
                response.totalProductsDiscountsAmount,
                coupons: response.coupons,
                totalCouponsDiscountsAmount:
                response.totalCouponsDiscountsAmount,
                totalVatAmount: response.totalVatAmount,
                totalOtherTaxesAmount: response.totalOtherTaxesAmount,
                finalPrice: response.finalPrice,
              });

            })
            .catch((error: any) =>
              toast.error(
                error?.response?.data?.detail || t("something went wrong")
              )
            );
        })
        .catch((error) => {
          if (key === 'selectedCheckedInDate')
            setInitialState((prevState) => ({
              ...prevState,
              selectedCheckedInDate: null,
            }))
          toast.error(
            error?.response?.data?.detail ||
            "We couldn't update the cart, please try again later."
          );
        });
    } else if (key === 'addons') {
      loadReadyAnonymousCart([...cartItems.map((item: any) => ({
        productId: item.id,
        qty: item.count
      })), ...value.map((val: any) => ({ productId: val.addonId, qty: val.qty }))]).then((response: any) => {
        setBackendPriceDetails({
          subTotal: response.subTotal,
          totalProductsDiscountsAmount:
          response.totalProductsDiscountsAmount,
          coupons: response.coupons,
          totalCouponsDiscountsAmount:
          response.totalCouponsDiscountsAmount,
          totalVatAmount: response.totalVatAmount,
          totalOtherTaxesAmount: response.totalOtherTaxesAmount,
          finalPrice: response.finalPrice,
        });
      })
        .catch((error: any) =>
          toast.error(
            error?.response?.data?.detail || t("something went wrong")
          )
        );
    }
  };

  if (!shouldRenderItem) return null;


  return (
    <div className="custom-accordion mb-4">
      <div
        className="custom-accordion-head"
        onClick={() => setShowContent(!showContent)}
      >
        <div className="custom-accordion-head-title">
          <strong>{name}</strong>
          {category === 4 && (
            <strong style={{ fontSize: 12, color: "red" }}>
              (Session date is requied)
            </strong>
          )}
        </div>

        <div className="custom-accordion-head-icon">
          {showContent ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </div>
      </div>
      {showContent && (
        <div className="custom-accordion-body">
          {category === 4 && (
            <div className="w-100 mb-2">
              <strong>{t("Session Date")}</strong>
              <div className="custom-datetimepicker">

                <DateTimePickerCustom
                  speakerExist={!!initialState.selectedInstructorId}
                  schedule={speakerSchedule}
                  date={initialState.selectedCheckedInDate ? moment(initialState.selectedCheckedInDate) : undefined}
                  setDate={date => {
                    setInitialState((prevState) => ({
                      ...prevState,
                      selectedCheckedInDate: date ? date.utc(true) : null,
                    }));
                    if (date)
                      handleUpdate("selectedCheckedInDate", date ? date.utc(true): null);
                  }}/>
              </div>
            </div>
          )}

          {locations.length >= 1 && (
            <div className="custom-options-contain mb-4">
              <strong>{t("Location :")}</strong>
              <div className="custom-options-wrap">
                {locations.map((locationItem: any) => (
                  <div
                    className={`custom-option-item ${
                      locationItem.id === initialState.selectedLocationId &&
                      "active"
                    }`}
                    key={locationItem.id}
                    onClick={() => {
                      setInitialState((prevState) => ({
                        ...prevState,
                        selectedLocationId: locationItem.id,
                      }));

                      handleUpdate("locationId", locationItem.id);
                    }}
                  >
                    <div className="custom-option-item-active-icon">
                      <FaCheckCircle/>
                    </div>
                    <div className="custom-option-item-content">
                      <FaMapMarkerAlt/>
                      <span>{locationItem.name}</span>
                    </div>
                    <div className="custom-option-item-img">
                      <img
                        loading="lazy"
                        src={locationItem?.media?.fullUrl || FallbackImage}
                        style={{
                          backgroundColor: locationItem?.media?.fullUrl
                            ? "unset"
                            : "#f5f5f5",
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {speakers.length >= 1 && (
            <div className="custom-options-contain mb-4">
              <strong>{t("instructorsText")} :</strong>
              <div className="custom-options-wrap">
                {speakers.map((speakerItem: any) => (
                  <div
                    className={`custom-option-item ${
                      speakerItem.id === initialState.selectedInstructorId &&
                      "active"
                    }`}
                    key={speakerItem.id}
                    onClick={() => {
                      setInitialState((prevState) => ({
                        ...prevState,
                        selectedInstructorId: speakerItem.id,
                        selectedCheckedInDate: speakerItem.id !== prevState.selectedInstructorId ? null : prevState.selectedCheckedInDate
                      }));
                    }}
                  >
                    <div className="custom-option-item-active-icon">
                      <FaCheckCircle/>
                    </div>
                    <div
                      className="custom-option-item-content"
                      style={{ flexDirection: "column" }}
                    >
                      <span>
                        {joinNames(speakerItem.title, speakerItem.firstName, speakerItem.secondName)}
                      </span>
                      <span>{speakerItem.bio}</span>
                    </div>
                    <div className="custom-option-item-img">
                      <img
                        loading="lazy"
                        src={
                          speakerItem?.profilePicture?.fullUrl || FallbackImage
                        }
                        style={{
                          backgroundColor: speakerItem?.profilePicture?.fullUrl
                            ? "unset"
                            : "#f5f5f5",
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {addons.length >= 1 && (
            <div className="custom-options-contain">
              <strong>{t("equipmentsText")} :</strong>
              <div className="custom-options-wrap">
                {addons.map((addonItem: any) => (
                  <div>
                    <div
                      className="custom-option-item active"
                      key={addonItem.id}
                    >
                      <div
                        className="custom-option-item-content"
                        style={{ flexDirection: "column" }}
                      >
                        <span>{addonItem.name}</span>
                        <div className="result-item-price">
                          {addonItem?.price?.discount > 0 && (
                            <span className="prev-price">
                              {
                                addonItem.price
                                  ?.displayTotalPriceWithoutDiscount
                              }
                            </span>
                          )}

                          <span className="current-price">
                            {addonItem.price.displayTotalPrice}
                          </span>
                        </div>
                      </div>
                      <div className="custom-option-item-img">

                        <img
                          loading="lazy"
                          src={addonItem?.media[0]?.fullUrl || FallbackImage}
                          style={{
                            backgroundColor: addonItem?.media[0]?.fullUrl
                              ? "unset"
                              : "#f5f5f5",
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-4">
                      <div className="counter-wrap">
                        <div
                          className="counter-action"
                          onClick={() => {
                            const existAddon =
                              initialState.selectedAddonsIds.find(
                                (state: any) => state.addonId === addonItem.id
                              );

                            if (existAddon) {
                              setInitialState((prevState) => {
                                const updatedSelectedAddonsIds =
                                  prevState.selectedAddonsIds
                                    .map((state: any) => {
                                      if (
                                        state.addonId === addonItem.id &&
                                        state.qty !== 0
                                      ) {
                                        return { ...state, qty: state.qty - 1 };
                                      }
                                      return state;
                                    })
                                    .filter((state: any) => state.qty > 0);
                                handleUpdate(
                                  "addons",
                                  updatedSelectedAddonsIds
                                );

                                return {
                                  ...prevState,
                                  selectedAddonsIds: updatedSelectedAddonsIds,
                                };
                              });
                            }
                          }}
                        >
                          <RemoveIcon/>
                        </div>
                        <div className="counter-number">
                          {initialState.selectedAddonsIds.length >= 1
                            ? initialState.selectedAddonsIds.find(
                              (state: any) => state.addonId === addonItem.id
                            )
                              ? initialState.selectedAddonsIds.find(
                                (state: any) => state.addonId === addonItem.id
                              )?.qty
                              : 0
                            : 0}
                        </div>
                        <div
                          className="counter-action"
                          onClick={() => {
                            const existAddon =
                              initialState.selectedAddonsIds.find(
                                (state: any) => state.addonId === addonItem.id
                              );

                            if (existAddon) {
                              setInitialState((prevState) => {
                                const updatedSelectedAddonsIds =
                                  prevState.selectedAddonsIds.map(
                                    (state: any) => {
                                      if (state.addonId === addonItem.id) {
                                        return { ...state, qty: state.qty + 1 };
                                      }
                                      return state;
                                    }
                                  );

                                handleUpdate(
                                  "addons",
                                  updatedSelectedAddonsIds
                                );

                                return {
                                  ...prevState,
                                  selectedAddonsIds: updatedSelectedAddonsIds,
                                };
                              });
                            } else {
                              setInitialState((prevState) => {
                                return {
                                  ...prevState,
                                  selectedAddonsIds:
                                    prevState.selectedAddonsIds.concat([
                                      { addonId: addonItem.id, qty: 1 },
                                    ]),
                                };
                              });
                              handleUpdate(
                                "addons",
                                initialState.selectedAddonsIds.concat([
                                  { addonId: addonItem.id, qty: 1 },
                                ])
                              );
                            }
                          }}
                        >
                          <AddIcon/>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BookingItem;
