import { I18nextProvider } from "react-i18next";
import i18n from "./i18n ";
import React, { useContext } from "react";
import { Route, Routes } from "react-router";
import routes, { accountRoutes, RoutesOutlet } from "./routes/routes";
import { Navigate } from "react-router-dom";
import NotFoundPage from "./pages/notFoundPage/notFoundPage";
import { GeneralLayout } from "./layouts/GeneralLayout";
import { AccountLayout } from "./layouts/AccountLayout";
import CourseDetailsPage from "./pages/CourseDetailsPage/CourseDetailsPage";
import { coursesData } from "./components/coursesData/coursesData";
import { UserContext, UserContextProps } from "./contexts/UserContext";
import { ResetPasswordProvider } from "./contexts/ResetPasswordContext";
import { FaWhatsapp } from "react-icons/fa";
import ServerDownModal from "./components/ServerDownModal/ServerDownModal";


function App() {
  const { isAuthenticated } = useContext(UserContext) as UserContextProps;

  const authGuard = (page: any) =>
    isAuthenticated ? page : <Navigate to="/login"/>;

  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
      <ServerDownModal/>
        <ResetPasswordProvider>
          <Routes>
            <Route path="" element={<RoutesOutlet Layout={GeneralLayout}/>}>
              {routes(isAuthenticated).map((route, index) => (
                <Route key={index} path={route.path} element={route.element}/>
              ))}
            </Route>

            <Route path="" element={<RoutesOutlet Layout={AccountLayout}/>}>
              {accountRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={authGuard(route.element)}
                />
              ))}
            </Route>

            <Route
              path="/courseDetails/:index"
              element={<CourseDetailsPage coursesData={coursesData}/>}
            />
            <Route path="*" element={<NotFoundPage/>}/>
          </Routes>
          <a  href="https://wa.me/966551855653" target="_blank" style={i18n.language !== "ar"?{right:'35px'}:{left:'35px'}} className={`whatsapp-link`}>
            <FaWhatsapp/>
          </a>
        </ResetPasswordProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;
