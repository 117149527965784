/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */

// Import images from './assets/'
import phoneImage from "./assets/phone.png";
import emailImage from "./assets/mail.png";
import marketImage from "./assets/market.png";
import { Container } from "@mui/material";
import toast from "react-hot-toast";
import React, { useContext } from "react";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";

export default function ContactUs() {
    const {t, direction} = useContext(LocaleContext) as LocaleContextProps;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const email = formData.get("email");

        if (!email) {
            toast.error(t("Please write your email"));
        } else {
            // Perform submission logic here
            toast.success(t("Message sent successfully!"));
            e.target.reset(); // Reset the form after successful submission
        }
    };

    return (
        <div>

            <section className="contactus mr-section" dir={direction}>
                <Container maxWidth="xl" className="main-container">
                  <div className="title-center">
                    <h3>{t('Contact Us')}</h3>
                  </div>
                    <div className="row">


                                <div className="col-4">
                                <a href="tel:00966551855653">
                                    <div className="sub-info-contactus">
                                        <div className="img-info-contactus">
                                            {/* Use the imported image */}
                                            <img loading="lazy" src={phoneImage} alt=""/>
                                        </div>
                                        <h2>{t("Phone no. :")}</h2>
                                        <p>+966551855653</p>
                                    </div>
                                </a>
                                </div>
                                <div className="col-4">
                                <a href="mailto:info@najtraining.com">
                                    <div className="sub-info-contactus">
                                        <div className="img-info-contactus">
                                            {/* Use the imported image */}
                                            <img loading="lazy" src={emailImage} alt=""/>
                                        </div>
                                        <h2>{t("E-mail :")}</h2>
                                        <p>info@najtraining.com</p>
                                    </div>
                                </a>
                                </div>
                                <div className="col-4">
                                <a target="_blank" href="https://www.google.com/maps/place/%D9%85%D8%B1%D9%83%D8%B2+%D9%86%D8%A7%D8%AC+%D9%84%D9%84%D8%AA%D8%AF%D8%B1%D9%8A%D8%A8+%D8%A7%D9%84%D8%B5%D8%AD%D9%8A%E2%80%AD/@24.662014,46.702974,19z/data=!4m6!3m5!1s0x3e2f05001220368f:0x7f03640f76d9953b!8m2!3d24.6619577!4d46.70298!16s%2Fg%2F11p012k7pp?hl=ar&entry=ttu">
                                    <div className="sub-info-contactus">
                                        <div className="img-info-contactus">
                                            {/* Use the imported image */}
                                            <img loading="lazy" src={marketImage} alt=""/>
                                        </div>
                                        <h2>{t("Location :")}</h2>
                                        <p>{t("LocationName")}</p>
                                    </div>
                                </a>
                                </div>
                        <div className="col-lg-12">


                            <div id="map" style={{marginTop: 50 }}>
                                <iframe
                                    title="Google Maps"
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14503.55496269163!2d46.70298!3d24.6619577!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f05001220368f%3A0x7f03640f76d9953b!2z2YXYsdmD2LIg2YbYp9isINmE2YTYqtiv2LHZitioINin2YTYtdit2Yo!5e0!3m2!1sen!2sca!4v1708877113469!5m2!1sen!2sca"
                                    width="100%"
                                    height="100%"
                                    style={{border: 0}}
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>


                        </div>
                    </div>
                </Container>
            </section>
        </div>
    );
}
