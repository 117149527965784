import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthServices from "../api/useAuthServices";
import toast from "react-hot-toast";
import { LocaleContext, LocaleContextProps } from "./LocaleContext";

export interface UserClaims {
  nameid: string;
  email: string;
  bio: string;
  given_name: string;
  firstName: string;
  secondName: string;
  thirdName: string;
  lastName: string;
  unique_name: string;
  mobilephone: string;
  role: string;
  image: string;
  scfhsid: string;
  username: string;
  membershipNumber: string;
}

export interface UserContextProps {
  isAuthenticated: boolean;
  currentUser: UserClaims | null;
  setCurrent: React.Dispatch<React.SetStateAction<UserClaims | null>>;
  loginUser: (
    username: string,
    password: string,
    returnUrl: string | undefined
  ) => Promise<void>;
  handleLogout: () => void;
  fetchProfile: () => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

const UserProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const { t } = useContext(LocaleContext) as LocaleContextProps;
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("najSecretKey") !== null ||
      localStorage.getItem("najRefreshToken") !== null
  );
  const [currentUser, setCurrent] = useState<UserClaims | null>(null);
  const { login, getProfile } = useAuthServices();
  
  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      fetchProfile();
    }
  }, []);

  const loginUser = async (
    username: string,
    password: string,
    returnUrl: string | undefined
  ) => {
    const data = await login(username, password);
    const { accessToken, refreshToken } = data;
    handleLogin(accessToken, refreshToken, returnUrl);
    fetchProfile();
  };

  const fetchProfile = () => {
    getProfile()
      .then(extractUserInfo)
      .catch(() => toast.error(t("Error getting profile info")));
  };

  const handleLogin = (
    accessToken: string,
    refreshToken: string,
    returnUrl: string | undefined
  ) => {
    localStorage.setItem("najSecretKey", accessToken);
    localStorage.setItem("najRefreshToken", refreshToken);
    setIsAuthenticated(true);
    navigate(returnUrl ? returnUrl : "/myAccount");
  };

  const handleLogout = () => {
    localStorage.removeItem("najSecretKey");
    localStorage.removeItem("najRefreshToken");
    setIsAuthenticated(false);
    navigate("/");
  };

  const extractUserInfo = (data: any) => {
    const {
      id,
      firstName,
      secondName,
      thirdName,
      lastName,
      email,
      bio,
      phoneNumber: mobilephone,
      image: image,
      role,
      scfhsid,
      username,
      membershipNumber,
    } = data;
    const fullName = joinNames(firstName, secondName, thirdName, lastName);

    setCurrent((prev) => ({
      ...prev!,
      nameid: id,
      given_name: fullName,
      email,
      bio,
      unique_name: fullName,
      firstName,
      secondName,
      thirdName,
      lastName,
      role: role?.name,
      mobilephone,
      scfhsid,
      username,
      membershipNumber,
      image: image?.fullUrl,
    }));
  };

  const joinNames = (...names: string[]) => {
    return names.filter((name) => name && name !== "").join(" ");
  };

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        setCurrent,
        loginUser,
        handleLogout,
        fetchProfile,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
