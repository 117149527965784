/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import comfirem from "./assets/comfirem.svg";
import { Container } from "@mui/material";
import { LocaleContext, LocaleContextProps } from "src/contexts/LocaleContext";
import { useLocation } from "react-router-dom";
import usePaymentAPI from "src/api/ems/payment";
import { CartContext } from "src/contexts/CartContext";
import { Cancel } from "@mui/icons-material";

export default function AlreadyLoginPage() {
  const { direction, t } = useContext(LocaleContext) as LocaleContextProps;
  return (
    <div>
      <section className="comfirm-payment mr-section" dir={direction}>
        <Container maxWidth="xl" className="main-container mt-2">
          <div className="main-comfirm-payment">
            <div className="img-comfirm-payment">
              <img loading="lazy" src={comfirem} alt="" />
            </div>
            <h2>{t("login_success")}</h2>
            
          </div>
        </Container>
      </section>
    </div>
  );
}
