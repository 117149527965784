/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Loading from '../../components/Loading/Loading';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import {useTranslation} from 'react-i18next';
import {BiShare} from 'react-icons/bi';

import prd2 from './assets/prd2.png';
import user2 from './assets/user2.png';
import sp1 from './assets/sp1.png';
import sp3 from './assets/sp3.png';
import {Container} from '@mui/material';
import {FaChevronDown, FaEdit, FaLongArrowAltRight, FaPlay, FaStar} from 'react-icons/fa';

export default function CourseVideo() {
    const {t, i18n} = useTranslation();

    const handleChangeLanguage = (language: string | undefined) => {
        i18n.changeLanguage(language);
    };

    const isArabic = i18n.language === 'ar';
    const direction = isArabic ? 'rtl' : 'ltr';

    return (
        <div>
            <section className="course-video mr-section">
                <Container maxWidth="xl" className="main-container">
                    <div className="title-course-video">
                        <h2>{t('course.title')}</h2>
                        <div className="progress-video">
                            <div className="sub-progress-video">
                                <div className="percent">
                                    <svg>
                                        <circle cx={20} cy={20} r={16}/>
                                        <circle cx={20} cy={20} r={16}/>
                                    </svg>
                                </div>
                                <h3>
                                    {t('course.progress')}
                                    <FaChevronDown/>
                                </h3>
                            </div>
                            <div className="dropdowm-progress">
                                <p>{t('course.completed', {count: 7, total: 11})}</p>
                            </div>
                        </div>
                        <div className="share-course-video">
                            <a href="" className="ctm-btn2">
                                <BiShare/> {t('course.share')}
                            </a>
                        </div>
                    </div>
                    <div className="main-course-video">
                        <video
                            src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                            id="videoId"
                            controls
                            poster="images/p2.png"
                        />
                        <button id="play-video" type="button">
                            <FaPlay/>
                        </button>
                    </div>
                    <div className="links-products-details">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    id="pills-1-tab"
                                    data-toggle="pill"
                                    href="#pills-1"
                                    role="tab"
                                    aria-controls="pills-1"
                                    aria-selected="true"
                                >
                                    {t('course.overview')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="pills-2-tab"
                                    data-toggle="pill"
                                    href="#pills-2"
                                    role="tab"
                                    aria-controls="pills-2"
                                    aria-selected="false"
                                >
                                    {t('course.curriculum')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="pills-3-tab"
                                    data-toggle="pill"
                                    href="#pills-3"
                                    role="tab"
                                    aria-controls="pills-3"
                                    aria-selected="false"
                                >
                                    {t('course.instructors')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="pills-4-tab"
                                    data-toggle="pill"
                                    href="#pills-4"
                                    role="tab"
                                    aria-controls="pills-4"
                                    aria-selected="false"
                                >
                                    {t('course.reviews')}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="tabs-details">
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-1"
                                role="tabpanel"
                                aria-labelledby="pills-1-tab"
                            >
                                <div className="overview">
                                    <h3>{t('course.objectivesTitle')}</h3>
                                    <ul>
                                        <li>{t('course.objective1')}</li>
                                        <li>{t('course.objective2')}</li>
                                        <li>{t('course.objective3')}</li>
                                    </ul>
                                    <h3>{t('course.handsonTitle')}</h3>
                                    <ul>
                                        <li>{t('course.handson1')}</li>
                                        <li>{t('course.handson2')}</li>
                                        <li>{t('course.handson3')}</li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-2"
                                role="tabpanel"
                                aria-labelledby="pills-2-tab"
                            >
                                <div className="curriculum">
                                    <ul>
                                        <li className="sub-curriculum">
                                            <h2>{t('course.welcome')}</h2>
                                            <div className="videos-curriculum">
                                                <ul>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session1')}
                                                            </h4>
                                                            <span> 7 minutes </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session2')}
                                                            </h4>
                                                            <span>15 minutes </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session3')}
                                                            </h4>
                                                            <span> 32 minutes </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session4')}
                                                            </h4>
                                                            <span> 12 minutes </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="sub-curriculum">
                                            <h2>{t('course.contentTitle')}</h2>
                                            <div className="videos-curriculum">
                                                <ul>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session1')}
                                                            </h4>
                                                            <span> 7 minutes </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session2')}
                                                            </h4>
                                                            <span>15 minutes </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session3')}
                                                            </h4>
                                                            <span> 32 minutes </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session4')}
                                                            </h4>
                                                            <span> 12 minutes </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="sub-curriculum">
                                            <h2>{t('course.contentTitle')}</h2>
                                            <div className="videos-curriculum">
                                                <ul>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session1')}
                                                            </h4>
                                                            <span> 7 minutes </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session2')}
                                                            </h4>
                                                            <span>15 minutes </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session3')}
                                                            </h4>
                                                            <span> 32 minutes </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="">
                                                            <h4>
                                                                <img loading="lazy" src={prd2}
                                                                     alt=""/> {t('course.session4')}
                                                            </h4>
                                                            <span> 12 minutes </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-3"
                                role="tabpanel"
                                aria-labelledby="pills-3-tab"
                            >
                                <div className="instructors">
                                    <ul>
                                        <li>
                                            <a href="">
                                                <div className="img-instructors">
                                                    <img loading="lazy" src={sp1} alt=""/>
                                                </div>
                                                <div className="text-instructors">
                                                    <h2>{t('course.instructor1')}</h2>
                                                    <p>{t('course.specialist1')}</p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <div className="img-instructors">
                                                    <img loading="lazy" src={sp3} alt=""/>
                                                </div>
                                                <div className="text-instructors">
                                                    <h2>{t('course.instructor2')}</h2>
                                                    <p>{t('course.specialist2')}</p>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="tab-pane reviews-tab fade"
                                id="pills-4"
                                role="tabpanel"
                                aria-labelledby="pills-4-tab"
                            >
                                <div className="reviews">
                                    <div className="rate-reviews">
                                        <div className="row align-items-center">
                                            <div className="col-lg-4">
                                                <div className="total-reviews">
                                                    <h2>4.8</h2>
                                                    <div className="rate">
                                                        <span className="fa fa-star checked"/>
                                                        <span className="fa fa-star checked"/>
                                                        <span className="fa fa-star checked"/>
                                                        <span className="fa fa-star"/>
                                                        <span className="fa fa-star"/>
                                                    </div>
                                                    <h4>(3 {t('course.reviewsCount')})</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="progress-rate">
                                                    <div className="sub-progress">
                                                        <h3>
                                                            5 <FaStar/>
                                                        </h3>
                                                        <div className="animated-progress progress-">
                                                            <span data-progress={85}/>
                                                        </div>
                                                    </div>
                                                    <div className="sub-progress">
                                                        <h3>
                                                            4 <FaStar/>
                                                        </h3>
                                                        <div className="animated-progress progress-">
                                                            <span data-progress={80}/>
                                                        </div>
                                                    </div>
                                                    <div className="sub-progress">
                                                        <h3>
                                                            3 <FaStar/>
                                                        </h3>
                                                        <div className="animated-progress progress-">
                                                            <span data-progress={65}/>
                                                        </div>
                                                    </div>
                                                    <div className="sub-progress">
                                                        <h3>
                                                            2 <FaStar/>
                                                        </h3>
                                                        <div className="animated-progress progress-">
                                                            <span data-progress={45}/>
                                                        </div>
                                                    </div>
                                                    <div className="sub-progress">
                                                        <h3>
                                                            1 <FaStar/>
                                                        </h3>
                                                        <div className="animated-progress progress-">
                                                            <span data-progress={15}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comment-reviews">
                                        <div className="link-comment-reviews">
                                            <h2>{t('course.reviewsTitle')}</h2>
                                            <a
                                                href=""
                                                data-toggle="modal"
                                                data-target=".form-add-comment"
                                            >
                                                <FaEdit/> {t('course.writeReview')}
                                            </a>
                                        </div>
                                        <div className="sub-comment-reviews">
                                            <div className="title-comment-reviews">
                                                <div className="sub-title-comment-reviews">
                                                    <div className="img-comment-reviews">
                                                        <img loading="lazy" src={user2} alt=""/>
                                                    </div>
                                                    <div className="text-comment-reviews">
                                                        <h3>username</h3>
                                                        <p>{t('course.today')}</p>
                                                    </div>
                                                </div>
                                                <div className="rate">
                                                    <span className="fa fa-star checked"/>
                                                    <span className="fa fa-star checked"/>
                                                    <span className="fa fa-star checked"/>
                                                    <span className="fa fa-star"/>
                                                    <span className="fa fa-star"/>
                                                </div>
                                            </div>
                                            <p>
                                                "
                                                {t('course.reviewComment', {
                                                    content:
                                                        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
                                                })}
                                                ".
                                            </p>
                                        </div>
                                        <div className="sub-comment-reviews">
                                            <div className="title-comment-reviews">
                                                <div className="sub-title-comment-reviews">
                                                    <div className="img-comment-reviews">
                                                        <img loading="lazy" src={user2} alt=""/>
                                                    </div>
                                                    <div className="text-comment-reviews">
                                                        <h3>username</h3>
                                                        <p>{t('course.today')}</p>
                                                    </div>
                                                </div>
                                                <div className="rate">
                                                    <span className="fa fa-star checked"/>
                                                    <span className="fa fa-star checked"/>
                                                    <span className="fa fa-star checked"/>
                                                    <span className="fa fa-star"/>
                                                    <span className="fa fa-star"/>
                                                </div>
                                            </div>
                                            <p>
                                                "
                                                {t('course.reviewComment', {
                                                    content:
                                                        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
                                                })}
                                                ".
                                            </p>
                                        </div>
                                        <div className="sub-comment-reviews">
                                            <div className="title-comment-reviews">
                                                <div className="sub-title-comment-reviews">
                                                    <div className="img-comment-reviews">
                                                        <img loading="lazy" src={user2} alt=""/>
                                                    </div>
                                                    <div className="text-comment-reviews">
                                                        <h3>username</h3>
                                                        <p>{t('course.today')}</p>
                                                    </div>
                                                </div>
                                                <div className="rate">
                                                    <span className="fa fa-star checked"/>
                                                    <span className="fa fa-star checked"/>
                                                    <span className="fa fa-star checked"/>
                                                    <span className="fa fa-star"/>
                                                    <span className="fa fa-star"/>
                                                </div>
                                            </div>
                                            <p>
                                                "
                                                {t('course.reviewComment', {
                                                    content:
                                                        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
                                                })}
                                                ".
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* form-add-comment */}
                    <div
                        className="modal fade form-add-comment"
                        tabIndex={1}
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <form>
                                    <div className="main-form-add-comment">
                                        <div className="title-center">
                                            <h3>{t('course.rateUs')}</h3>
                                            <h2>{t('course.rateProduct')}</h2>
                                        </div>
                                        <div className="rating">
                                            <input type="radio" id="star5" name="rating" defaultValue={5}/>
                                            <label className="star" htmlFor="star5" title={t('course.ratingAwesome')}
                                                   aria-hidden="true"/>
                                            <input type="radio" id="star4" name="rating" defaultValue={4}/>
                                            <label className="star" htmlFor="star4" title={t('course.ratingGreat')}
                                                   aria-hidden="true"/>
                                            <input type="radio" id="star3" name="rating" defaultValue={3}/>
                                            <label className="star" htmlFor="star3" title={t('course.ratingVeryGood')}
                                                   aria-hidden="true"/>
                                            <input type="radio" id="star2" name="rating" defaultValue={2}/>
                                            <label className="star" htmlFor="star2" title={t('course.ratingGood')}
                                                   aria-hidden="true"/>
                                            <input type="radio" id="star1" name="rating" defaultValue={1}/>
                                            <label className="star" htmlFor="star1" title={t('course.ratingBad')}
                                                   aria-hidden="true"/>
                                        </div>
                                        <div className="textarea-comment">
                                            <textarea className="form-control" placeholder={t('course.writeComment')}
                                                      defaultValue={""}/>
                                        </div>
                                        <div className="btn-add-comment">
                                            <button className="ctm-btn2">
                                                {t('course.submit')} <FaLongArrowAltRight/>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    );
}
