import React, { useContext, useEffect, useState } from "react";
import "./ProfileCourses.css";
import { motion } from "framer-motion";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import useProductsAPI from "../../api/ems/products";
import { CartContext } from "../../contexts/CartContext";
import { useNavigate } from "react-router";
import NoData from "src/iComponents/NoData/NoData";
import ImageFallBack from "../../assets/images/imagefallback.png"

const ProfileCourses: React.FC = () => {
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const { t, direction ,isRtl } = useContext(LocaleContext) as LocaleContextProps;
  const { loadMyCourses } = useProductsAPI();
  const { addToCart } = useContext(CartContext);

  const [showPaymentCourses, setShowPaymentCourses] = useState(true);
  const [courses, setCourses] = useState<any[]>([]);

  useEffect(() => {
    loadMyCourses()
      .then((courses) => {
        setCourses(courses?.items);
      })
      .catch((err) => {});
  }, [isRtl]);

  return (
    <div className="myCasesContainer">
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
        dir={direction}
      >
        <div className="title-myprofile">
          <h3>{t("Courses")}</h3>
        </div>
        <div className="profile-courses mt-2" dir={direction}>
          <div className="main-fliter-profile-courses d-none">
            <form action="">
              <div className="fliter-profile-courses">
                <div className="input-profile-courses arrow-select">
                  <label htmlFor="">{t("Category")}</label>
                  <select
                    className="form-select form-control"
                    name="category"
                    dir={direction}
                  >
                    <option value="All" style={{ padding: "5px" }}>
                      {t("All")}
                    </option>
                    <option value="Online Courses" style={{ padding: "5px" }}>
                      {t("onlineCourses")}
                    </option>
                    <option value="General Events" style={{ padding: "5px" }}>
                      {t("General Events")}
                    </option>
                    <option value="Conferences" style={{ padding: "5px" }}>
                      {t("Conferences")}
                    </option>
                  </select>
                </div>
                <div className="input-profile-courses">
                  <label htmlFor="">{t("Search by title")}</label>
                  <input
                    type="text"
                    placeholder={t("Enter a keyword")}
                    className="form-control"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                  />
                </div>
              </div>

              <div className="btn-completed-div">
                {/* Show Payment Courses Checkbox */}
                <div className="btn-completed">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={showPaymentCourses}
                      onChange={() =>
                        setShowPaymentCourses(!showPaymentCourses)
                      }
                    />
                    <span className="slider-switch round"/>
                  </label>
                  <span id="completed-switch">
                    {t("Display of Unpaid Courses")}
                  </span>
                </div>
              </div>
            </form>
          </div>

          <div>
            {courses && courses.length >= 1 ? (
              <div className="main-profile-courses">
                {courses.map((course, index) => (
                  <motion.div
                    className={`sub-profile-courses position-relative ${course.lessonsLink ? 'cursor-pointer':''} `}
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    onClick={()=>course.lessonsLink && window.location.replace(course.lessonsLink)}
                  >
                    <div className="category-profile-img">
                      <img src={course.media?.fullUrl ?? ImageFallBack } title={course.name} />
                    </div>
                    <div className="category-profile-content">
                      <div className="category-profile-courses">
                        {course?.productCategory && <h3>{course.productCategory.name}</h3>}
                      </div>
                      <h2>{course.name}</h2>
                      <p>{course.description}</p>

                    </div>
                    
                  </motion.div>
                ))}
              </div>
            ) : (
              <NoData msg="There is no courses available" />
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ProfileCourses;
