import { motion } from "framer-motion";
import React, { useContext, useState } from "react";
import { FormMode } from "./LoginSM";
import { MotionInput } from "../../iComponents/MotionInput";
import {
  LocaleContext,
  LocaleContextProps,
} from "../../contexts/LocaleContext";
import useAuthServices from "../../api/useAuthServices";
import toast from "react-hot-toast";
import {
  ResetPasswordContext,
  ResetPasswordContextProps,
} from "../../contexts/ResetPasswordContext";

export function ResetPassword({ setError, setFormMode }: any) {
  const { t } = useContext(LocaleContext) as LocaleContextProps;
  const { resetPassword } = useAuthServices();
  const { infos } = useContext(
    ResetPasswordContext
  ) as ResetPasswordContextProps;

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const [resetPasswordForm, setResetPasswordForm] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleResetPassword = () => {
    const errors = Object.values(validationErrors).every(
      (value) => value !== ""
    );
    if (!errors) {
      resetPassword(
        infos.email,
        infos.phone,
        infos.token.toString(),
        resetPasswordForm.newPassword,
        resetPasswordForm.confirmPassword
      ).then((res) => {
        setError("");
        setValidationErrors({});
        toast.success(t("Password has been changed successfully!"));
        setFormMode(FormMode.SignIn);
      });
    }
  };

  const handleChange = (e: any) => {
    setResetPasswordForm({
      ...resetPasswordForm,
      [e.target.name]: e.target.value,
    });

    setValidationErrors({
      ...validationErrors,
      [e.target.name]: e.target.value ? "" : "required",
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
      className="animate__animated animate__fadeIn"
      id="smForm"
    >
      <b>{t("Please set your new password")}</b>

      <MotionInput
        form={resetPasswordForm}
        name="newPassword"
        type="password"
        placeholder={t("newPasswordPlaceholder")}
        handleChange={handleChange}
        validationErrors={validationErrors}
      />

      <MotionInput
        form={resetPasswordForm}
        name="confirmPassword"
        type="password"
        placeholder={t("confirmPasswordPlaceholder")}
        handleChange={handleChange}
        validationErrors={validationErrors}
      />

      <motion.button
        onClick={(e) => {
            e.preventDefault();
            handleResetPassword();
        }}
        className="LoginSMsigin-btn"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {t("Confirm")}
      </motion.button>
    </motion.div>
  );
}
