import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeroSectionSvg from "./svg/hero-section.svg";
import { UserContext, UserContextProps } from "src/contexts/UserContext";
import NajBanner from '../../assets/images/naj-baner.png'

export default function SubHeaderSection() {
  const { isAuthenticated } = useContext(UserContext) as UserContextProps;
  const { t, i18n } = useTranslation();

  const isArabic = i18n.language === "ar";
  const direction = isArabic ? "rtl" : "ltr";

  return (
    <>
      <div className="sub-header" dir={direction}>
        <div className="main-container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="text-sub-header">

                <div className='w-auto'>
                <h1 style={{ color: "#214899" }}>{t("NAJ Training title")}</h1>
                <p>{t("NAJ Training description")}</p>

                <div className="btns-sub-header">
                  <Link to="/page/About" className="ctm-btn">
                    {t("Read More")}
                  </Link>
                  {!isAuthenticated && (
                    <Link to="/signup" className="ctm-btn2">
                      {t("Join Now")}
                    </Link>
                  )}
                </div>
                </div>
                <img src={NajBanner} className='w-75 mt-2 mt-md-5'/>
              </div>
            </div>
            <div className="col-lg-6 d-lg-block d-xl-block d-none">
              <div className="img-sub-header">
                <object data={HeroSectionSvg}>
                  <img loading="lazy" src={HeroSectionSvg} alt="" />
                </object>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
