import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {Link} from "react-router-dom";
import {Drawer, SwipeableDrawer} from "@mui/material";
import React, {useContext} from "react";
import {LocaleContext, LocaleContextProps} from "../../contexts/LocaleContext";
import Notifications from "../Notifications/Notifications";

export function NotificationDrawer({toggleDrawer,anchor, showNotifications, setShowNotifications} : any) {
    const { t, direction } = useContext(LocaleContext) as LocaleContextProps;

    return(

        <Drawer
            anchor={anchor}
            open={showNotifications}
            onClose={toggleDrawer}
            id="menuDrawer"
            dir={direction}
        >
        <Notifications showNotifications={showNotifications} setShowNotifications={setShowNotifications}/>
        </Drawer>
    )
}