export const synchronizeCarts = async ({
  backendCart,
  cartItems,
  setCartItems,
  addToUserCart,
  updateToUserCart,
  deleteFromUserCart,
  setBackendPriceDetails,
  loadReadyUserCart,
}: any) => {
  const { productWithAddons } = backendCart;
  const itemsToAdd = cartItems.filter((frontendItem: any) => {
    return !productWithAddons.some(
      (backendItem: any) => backendItem.id === frontendItem.uuid
    );
  });

  const itemsToUpdate = cartItems.filter((frontendItem: any) => {
    return productWithAddons.some(
      (backendItem: any) =>
        backendItem.id === frontendItem.uuid &&
        frontendItem.count !== backendItem.qty
    );
  });

  const itemsToDelete = productWithAddons.filter((backendItem: any) => {
    return !cartItems.some(
      (frontendItem: any) => frontendItem.uuid === backendItem.id
    );
  });

  const addRequests = itemsToAdd.map(async (item: any) => {
    try {
      const response = await addToUserCart({
        productId: item.id,
        qty: item.count,
      });

      if (response) {
        item.uuid = response;
        loadReadyUserCart().then((res: any) => {
          setBackendPriceDetails({
            subTotal: res.subTotal,
            totalProductsDiscountsAmount: res.totalProductsDiscountsAmount,
            coupons: res.coupons,
            totalCouponsDiscountsAmount: res.totalCouponsDiscountsAmount,
            totalVatAmount: res.totalVatAmount,
            totalOtherTaxesAmount: res.totalOtherTaxesAmount,
            finalPrice: res.finalPrice,
          })
        })
      } else {
        // toast.error("You have outdated items in your cart, we removed it.");
        const indexToRemove = itemsToAdd.indexOf(item);
        if (indexToRemove !== -1) {
          itemsToAdd.splice(indexToRemove, 1);
        }
      }
    } catch (error) {
      // toast.error("You have outdated items in your cart, we removed it.");

      // Remove the item from itemsToAdd in case of an exception
      const indexToRemove = itemsToAdd.indexOf(item);
      if (indexToRemove !== -1) {
        itemsToAdd.splice(indexToRemove, 1);
      }
    }
  });

  const updateRequests = itemsToUpdate.map(async (item: any) => {
    try {
      const response = await updateToUserCart({
        uuid: item.uuid,
        productId: item.id,
        qty: item.count,
        couponCode : item.coupon?.code
      });
      if (response.status === 200) {
        item.forceUpdate = true;
        loadReadyUserCart().then((res: any) => {
          setBackendPriceDetails({
            subTotal: res.subTotal,
            totalProductsDiscountsAmount: res.totalProductsDiscountsAmount,
            coupons: res.coupons,
            totalCouponsDiscountsAmount: res.totalCouponsDiscountsAmount,
            totalVatAmount: res.totalVatAmount,
            totalOtherTaxesAmount: res.totalOtherTaxesAmount,
            finalPrice: res.finalPrice,
          })
        })
      }
    } catch (error) {
      // toast.error("You have outdated items in your cart, we updated it.");
      item.forceUpdate = false;
    }
  });

  // const removeRequests = itemsToDelete.map(async (item: any) => {
  //   try {
  //     const response = await deleteFromUserCart(item.id);
  //     if (response.status === 200) {
  //       item.forceDelete = true;
  //     }
  //   } catch (error) {
  //     toast.error("You have outdated items in your cart, we remove it.");
  //     item.forceDelete = false;
  //   }
  // });

  await Promise.all(addRequests);
  await Promise.all(updateRequests);
  // await Promise.all(removeRequests);

  const transformedBackendItems = productWithAddons
    .map((item: any) => {
      const updatedItemCount = itemsToUpdate.find(
        (frontItem: any) => frontItem.uuid === item.id
      );


      const selectedAddons = item.product?.addons
        .filter((addon: any) =>
          item.selectedAddonsIds?.some((selectedAddon: any) => selectedAddon.addonId === addon.id)
        )
        .map((addon: any) => {
          const selectedAddon = item.selectedAddonsIds.find(
            (selectedAddon: any) => selectedAddon.addonId === addon.id
          );
          return {
            ...addon,
            qty: selectedAddon.qty
          };
        });

      return {
        id: item.product?.id,
        uuid: item.id,
        name: item.product?.name,
        count: updatedItemCount ? updatedItemCount.count : item.qty,
        image: item?.product?.media[0] && item.product.media[0]['fullUrl'],
        selectedCheckedInDate: item.selectedCheckedInDate,
        selectedInstructorId: item.selectedInstructorId,
        selectedLocationId: item.selectedLocationId,
        selectedAddonsIds: item.selectedAddonsIds,
        addons: item.product?.addons || [],
        locations: item.product?.locations || [],
        speakers: item.product?.speakers || [],
        maxQTYPerOrder: item.product?.maxQTYPerOrder,
        minQTYPerOrder: item.product?.minQTYPerOrder,
        categoryId: item.product?.category?.id,
        coupon: item.coupon,
        price: item?.price || item.product?.price,
        selectedAddons: selectedAddons,
      };
    })
    .filter((item: any) => item !== null);
    setBackendPriceDetails({
      subTotal: backendCart.subTotal,
      totalProductsDiscountsAmount: backendCart.totalProductsDiscountsAmount,
      coupons: backendCart.coupons,
      totalCouponsDiscountsAmount: backendCart.totalCouponsDiscountsAmount,
      totalVatAmount: backendCart.totalVatAmount,
      totalOtherTaxesAmount: backendCart.totalOtherTaxesAmount,
      finalPrice: backendCart.finalPrice,
    })
  setCartItems([...transformedBackendItems, ...itemsToAdd]);
};

export const synchronizeCartsOffline = (cartItems: any, backendItems: any, setBackendPriceDetails: any) => {
  const { productWithAddons } = backendItems;
  setBackendPriceDetails({
    subTotal: backendItems.subTotal,
    totalProductsDiscountsAmount: backendItems.totalProductsDiscountsAmount,
    coupons: backendItems.coupons,
    totalCouponsDiscountsAmount: backendItems.totalCouponsDiscountsAmount,
    totalVatAmount: backendItems.totalVatAmount,
    totalOtherTaxesAmount: backendItems.totalOtherTaxesAmount,
    finalPrice: backendItems.finalPrice,
  })
  return cartItems.map((cartItem: any) => {
    // Find the corresponding item in backendItems
    const backendItem = productWithAddons.find(
      (item: any) => item.product.id === cartItem.id
    );
    const selectedAddons = cartItem.product?.addons
        .filter((addon: any) =>
        cartItem.selectedAddonsIds?.some((selectedAddon: any) => selectedAddon.addonId === addon.id)
        )
        .map((addon: any) => {
          const selectedAddon = cartItem.selectedAddonsIds.find(
            (selectedAddon: any) => selectedAddon.addonId === addon.id
          );
          return {
            ...addon,
            qty: selectedAddon.qty
          };
        });

    // If found, update qty and price in cartItem
    if (backendItem) {
      return {
        ...cartItem,
        addons: backendItem.product?.addons || [],
        locations: backendItem.product?.locations || [],
        speakers: backendItem.product?.speakers || [],
        selectedAddonsIds: backendItem.selectedAddonsIds || [],
        selectedCheckedInDate: backendItem.selectedCheckedInDate,
        selectedInstructorId: backendItem.selectedInstructorId,
        selectedLocationId: backendItem.selectedLocationId,
        categoryId: backendItem.product?.category?.id,
        selectedAddons: selectedAddons,
      };
    }

    // If not found, return the original cartItem
    return cartItem;
  });
};