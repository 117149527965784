import { ISpeaker } from "../../api/ems/schemas";
import { SpeakerContact } from "./SpeakerContact";

interface SpeakerProps {
  speaker: ISpeaker;
  swiper?: boolean
}

export function Speaker({ speaker, swiper }: SpeakerProps) {

  return (
    <div className={`speaker-container ${swiper ? "p-4" : "col-lg-4 col-md-6 col-sm-6 mt-4"}`}>
      <div className="sub-speakers-index">
        <div className="img-speakers-index">
          <img loading="lazy" src={speaker.profilePicture?.fullUrl} alt=""/>
        </div>
        <div className="text-speakers-index">
          <h2>{speaker.title} {speaker.firstName + " " + speaker.lastName}</h2>
          <p style={{maxHeight:150}} className="truncate-3-lines" >{speaker.bio}</p>
        </div>
        <SpeakerContact contact={speaker.contact}/>
        {/*<div className="links-speakers-index">*/}
        {/*    <ul>*/}
        {/*        <li>*/}
        {/*            <a href="#">*/}

        {/*                <img loading="lazy" src={sp01} alt=""/>*/}
        {/*            </a>*/}
        {/*        </li>*/}
        {/*        <li>*/}
        {/*            <Link to="/booking">*/}

        {/*                <img loading="lazy" src={sp02} alt=""/>*/}
        {/*            </Link>*/}
        {/*        </li>*/}
        {/*    </ul>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}