import React, {useContext, useState} from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {makeStyles} from '@mui/styles';
import {LocaleContext} from "../../contexts/LocaleContext";

const useStyles = makeStyles((theme) => ({
  announcementWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 9999,
  },
  announcementContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#d6edf6',
    borderWidth: 0,
    borderRadius: 0,
    color: '#000',
    fontSize: '16px',
    minHeight: '80px',
    padding: '15px 40px',
    position: 'absolute',
    top: '50px',
    left: '0',
    right: '0',
    margin: 'auto',
  },
  announcementTitle: {
    marginBottom: '8px',
    fontSize: 'large',
    fontWeight: 'bold',
  },
  announcementContent: {
    lineHeight: '1.5',
  },
}));
export default function Announcement() {
  const {direction, t} = useContext(LocaleContext);

  const classes = useStyles();
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const handleClose = () => {
    setShowAnnouncement(false);
    localStorage.setItem('showAnnouncement', 'false');
  };
  const shouldShowAnnouncement = localStorage.getItem('showAnnouncement') !== 'false';
  if (!shouldShowAnnouncement) {
    return null;
  }
  return (
    <div id='Announcement' dir={direction} style={{marginBottom:"20px"}}>
        {showAnnouncement && (
            <div className={classes.announcementWrapper} >
                <Paper
                    style={{
                      width: '100%',
                      justifyContent: 'space-between',
                      background: '#d6edf6',
                      color: '#000',
                    }}
                    className={classes.announcementContainer}
                >
                  <div style={{
                    width: '100%',
                    maxWidth:"1536px",
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    background: '#d6edf6',
                    color: '#000',
                  }}>
                    <div className="announcementDiv">
                      <Typography variant="h5" className={classes.announcementContent}>
                        {t('announcement')}
                      </Typography>
                      <a href='/signup' className={classes.announcementTitle}>
                        {t('registerNow')}
                      </a>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <IconButton aria-label='Close' onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </Paper>
            </div>
        )}
    </div>
  );
}
