import { Box, FormControl, IconButton, Typography } from "@mui/material";
import { MotionInput } from "../../iComponents/MotionInput";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import CloseIcon from "@mui/icons-material/Close";
import "./Cases.css";
import Select from 'react-select'
import EditorInput from "../../iComponents/Editor/EditorInput";
import useCasesAPI, { IAddEditCase } from "../../api/ems/cases";
import { ICase, ICaseCategory, ICaseTag } from "../../api/ems/schemas";
import toast from "react-hot-toast";

const modalStyle = {
    // position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
    overflowY: 'overlay',
    maxHeight: '100vh'
};

const tagOptions = [
    {value: 'tag1', label: 'tag1'},
    {value: 'tag2', label: 'tag2'},
    {value: 'tag3', label: 'tag3'}
]

interface AddEditCaseProps {
    handleClose: (success: boolean) => void;
    caseToEdit: ICase | null;
}

export function AddEditCase({handleClose, caseToEdit}: AddEditCaseProps) {
    const {t, direction, isRtl} = useContext(LocaleContext) as LocaleContextProps;
    const [tags, setTags] = React.useState<ICaseTag[]>([]);
    const [categories, setCategories] = React.useState<ICaseCategory[]>([]);
    const [previewBannerUrl, setPreviewBannerUrl] = useState<string | null>(null);

    const {loadCasesCategories, loadCasesTags, addCase, updateCase,loadMyCaseDetails} = useCasesAPI();

    const [formData, setFormData] = useState<IAddEditCase>({
        id: null,
        title: '',
        postCategoryId: 0,
        tags: [],
        content: '',
        media: null,
        isDraft: true,
    });

    useEffect(() => {
        loadCasesCategories().then(data => setCategories(data)).catch(err => {});
        loadCasesTags().then(data => setTags(data)).catch(err => {});
        if(caseToEdit){
            loadMyCaseDetails(caseToEdit.id).then(response=>{
                setFormData(formData => ({
                    ...formData,
                    id: caseToEdit.id,
                    title: response.title,
                    postCategoryId: response.postCategory.id,
                    content: response.content,
                    media: response.media,
                    tags:response.tags
                }))
                if(response.media){
                    setPreviewBannerUrl(response.media.fullUrl);
                }
            })
        }
    }, [])

    const handleChange = (e: any) => {
        setFormData(formData=>({
            ...formData,
            [e.target.name]: e.target.value
        }));
    };

    const setCaseCategory = (option: any) => {
        const optionId = option.id;
        setFormData(formData=>({
            ...formData,
            postCategoryId: optionId
        }));
    }

    const setCaseTags = (options: any[]) => {
        setFormData(formData=>({...formData, tags: options}));
    }

    const closeCase = ()=> {
        handleClose(false)
    }

    const handleBannerChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target && e.target.files && e.target.files[0];
        setFormData(formData=>({
            ...formData,
            media: file
        }));
        if (file && file.type.startsWith("image/")) {
            if (typeof FileReader !== "undefined") {
                const fileReader = new FileReader();
                fileReader.onloadend = () => {
                    setPreviewBannerUrl(fileReader.result as string);
                };

                fileReader.readAsDataURL(file);
            } else {
                setPreviewBannerUrl(null);
            }

        } else {
            setPreviewBannerUrl(null);
        }
    };

    const changeContent = (content: any) => {
        setFormData(formData=>({
            ...formData,
            content: content
        }));
    }

    const handleSubmit = (isDraft: boolean) => {
        const caseToSubmit = {...formData,tags:formData.tags?.map(tag=>tag.id) ?? [], isDraft: isDraft};
        let promise = null;
        let updating = false;
        if(caseToEdit && caseToEdit.id){
            updating = true;
            promise = updateCase(caseToSubmit)
        } else {
            promise = addCase(caseToSubmit)
        }
        promise.then(resp=>{
            toast.success(updating ? t("Case updated successfully!") : t("Case added successfully!"));
            handleClose(true);
        }).catch(err=>{
            toast.error(t("Error adding case"));
        })
    }
    
    return (
        <div className="modal-container" dir={direction}>
            <Box sx={modalStyle}>
                <div
                    className="animate__animated animate__fadeIn"
                    id="">
                    <div className="space-between"
                         style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: "10px"}}>
                            {t('Add your case')}
                        </Typography>

                        <IconButton onClick={closeCase}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <br/>
                    <MotionInput
                        form={formData}
                        name="title"
                        placeholder={t('Case title')}
                        handleChange={handleChange}
                        className="input-form"
                    />
                    <div className="form-group">
                        <label style={{padding: "0"}}>{t('Case category')} :</label>
                        <FormControl fullWidth>
                            {/*<InputLabel id="demo-multiple-chip-label" dir={direction}>{t('Case category')}</InputLabel>*/}

                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isSearchable={true}
                                isRtl={isRtl}
                                placeholder={t('Case category')}
                                name="category"
                                options={categories}
                                value={categories.filter(ct=>ct.id === formData.postCategoryId)}
                                onChange={(o: any) => {
                                    setCaseCategory(o);
                                }}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id + ""}
                                onInputChange={val => {
                                    loadCasesCategories(val).then(data => setCategories(data)).catch(err => {})

                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="form-group">
                        <label style={{padding: "0"}}>{t('Case tag')} :</label>
                        <FormControl fullWidth>
                            {/*<InputLabel id="demo-multiple-chip-label" dir={direction}>{t('Case tag')}</InputLabel>*/}
                            <Select
                                isMulti
                                className="basic-single"
                                classNamePrefix="select"
                                isRtl={isRtl}
                                isSearchable={true}
                                name="tags"
                                options={tags}
                                value={formData.tags}
                                placeholder={t('Case tag')}
                                onChange={(o: any) => {
                                    setCaseTags(o);
                                }}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onInputChange={val => {
                                        loadCasesTags(val).then(data => setTags(data)).catch(err => {})
                                }}
                            />

                        </FormControl>
                    </div>

                    <MotionInput
                        form={formData}
                        type="file"
                        name="banner"
                        placeholder={t('Case banner')}
                        handleChange={handleBannerChange}
                        className="input-form"
                    />

                    {previewBannerUrl && <div
                        className="rounded thumbnail"
                        style={{height: 265}}
                    >
                        <img
                            src={previewBannerUrl}
                            className="rounded thumbnail"
                            style={{height: 265, width: "100%"}}
                        />
                    </div>
                    }

                    <div className="form-group">
                        <label style={{padding: "0"}}>{t('Content')} :</label>
                        <EditorInput content={formData.content ?? ''} changeContent={changeContent}/>
                    </div>
                    <br/>
                    <div className={`form-group row `}>
                        <div className="btn-edit-profile addCase-buttons">
                            <button className={`ctm-btn2`} onClick={closeCase}>{t('Cancel')}</button>
                            <div>
                                <button className={`ctm-btn2`} onClick={()=>handleSubmit(true)}>{t('Draft')}</button>
                                <button className={`ctm-btn`} onClick={()=>handleSubmit(false)}>{t('Add')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    );
}