import React, { useContext, useEffect, useState } from 'react'
import { Container } from '@mui/material'
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import { ProductCategoriesTabs } from "../../components/ProductsSection/ProductCategoriesTabs";
import useProductsAPI from "../../api/ems/products";
import { IProductCategory, IProductsByCategory } from "../../api/ems/schemas";

export default function Products() {
    const { t, direction , isRtl } = useContext(LocaleContext) as LocaleContextProps;
    const [productsCategories, setProductsCategories] = useState<IProductCategory[]>([]);
    const [productsByCategory, setProductsByCategory] = useState<IProductsByCategory[]>([]);

    const {loadProductsCategories, loadProductsByCategory} = useProductsAPI();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const categories = await loadProductsCategories();
                setProductsCategories(categories);

                if (categories && categories.length >= 1) {
                    categories.forEach(category => {
                        const categoryId = category.id;
                        loadProductsByCategory(categoryId).then(response => {
                            const productByCategory: IProductsByCategory = { category: categoryId, products: response };
                            setProductsByCategory((old) => [...old, productByCategory]);
                        }).catch(() => {
                            setProductsByCategory((old) => [...old, { category: categoryId, products: [] }]);
                        })
                    });
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, [isRtl]);

    return (
        <div dir={direction}>
            <section className="product-page mr-section">
                <Container maxWidth="xl" className="main-container">
                    <div className="title-center">
                        <h3>{t('productsHeader')}</h3>

                    </div>

                    <ProductCategoriesTabs productsCategories={productsCategories} productsByCategory={productsByCategory}/>

                </Container>
            </section>
        </div>
    )
}
