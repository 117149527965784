import React, { createContext, useEffect, useState } from "react";

export enum ServerStatus{
  UP,
  DOWN
}

export interface ServerContextProps {
  status:ServerStatus
  setStatus:  React.Dispatch<React.SetStateAction<ServerStatus>>
}

const ServerContext = createContext<ServerContextProps | undefined>(undefined);

const ServerProvider = ({ children } : any) => {

  const [status,setStatus] = useState(ServerStatus.UP);

  useEffect(()=>{

    // setLangParam(lang);
  }, [])

  return (
    <ServerContext.Provider
      value={{
        status:status,
        setStatus:setStatus
      }}
    >
      {children}
    </ServerContext.Provider>
  );
}

export { ServerProvider, ServerContext };