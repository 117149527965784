import React, { useContext, useEffect, useState } from "react";
import { IProductCategory, IProductsByCategory } from "../../api/ems/schemas";
import Product from "./Product";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import NoData from "../../iComponents/NoData/NoData";
import useProductsAPI from "../../api/ems/products";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";

interface ProductCategoriesTabsProps {
  productsCategories: IProductCategory[];
  productsByCategory: IProductsByCategory[];
  loading?: boolean;
}

export const ProductCategoriesTabs: React.FC<ProductCategoriesTabsProps> = ({
                                                                              productsCategories,
                                                                              productsByCategory,
                                                                              loading
                                                                            }) => {
  const { t, isRtl } = useContext(LocaleContext) as LocaleContextProps;
  const [allProducts, setAllProducts] = useState([])
  const { getAllHomeProducts } = useProductsAPI();
  const [loadingAll, setLoadingAll] = useState(true);

  useEffect(() => {
    getAllHomeProducts().then((products) => {
      setAllProducts(products)
      setLoadingAll(false)
    }).catch((error) => {
      toast.error(error.response?.data?.details ?? 'Error while fetching all products')
    })
  }, [isRtl])
  return (
    <>
      <div className="links-products-tabs">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item">
            <a
              className={`nav-link active`}
              id={`pills-all-products-tab`}
              data-toggle="pill"
              href={`#pills-all-products`}
              role="tab"
              aria-controls={`pills-all-products`}
              aria-selected="false"
            >
              {t("All Products")}
            </a>
          </li>
          {productsCategories.map((pc, index) => (
            <li className="nav-item">
              <a
                className={`nav-link`}
                id={`pills-${pc.id}-tab`}
                data-toggle="pill"
                href={`#pills-${pc.id}`}
                role="tab"
                aria-controls={`pills-${pc.id}`}
                aria-selected="false"
              >
                {isRtl && pc.nameAr ? pc.nameAr : pc.name}
              </a>
            </li>
          ))}

        </ul>
      </div>

      <div className="tabs-details-products">
        <div className="tab-content" id="pills-tabContent">
          {productsByCategory.map((pbc, index) => (
            <div
              className={`tab-pane fade`}
              id={`pills-${pbc.category}`}
              role="tabpanel"
              aria-labelledby={`pills-${pbc.category}-tab`}
            >
              <div className="main-products-index">
                {loading ? <div className="w-100 d-flex h-100 justify-content-center align-items-center"><CircularProgress size={64}/></div> :
                  <div className="row">


                    {pbc.products.map((product) => (
                      <Product product={product} index={pbc.category}/>
                    ))}

                    {!pbc.products ||
                    (pbc.products.length === 0 && (
                      <NoData msg="There is no product for this category"/>
                    ))}


                  </div>}
              </div>
            </div>
          ))}
          <div
            className={`tab-pane fade show active`}
            id={`pills-all-products`}
            role="tabpanel"
            aria-labelledby={`pills-all-products-tab`}
          >
            <div className="main-products-index">
              {loadingAll ? <div className="w-100 d-flex h-100 justify-content-center align-items-center"><CircularProgress size={64}/></div> :
                <div className="row">

                  {allProducts.map((product, index) => (
                    <Product product={product} index={index}/>
                  ))}

                  {!allProducts ||
                  (allProducts.length === 0 && (
                    <NoData msg="There is no product"/>
                  ))}

                </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
