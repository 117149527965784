import React from 'react';
import Loading from '../../components/Loading/Loading';
import logo from './assets/Logo.svg';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Verification() {
  const digitValidate = (_e: any) => {
    // Implement your digit validation logic here, if needed
  };


  return (
    <>
      <Loading />

      

      <Container maxWidth="xl" className="verification login-page">
        <div className="row pg_none">
          <div className="col-lg-7 pg_none">
            <div className="title-login">
              <h2>NAJ Training Center</h2>
              <p>We're a specialized Center that offers educational services to all Dental students in Saudi Arabia ..</p>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="main-verification main-login">
              <div className="logo-login">
                <Link to ="/">
                  <img loading="lazy" src={logo} alt="" />
                </Link>
              </div>
              <div className="text-login-main">
                <h2>Verification Code</h2>
                <p>Please enter verification code to change your password</p>
              </div>
              <div className="form-login">
                <form>
                  <div className="otp_input">
                    <input className="otp" type="text" onInput={digitValidate} maxLength={1} />
                    <input className="otp" type="text" onInput={digitValidate} maxLength={1} />
                    <input className="otp" type="text" onInput={digitValidate} maxLength={1} />
                    <input className="otp" type="text" onInput={digitValidate} maxLength={1} />
                  </div>
                  <div className="btn-form-login">
                    <button className="ctm-btn2">confirm</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
