/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import documentContents from "./documentContents";
import toast from "react-hot-toast";

import "./CourseDetailsPage.css";
import CourseDetailsHeader from "../../components/CourseDetailsHeader/CourseDetailsHeader";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from "@mui/joy/Box";
import VideoCard from "src/components/VideoCard/VideoCard";

const Sidebar = styled.div<{ isOpen: boolean }>`
  /* ... Your existing sidebar styles ... */

  /* New styles for responsive behavior */
  @media screen and (max-width: 768px) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;

const CourseDetailsContainer = styled.div`
  /* ... Your existing course details container styles ... */
`;

const Heading = styled.h1`
  /* ... Your existing heading styles ... */
`;

const Paragraph = styled.p`
  /* ... Your existing paragraph styles ... */
`;

const ArrowButton = styled.button`
  /* ... Your styles for the arrow button ... */
`;

const TimelineContainer = styled.div`
  position: relative;
`;

const VerticalLine = styled.div`
  border-left: 1px solid hsl(0, 0%, 90%);
  position: absolute;
  top: 0;
  left: 40px;
  height: 100%;
`;

const TimelineItem = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding-left: 60px;
`;

const TimelineIcon = styled.div<{
  isActive: boolean;
  isCurrent: boolean;
  isCompleted: boolean;
}>`
  position: absolute;
  left: 25px;
  top: 0;
  background-color: ${(props) =>
    props.isCompleted
      ? "#0b945e"
      : props.isActive
      ? "hsl(217, 88.2%, 90%)"
      : "hsl(0, 0%, 90%)"};
  color: ${(props) =>
    props.isCompleted
      ? "#FFFFFF"
      : props.isActive
      ? "hsl(217, 88.8%, 35.1%)"
      : "hsl(0, 0%, 50%)"};
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

const TopProgressBar = styled.div<{ progress: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: ${(props) => props.progress}%;
  height: 3px;
  background-color: #007bff;
  z-index: 9999;
`;

const MenuIcon = styled.div`
  display: none;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

interface CourseDetailsPageProps {
  coursesData: {
    category: string;
    title: string;
    description: string;
    duration: string;
    instructor: string;
    completed: boolean;
  }[];
}

const CourseDetailsPage: React.FC<CourseDetailsPageProps> = ({
  coursesData,
}) => {
  const { index } = useParams<{ index?: string }>();
  const courseIndex = parseInt(index || "0", 10);
  const selectedCourse = coursesData[courseIndex];
  const documentCount = documentContents.length;
  const [currentPage, setCurrentPage] = useState(0);
  const [activeDocumentIndex, setActiveDocumentIndex] = useState(0);
  const [isSidebarOpen, setSidebarOpen] = useState(false); // Set the initial state to hide the sidebar.
  const [completedDocuments, setCompletedDocuments] = useState<number[]>([]);

  useEffect(() => {
    setCurrentPage(activeDocumentIndex);
  }, [activeDocumentIndex]);

  const progress = ((currentPage + 1) / documentContents.length) * 100;

  const handlePreviousPage = () => {
    setActiveDocumentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setCompletedDocuments((prevCompleted) => {
      const newCompleted = new Set(prevCompleted);
      newCompleted.delete(activeDocumentIndex - 1);
      return Array.from(newCompleted);
    });
  };

  const handleNextPage = () => {
    setActiveDocumentIndex((prevIndex) =>
      Math.min(prevIndex + 1, documentContents.length - 1)
    );
    toast.success("Course Saved!");
    setCompletedDocuments((prevCompleted) => {
      const newCompleted = new Set(prevCompleted);
      newCompleted.add(activeDocumentIndex);
      return Array.from(newCompleted);
    });
  };

  if (!selectedCourse) {
    return (
      <div className="courseNotFound">
        <h1>Course Not Found !</h1>
        <Link to="/myAccount">
          <Button variant="contained">Back To Profile</Button>
        </Link>
      </div>
    );
  }

  const { t, i18n } = useTranslation();

  function toggleSidebar(): void {
    throw new Error("Function not implemented.");
  }
  const handleToggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState); // Toggle the value
  };

  return (
    <>
      <TopProgressBar progress={progress} />

      <MenuIcon id="MenuIconCourse" onClick={handleToggleSidebar}>
        {/* Replace 'menu_icon.png' with your actual menu icon */}
        <img
          loading="lazy"
          width="30"
          height="30"
          src="https://img.icons8.com/ios-glyphs/30/menu--v1.png"
          alt="menu--v1"
        />
      </MenuIcon>

      {/* <SidebarControlIcon isOpen={isSidebarOpen} onClick={handleToggleSidebar} /> */}

      <Sidebar
        className={`sideBarCourse animate__animated ${
          isSidebarOpen ? "animate__slideInLeft" : "hidden"
        }`}
        isOpen={isSidebarOpen}
      >
        <Link className="backToCourseButton" to="/myCourses">
          <ArrowBackIcon/>
          {t("Back to Courses")}
        </Link>
        <div className="main-profile-courses">
          <div
            className="sub-profile-courses"
            style={{ width: "100%", boxShadow: "none" }}
            key={index}
          >
            <div className="category-profile-courses">
              <h3>{selectedCourse.category}</h3>
            </div>
            <h2 style={{ fontSize: "16px" }}>{selectedCourse.title}</h2>
            <p style={{ fontSize: "14px" }}>{selectedCourse.description}</p>
            <div style={{ fontSize: "14px" }} className="duration-courses">
              <img
                loading="lazy"
                src="https://img.icons8.com/ios/16/000000/clock--v1.png"
                alt=""
              />
              Duration : <span>{selectedCourse.duration}</span>
            </div>
            <div style={{ fontSize: "14px" }} className="name-doc">
              <img
                loading="lazy"
                src="https://img.icons8.com/ios-glyphs/16/000000/graduation-cap--v1.png"
                alt=""
              />
              <span>{selectedCourse.instructor}</span>
            </div>
            <span style={{ fontSize: "14px" }} className="CardSpan"></span>
            <hr />
          </div>
        </div>

        <TimelineContainer style={{ padding: "0 0 10rem 0" }}>
          <VerticalLine />
          {documentContents.map((doc, pageIndex) => (
            <TimelineItem key={pageIndex}>
              <TimelineIcon
                isActive={pageIndex === activeDocumentIndex}
                isCurrent={pageIndex === currentPage}
                isCompleted={completedDocuments.includes(pageIndex)}
              >
                {completedDocuments.includes(pageIndex) ? "✓" : pageIndex + 1}
              </TimelineIcon>
              <h5 style={{ fontSize: "16px" }} className="fw-bold">
                {doc.title}
              </h5>
              <p
                style={{ fontSize: "14px" }}
                className="text-muted mb-2 fw-bold"
              >
                {doc.date}
              </p>
              <p  style={{ fontSize: "14px" }} className="text-muted sentence">
                {doc.shortDescription}
              </p>
            </TimelineItem>
          ))}
        </TimelineContainer>
      </Sidebar>

      <CourseDetailsHeader />

      <CourseDetailsContainer className="CourseDetailsContainer">
        <div className="document-content">
          <h1>Title Of Course</h1>

          <br />
          <br />

          {documentContents[currentPage].content}

          <br />
          <br />

          <Box
            component="ul"
            sx={{ display: "flex", gap: 2, flexWrap: "wrap", p: 0, m: 0 }}
          >
            <VideoCard />
          </Box>

          <br />
          <br />

          <p>
            amet consectetur adipisicing elit. Sit necessitatibus adipisci, ad
            alias, voluptate pariatur officia repellendus repellat inventore
            fugit perferendis totam dolor voluptas et corrupti distinctio maxime
            corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Sit necessitatibus adipisci, ad alias, voluptate pariatur
            officia repellendus repellat inventore fugit perferendis totam dolor
            voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum
            dolor sit amet consectetur adipisicing elit. Sit necessitatibus
            adipisci, ad alias, voluptate pariatur officia repellendus repellat
            inventore fugit perferendis totam dolor voluptas et corrupti
            distinctio maxime corporis optio?Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Sit necessitatibus adipisci, ad alias,
            voluptate pariatur officia repellendus repellat inventore fugit
            perferendis totam dolor voluptas et corrupti distinctio maxime
            corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Sit necessitatibus adipisci, ad alias, voluptate pariatur
            officia repellendus repellat inventore fugit perferendis totam dolor
            voluptas et corrupti distinctio maxime corporis optio?
          </p>

          <br />
          <br />
          <Box
            component="ul"
            sx={{ display: "flex", gap: 2, flexWrap: "wrap", p: 0, m: 0 }}
          >
            <img
              style={{ width: "100%" }}
              src="https://cdn.dribbble.com/users/7508004/screenshots/17264350/media/905be0b77a781977adb132268ae2b08b.png?resize=800x600&vertical=center"
              alt="course"
            />
          </Box>

          <br />
          <br />

          <p>
            amet consectetur adipisicing elit. Sit necessitatibus adipisci, ad
            alias, voluptate pariatur officia repellendus repellat inventore
            fugit perferendis totam dolor voluptas et corrupti distinctio maxime
            corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Sit necessitatibus adipisci, ad alias, voluptate pariatur
            officia repellendus repellat inventore fugit perferendis totam dolor
            voluptas et corrupti distinctio maxime corporis optio?Lorem ipsum
            dolor sit amet consectetur adipisicing elit. Sit necessitatibus
            adipisci, ad alias, voluptate pariatur officia repellendus repellat
            inventore fugit perferendis totam dolor voluptas et corrupti
            distinctio maxime corporis optio?Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Sit necessitatibus adipisci, ad alias,
            voluptate pariatur officia repellendus repellat inventore fugit
            perferendis totam dolor voluptas et corrupti distinctio maxime
            corporis optio?Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Sit necessitatibus adipisci, ad alias, voluptate pariatur
            officia repellendus repellat inventore fugit perferendis totam dolor
            voluptas et corrupti distinctio maxime corporis optio?
          </p>
        </div>

        <div className="courseDetailsButtons">
          <ArrowButton
            onClick={handlePreviousPage}
            disabled={currentPage === 0}
          >
            <img
              loading="lazy"
              src="https://img.icons8.com/FFFFFF/ios-glyphs/30/left.png"
              alt="Previous"
            />
            {t("Prev")}
          </ArrowButton>
          <div className="currentPage">
            <span>{currentPage + 1}</span>
            <span>/</span>
            <span>{documentCount}</span>
          </div>
          <ArrowButton
            onClick={handleNextPage}
            disabled={currentPage === documentContents.length - 1}
          >
            {t("Next")}
            <img
              loading="lazy"
              src="https://img.icons8.com/FFFFFF/ios-glyphs/30/arrow.png"
              alt="arrow"
            />
          </ArrowButton>
        </div>
      </CourseDetailsContainer>

    </>
  );
};

export default CourseDetailsPage;
