/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate } from "react-router";

import { Container } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import useNewsAPI, { INews } from "../../api/ems/news";
import NoData from "../../iComponents/NoData/NoData";
import FallbackImage from "../../assets/images/imagefallback.png";
import { formatDate } from "src/utils/dateUtils";
import { FaRegClock } from "react-icons/fa";

export default function News() {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const { loadNews } = useNewsAPI();
  const navigate = useNavigate();

  const [news, setNews] = useState<INews[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadNews(0, null)
      .then((news) => {
        setNews(news.items);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  return (
    <div>
      <Container maxWidth="xl">
        <section className="achievements mr-section" dir={direction}>
          <div className="main-container">
            <div className="title-center">
              <h3>{t("News")}</h3>
              <h2>{t("Latest News & Achievements")}</h2>
            </div>
            {!loading &&
              (news?.length > 0 ? (
                <div className="news-items-grid">
                  {news.map((nws) => (
                    <div
                      className="product-card news-card cursor-pointer"
                      onClick={() => navigate(`/newsDetails/${nws?.id}`)}
                    >
                      <div style={nws?.media?.fullUrl?{
                        backgroundImage: `url(${nws?.media?.fullUrl})`,
                      }:{}} className="product-card-image">
                        <ul className="product-card-labels">
                          {nws?.tags && nws?.tags.length >= 1 ? (
                            nws.tags.map(tag => {
                              return (
                                <li>{tag.name}</li>
                              )
                            })
                          ) : null}
                        </ul>
                        <img
                          loading="lazy"
                          src={nws?.media?.fullUrl || FallbackImage}
                          alt={"asasas"}
                          style={{
                            backgroundColor: nws?.media?.fullUrl
                              ? "unset"
                              : "#81c4ee0f",
                          }}
                        />
                      </div>
                      <div className="product-card-data">
                        <div className="product-card-data-top">
                          <div className="product-card-title-wrap">
                            <h2 className="product-card-title">{nws?.title}</h2>
                          </div>

                          {nws?.content && (
                            <div
                              className="product-card-description"
                              dangerouslySetInnerHTML={{
                                __html: nws ? nws.content : "",
                              }}
                            ></div>
                          )}
                        </div>
                        <div className="product-card-data-bot">
                          <div className="product-card-meta">
                            <div className="asset-wrap">
                              <FaRegClock
                                color="#7cc2ed"
                                className="asset-icon"
                              />
                              <span>{formatDate(nws?.postTime)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <NoData msg="There is no news yet" />
              ))}
          </div>
        </section>
      </Container>
    </div>
  );
}
