import moment from "moment";


export interface DayComponentProps {
  date: moment.Moment;
  setSelectedDate: (date: moment.Moment) => void;
  isSelected?: boolean;
  disabled?: boolean;
}

const DayComponent = ({ date, setSelectedDate, disabled, isSelected }: DayComponentProps) => {

  return (
    <div style={{minWidth:"11%"}} className='d-flex flex-column justify-content-center align-items-center'>
      <small className='text-capitalize text-secondary fw-light fs-6'>
        {date.format('ddd')}
      </small>
      <span
        onClick={() => {
          if (!disabled) setSelectedDate(date)
        }}
        style={{ height:44,boxSizing:'border-box' }}
        className={`${disabled ? 'text-secondary' : 'text-dark'} ${isSelected ? 'border-primary border-2 border fs-4' : ''} d-flex fw-bold align-items-center justify-content-center bg-secondary bg-opacity-50 rounded cursor-pointer w-100`}>
        {date.date()}
      </span>
    </div>
  )

}

export default DayComponent;