import useAxiosWithInterceptor from "../useAxiosWithInterceptor";
import { AxiosResponse } from "axios";
import { PaginatedResult } from "./PaginatedResult";
import { ICase, ICaseCategory, ICaseTag } from "./schemas";
import { objectToFormData } from "../apiUtils";

export interface IAddEditCase{
    id: number | null;
    postCategoryId: number;
    title: string;
    AuthorId?: number;
    content?: string;
    isDraft: boolean;
    tags: any[] | null;
    media: any | null; // You can replace 'any' with the specific type of the 'Media' array if known
}


const useCasesAPI = () => {
    const {axiosInstance} = useAxiosWithInterceptor();

    const loadCasesCategories = (searchText?: any) : Promise<ICaseCategory[]> =>
        axiosInstance
            .get("api/PostsCategories/SearchForCases", {
                params: {
                    text: searchText
                }
            })
            .then((response: AxiosResponse<ICaseCategory[]>) => response.data)
            .catch((error: any) => {
                throw error;
            });

    const loadCasesTags = (searchText?: any) : Promise<ICaseTag[]> =>
        axiosInstance
            .get("api/PostsTags/SearchForCases", {
                params: {
                    text: searchText
                }
            })
            .then((response: AxiosResponse<ICaseTag[]>) => response.data)
            .catch((error: any) => {
                throw error;
            });

    const loadHomeCasesCategories = async (): Promise<PaginatedResult<ICaseCategory>> => {
        const response = await axiosInstance.get(
            "api/Home/NewsCasesCategories?PTypeId="+1,
        );
        return response.data;
    }

    const addCase = async (payload: IAddEditCase): Promise<any> => {
        const formData = objectToFormData(payload);
        const response = await axiosInstance.post(
            "api/Posts/AddCurrentUserCase",
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
        return response.data;
    };

    const updateCase = async (payload: IAddEditCase): Promise<any> => {
        const formData = objectToFormData(payload);
        const response = await axiosInstance.put(
            "api/Posts/UpdateCurrentUserCase/"+payload.id,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
        return response.data;
    };

    const loadMyCases = async (): Promise<PaginatedResult<ICase>> => {
        const response = await axiosInstance.get(
            "api/Posts/GetCurrentUserCases",
        );
        return response.data;
    };

  const loadMyCaseDetails = async (id:number): Promise<ICase> => {
    const response = await axiosInstance.get(
      `api/Posts/CurrentUserCase/${id}`,
    );
    return response.data;
  };

    const loadCaseDetails = (id: string | undefined): Promise<ICase> =>
        axiosInstance
            .get("api/Home/NewsCases/"+id)
            .then((response: AxiosResponse<ICase>) => response.data)
            .catch((error: any) => {
                throw error;
            });


    const deleteCase = async (id: any): Promise<any> => {
        const response = await axiosInstance.delete(`/api/Posts/${id}`);
        return response.data;
    };



    return { loadCasesCategories, loadCasesTags, addCase, updateCase, loadCases: loadMyCases, loadHomeCasesCategories , loadCaseDetails, deleteCase , loadMyCaseDetails};
};

export default useCasesAPI;
