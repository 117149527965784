import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import MyAccount from "../MyAccount/MyAccount";
import EditProfile from "../EditProfile/EditProfile";
import ChangePassword from "../ChangePassword/ChangePassword";
import useUserCartAPI from 'src/api/ems/user-cart';
import { CartContext } from 'src/contexts/CartContext';
import { synchronizeCarts } from '../Cart/utils';
import { UserContext, UserContextProps } from 'src/contexts/UserContext';

export default function Settings() {

  const { t, direction, isRtl } = useContext(LocaleContext) as LocaleContextProps;
  const { cartItems, setCartItems, setBackendPriceDetails } = useContext(CartContext);
  const { handleLogout: handleLogoutGlobal } = useContext(UserContext) as UserContextProps;

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const {
    addToUserCart,
    updateToUserCart,
    deleteFromUserCart,
    loadReadyUserCart,
  } = useUserCartAPI();


  const handleLogout = () => {
    setLogoutModalOpen(true);
  };

  const handleLogoutConfirmation = () => {
    handleLogoutGlobal();
    setLogoutModalOpen(false);
  };

  useEffect(() => {
    loadReadyUserCart().then((response: any) => {
      synchronizeCarts({
        backendCart: response,
        cartItems,
        setCartItems,
        addToUserCart,
        updateToUserCart,
        deleteFromUserCart,
        setBackendPriceDetails,
        loadReadyUserCart,
      }).catch((error => console.error('ERROR', error)));
    });
  }, [isRtl]);

  return (
    <>
      <div className="settings">
        {/*<h2 color={"#214899"}>{t("Settings")} : </h2>*/}
        <div>
          {showEditProfile ? <EditProfile showEditProfile={() => setShowEditProfile(false)}/> :
            <MyAccount showEditProfile={() => setShowEditProfile(true)}/>}
        </div>
        <div>
          {showEditPassword && <ChangePassword showEditPassword={() => setShowEditPassword(false)}/>}
        </div>
        <ul>
          {!showEditPassword &&
          <motion.li
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
          >
              <a onClick={() => setShowEditPassword(true)} style={{ cursor: "pointer" }}>
                {t('Change Password')}
                {direction === 'rtl' ? <FaChevronLeft/> : <FaChevronRight/>}
              </a>
          </motion.li>
          }
        </ul>
        <Modal
          open={logoutModalOpen}
          onClose={() => setLogoutModalOpen(false)}
          aria-labelledby="logout-modal-title"
          aria-describedby="logout-modal-description"
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ p: 4, maxWidth: 400, mx: 'auto', mt: 6, background: 'white', borderRadius: '8px' }}>
            <Typography variant="h6" id="logout-modal-title" gutterBottom>

              {t('Are you sure you want to log out?')}

            </Typography>
            <Button onClick={handleLogoutConfirmation} variant="contained" color="primary">
              {t('Yes, Log out')}

            </Button>
            <Button onClick={() => setLogoutModalOpen(false)} variant="outlined" sx={{ ml: 2 }}>
              {t('Cancel')}
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
}
