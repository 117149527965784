/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import "./LoginSM.css";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { Alert } from "@mui/material";
import { SignUpForm } from "./SignUpForm";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPasswordContext, ResetPasswordContextProps } from "../../contexts/ResetPasswordContext";
import { ResetPassword } from "./ResetPassword";
import { OtpVerify } from "./OtpVerify";
import { AxiosError } from "axios";
import useAuthServices from "../../api/useAuthServices";
import toast from "react-hot-toast";

interface LoginProps {
  mode?: FormMode;
  returnUrl?: string;
  closeModal?: () => void;
}

export enum FormMode {
  SignIn,
  SignUp,
  ForgotPassword,
  Verification,
  ResetPassword,
  SignUpVerfiy,
}

const LoginSM: React.FC<LoginProps> = ({
                                         mode = FormMode.SignIn,
                                         returnUrl,
                                         closeModal,
                                       }) => {
  const [formMode, setFormMode] = useState(mode);
  const { loginUser } = useContext(UserContext) as UserContextProps;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [resendConfirmation,serResendConfirmation] = useState(false);
  const { resendConfirmationEmail } = useAuthServices();
  const { setInfos,infos } = useContext(ResetPasswordContext) as ResetPasswordContextProps;


  const handleSignIn = (e?: any) => {
    e?.preventDefault();
    setIsSubmitting(true);
    loginUser(username, password, returnUrl)
      .then(() => {
        if (closeModal) closeModal();
      })
      .catch((error: AxiosError<any>) => {
        if (error.response?.status === 400 && error.response?.data ==="Account might need Email Validation.") {
          resendConfirmationEmail(username).then(() => {
            setInfos({...infos,email:username})
            setFormMode(FormMode.SignUpVerfiy)
            setError(error.response?.data as string);
            serResendConfirmation(true)
            toast.success("Confirmation Email resent")
          }).catch(() => {
            toast.error("Error while sending Confirmation Email")
          })
        } else {
          setError(error?.response?.data?.detail || t("something went wrong"));
        }
      }).finally(() => setIsSubmitting(false));
  };

  const handleSignUpClick = () => {
    setError("");
    setFormMode(FormMode.SignUp);
  };

  const handleForgotPasswordClick = () => {
    setError("");
    setFormMode(FormMode.ForgotPassword);
  };

  const renderFormContent = () => {
    switch (formMode) {
      case FormMode.SignUp:
        return (
          <SignUpForm
            setIsSubmitting={setIsSubmitting}
            setError={setError}
            setFormMode={setFormMode}
          />
        );
      case FormMode.SignUpVerfiy:
        return <OtpVerify routeBack={() => {
          if(resendConfirmation){
            setError('')
            handleSignIn()
          }else{
            setFormMode(FormMode.SignIn)
          }
        }} verifyType={FormMode.SignUpVerfiy}/>;
      case FormMode.Verification:
        return <OtpVerify routeBack={() => setFormMode(FormMode.ResetPassword)} verifyType={FormMode.Verification}/>;
      case FormMode.ResetPassword:
        return <ResetPassword setError={setError} setFormMode={setFormMode}/>;
      case FormMode.ForgotPassword:
        return <ForgotPassword setError={setError} setFormMode={setFormMode}/>;
      case FormMode.SignIn:
      default:
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
            className="animate__animated animate__fadeIn"
            id="smForm"
          >
            <div>
              <label htmlFor="email">{t("emailLabel")}</label>
              <motion.input
                placeholder={t("emailPlaceholder")} // Add a corresponding translation key for the placeholder
                className="LoginSMinput"
                name="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.3 }}
              />

              <br/>
              <br/>
              <label htmlFor="password">{t("passwordLabel")}</label>

              <motion.input
                placeholder={t("passwordPlaceholder")} // Add a corresponding translation key for the placeholder
                className="LoginSMinput"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.3 }}
              />
            </div>

            <a
              className="forget"
              onClick={handleForgotPasswordClick}
              style={{ cursor: "pointer" }}
            >
              {t("forgotPassword")}
            </a>

            <motion.button
              disabled={isSubmitting}
              onClick={handleSignIn}
              className="LoginSMsigin-btn"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t("signIn")}
            </motion.button>

            <p className="LoginSMsignup-link">
              {t("dontHaveAnAccount")}

              <a style={{ color: "#398dde" }} onClick={handleSignUpClick}>
                {t("signUp")}
              </a>
            </p>
          </motion.div>
        );
    }
  };

  return (
    <>
        <motion.form
          className="LoginSMform animate__animated animate__fadeIn animate__faster"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          // onSubmit={handleSignIn}
          dir={direction}
        >
          <div className="LoginSMheader">
            {formMode === FormMode.SignIn && t("welcomeBack")}
            {formMode === FormMode.SignUp && t("signUp")}
            {formMode === FormMode.ForgotPassword && t("forgotPassword")}
            {formMode === FormMode.Verification && t("verificationCode")}
            {formMode === FormMode.ResetPassword && t("newPasswordTitle")}
          </div>
          {error && (
            <Alert severity="error" dir={direction}>
              {error}
            </Alert>
          )}
          <motion.div
            className="LoginSMinputs"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {renderFormContent()}
          </motion.div>
        </motion.form>
    </>
  );
};

export default LoginSM;
