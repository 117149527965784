import PropTypes from "prop-types";
import * as React from "react";
import { Card } from "reactstrap";

interface TimeLineGroupProps {
  children: React.ReactNode;
}

export const TimeLineGroup: React.FC<TimeLineGroupProps> = props => {
  return (
    <Card color="default" className='mt-2'>
      <ul className="list-group timeline-group">{props.children}</ul>
    </Card>
  );
};

TimeLineGroup.propTypes = {
  children: PropTypes.node.isRequired,
};