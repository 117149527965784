/* eslint-disable @typescript-eslint/no-unused-vars */
import {useTranslation} from 'react-i18next';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// Import the images from the assets directory
import {Container} from '@mui/material';
import SidebarMyaccount from '../../components/SidebarMyaccount/SidebarMyaccount';
import {FaCheck} from 'react-icons/fa';

export default function ChangeLanguage() {
    const {t, i18n} = useTranslation();

    const handleChangeLanguage = (language: string | undefined) => {
        i18n.changeLanguage(language);
    };

    const isArabic = i18n.language === 'ar';
    const direction = isArabic ? 'rtl' : 'ltr';

    return (
        <div className="change-language">
            <h2>{t('Language')} :</h2>
            <form>
                <div className="input-change-language">
                    <input type="radio" className="form-control" name="language"
                           id="language-ar"/>
                    <label htmlFor="language-ar">{t('Arabic')}<FaCheck/></label>
                </div>
                <div className="input-change-language">
                    <input type="radio" defaultChecked className="form-control" name="language"
                           id="language-en"/>
                    <label htmlFor="language-en">{t('English')}<FaCheck/></label>
                </div>
                <div className="btn-change-language">
                    <button className="ctm-btn2">{t('Save')}</button>
                </div>
            </form>
        </div>
    );
}
