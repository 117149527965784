import useAxiosWithInterceptor from "./useAxiosWithInterceptor";
import { AxiosResponse } from "axios";


const useContentApi =()=>{

  const { axiosInstance } = useAxiosWithInterceptor();

  const loadContentTypes = (): Promise<any[]> =>
    axiosInstance
      .get("/api/Home/HomePageTypes")
      .then((response: AxiosResponse<any[]>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const loadContent = (pageTypeId:number,isArabic:boolean): Promise<any> =>
    axiosInstance
      .get("/api/Home/PageContent",{params: { pageTypeId,isArabic }})
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  return {
    loadContentTypes,
    loadContent,
  };
}

export default useContentApi;