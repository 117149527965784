import React, { useContext, useEffect, useState } from "react";
import { Container } from "@mui/material";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { Cancel, CheckCircle, SentimentDissatisfied, } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

import { useLocation, useNavigate } from "react-router-dom";
import { CartContext } from "src/contexts/CartContext";
import usePaymentAPI from "src/api/ems/payment";

export default function PaymentStatus() {
  const location = useLocation();
  const navigate = useNavigate();

  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const { initiateMoyasarCallback } = usePaymentAPI();
  const { clearCart } = useContext(CartContext);
  const [loading, setLoading] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const message = searchParams.get("message");
  const status = searchParams.get("status");

  useEffect(() => {
    if (id) {
      setLoading(true);
      initiateMoyasarCallback({
        id,
        status,
        message,
      })
        .then((response) => {
          setLoading(false);
          setTimeout(() => navigate("/myorders"), 3000);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
      clearCart();
    }
  }, []);

  return (
    <div
      className="body_page  d-flex flex-column justify-content-between"
      dir={direction}
    >
      <section className="mr-section">
        <Container maxWidth="xl" className="main-container">
          <div className="d-flex flex-column justify-content-between align-items-center">
            {id ? (
              loading ? (
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ height: "300px" }}
                >
                  <CircularProgress size={30} />
                </div>
              ) : status === "failed" ? (
                <>
                  <Cancel style={{ color: "red", fontSize: 60 }} />
                  <p>Payment Failed</p>
                </>
              ) : (
                <>
                  <CheckCircle style={{ color: "green", fontSize: 60 }} />
                  <p>Payment Approved</p>
                </>
              )
            ) : (
              <>
                <SentimentDissatisfied
                  style={{ color: "black", fontSize: 60 }}
                />
                <p>Checkout not initiated</p>
              </>
            )}
          </div>
        </Container>
      </section>
    </div>
  );
}
