import React, { useContext } from 'react';
import './notFoundPage.css'; // Make sure to create this CSS file for styling
import { Button, Container } from '@mui/material';
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";

export default function NotFoundPage() {
  const {t} = useContext(LocaleContext) as LocaleContextProps
  return (
    <Container maxWidth="xl" className="d-flex flex-column align-items-center justify-content-center w-100 not-found-container">
      <h1 className="not-found-title">404</h1>
      <p className="not-found-text">{t('Page not found')}</p>
      <Button variant={"text"} onClick={() => window.location.href="/"}>{t('Home')}</Button>
    </Container>
  );
}
