import React, {useContext, useState} from "react";
import notificationsData from "../CourseDetailsHeader/notificationsData";
import {LocaleContext, LocaleContextProps} from "../../contexts/LocaleContext";
import {Alert, Button} from "@mui/material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {Link} from "react-router-dom";

export default function Notifications({showNotifications, setShowNotifications, searchInput}: any) {
    const {t, direction} = useContext(LocaleContext) as LocaleContextProps;

    const [notifications, setNotifications] = useState(
        notificationsData
    );
    const handleClearNotification = (notificationId: number) => {
        const updatedNotifications = notifications.filter(
            (notification) => notification.id !== notificationId
        );
        setNotifications(updatedNotifications);
    };

    const handleClearAllNotifications = (e: any) => {
        e.preventDefault();
        setNotifications([]);
    };

    const renderNotifications = () => {
        return notifications.map((notification) => {
            return (
                <div style={{marginBottom: "5px"}} key={notification.id} dir={direction}>
                    <Alert severity="info" dir={direction}>{t(notification.content)}</Alert>
                </div>
            );
        });
    };

    return (
        <
            // className={`notifications-drawer`}
            // dir={direction}
        >

            <CancelPresentationIcon
                style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "2rem",
                    left: "1rem",
                    zIndex: "9999",
                }}
                onClick={() => setShowNotifications(false)}
            />
            <div
                id="shopping_cart"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "column",
                    minWidth: "400px",
                    padding: "2rem 0",
                    position: "relative",
                    height: "100%",
                    width: "100%",
                }}
            >
                <h3 style={{marginBottom: "1rem"}}>{t("Notifications")}</h3>

                {/*<div className="notifications-header">*/}
                {/*    <h3>{t("Notifications")}</h3>*/}
                {/*</div>*/}
                <div className="notifications-content" dir={direction}>{renderNotifications()}</div>

                <div style={{ zIndex: "9999" }}>
                    <Link to="/Payment" onClick={handleClearAllNotifications}>
                        <button style={{ cursor: "pointer" }} className="ctm-btn">
                            {t("Clear All")}
                        </button>
                    </Link>
                </div>

                {/*<button*/}
                {/*    className="clear-all-notifications"*/}
                {/*    onClick={handleClearAllNotifications}*/}
                {/*>*/}
                {/*    {t("Clear All")}*/}
                {/*</button>*/}
            </div>
        </>
    )
}