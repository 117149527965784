import useAxiosWithInterceptor from "../useAxiosWithInterceptor";
import { IEvent } from "./schemas";
import { AxiosResponse } from "axios";

const futurEventsURL = 'api/HomeEvents/FutureEvents';
const previousEventsURL = 'api/HomeEvents/PreviousEvents'
const productsByEvent = 'api/HomeEvents/Items/'
const eventByIdURL = 'api/HomeEvents/byId/'
const defaultEventTimelineURL = '/api/HomeEvents/DefaultEventTimeline'

const useEventsAPI = () => {
  const { axiosInstance } = useAxiosWithInterceptor();

  const loadFutureHomeEvents = (): Promise<IEvent[]> =>
    axiosInstance
      .get(futurEventsURL)
      .then((response: AxiosResponse<any>) => response.data.items)
      .catch((error: any) => {
        throw error;
      });

  const loadPreviousHomeEvents = (): Promise<IEvent[]> =>
    axiosInstance
      .get(previousEventsURL)
      .then((response: AxiosResponse<any>) => response.data.items)
      .catch((error: any) => {
        throw error;
      });

  const loadItemsByEvent = (idEvent: number): Promise<any[]> =>
    axiosInstance
      .get(`${productsByEvent}${idEvent}`)
      .then((response: AxiosResponse<any[]>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const eventById = (id: number): Promise<any> =>
    axiosInstance
      .get(`${eventByIdURL}${id}`)
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const defaultEventTimeline = async (request: { firstStartDate?: string, lastStartDate?: string }): Promise<any[]> => {
    return (await axiosInstance
      .get(defaultEventTimelineURL, { params: request })).data
  }


  return { loadFutureHomeEvents, loadPreviousHomeEvents, loadItemsByEvent, eventById, defaultEventTimeline };
};

export default useEventsAPI;
