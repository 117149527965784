/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Loading from '../../components/Loading/Loading';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// Import your images from the assets directory
import logoImage from './assets/Logo.svg';
import mailImage from './assets/mail.png';
import {Container} from '@mui/material';

export default function LoginChangePassword() {
    const {t, i18n} = useTranslation();

    const handleChangeLanguage = (language: string | undefined) => {
        i18n.changeLanguage(language);
    };

    const isArabic = i18n.language === 'ar';
    const direction = isArabic ? 'rtl' : 'ltr';

    return (
        <div>
            <section className="forget-passsword login-page" dir={direction}>


                <div className="row pg_none">
                    <div className="col-lg-7 pg_none">
                        <div className="title-login">
                            <h2>{t('NAJ Training Center')}</h2>
                            <p>{t("We're a specialized Center that offers educational services to all Dental students in Saudi Arabia ..")}</p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="main-passsword main-login">
                            <div className="logo-login">
                                <Link to="/">
                                    {/* Use the imported logoImage */}
                                    <img loading="lazy" src={logoImage} alt={t('NAJ Training Center')}/>
                                </Link>
                            </div>
                            <div className="text-login-main">
                                <h2>{t('Forgot Password ?')}</h2>
                                <p>{t('Please enter your email to send verification code')}</p>
                            </div>
                            <div className="form-login">
                                <form>
                                    <div className="input-form">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            placeholder={t('E-mail')}
                                        />
                                        {/* Use the imported mailImage */}
                                        <img loading="lazy" src={mailImage} alt={t('E-mail')}/>
                                    </div>
                                    <div className="btn-form-login">
                                        <button className="ctm-btn2">{t('Send')}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}
