import React, { useContext, useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import './styles.scss';
import moment from "moment";
import MonthCalendar from "./MonthCalendar";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";

const EventsCalendar = ({ events }:any) => {
  const [eventsByMonth,setEventByMonth] = useState<any>({})
  const {direction} = useContext(LocaleContext) as LocaleContextProps

  useEffect(() => {
    const eventsByMonth:any = {}
    Array.from(Array(12).keys()).forEach(value1 => {
      const month = moment().add(value1,"month");
      eventsByMonth[month.format("MM-YYYY")] = events.filter((event:any) => moment(event.startDateTime).format("MM-YYYY") === month.format("MM-YYYY"))
    })
    setEventByMonth(eventsByMonth);
  },[events])


  return (
    <div dir={direction} className='row'>
      {Object.keys(eventsByMonth)
        .sort((a, b) => moment(a, "MM-YYYY").isAfter(moment(b, "MM-YYYY")) ? 1 : -1)
        .map(key => (
          <div key={key} className='col-12 col-sm-6 col-md-4 col-xl-3'>
          <MonthCalendar month={key} events={eventsByMonth[key]}/>
          </div>
        ))}
      </div>
        );

};

export default EventsCalendar;