import { MotionInput } from "./MotionInput";
import React, { useContext } from "react";
import { LocaleContext, LocaleContextProps } from "../contexts/LocaleContext";

export function FullName({ showAllNames = false, form, handleChange, validationErrors, className }: any) {
  const { t } = useContext(LocaleContext) as LocaleContextProps;

  return (
    <>
      <div className={`form-row ${className}`}>
        <div className="col-12 col-sm-6">
          <MotionInput
            form={form}
            name="firstName"
            placeholder={t("firstNamePlaceholder")}
            handleChange={handleChange}
            validationErrors={validationErrors}
          />
        </div>
        <div className="col-12 col-sm-6">
          {showAllNames ?
            <MotionInput
              form={form}
              name="secondName"
              placeholder={t("secondNamePlaceholder")}
              handleChange={handleChange}
              validationErrors={validationErrors}
            />
            :
            <MotionInput
              form={form}
              name="lastName"
              placeholder={t("lastNamePlaceholder")}
              handleChange={handleChange}
              validationErrors={validationErrors}
            />
          }
        </div>
      </div>
      {showAllNames &&
      <div className={`form-row ${className}`}>
          <div className="col">
              <MotionInput
                  form={form}
                  name="thirdName"
                  placeholder={t("thirdNamePlaceholder")}
                  handleChange={handleChange}
                  validationErrors={validationErrors}
              />
          </div>
          <div className="col">
              <MotionInput
                  form={form}
                  name="lastName"
                  placeholder={t("lastNamePlaceholder")}
                  handleChange={handleChange}
                  validationErrors={validationErrors}
              />
          </div>
      </div>
      }


    </>
  )
}