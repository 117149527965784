import React, { useContext } from "react";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";

export function Partner({ title, details, img }: any) {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  return (
    <div className="sponsor-card p-2 rounded" dir={direction}>
        <img loading="lazy" src={img} alt={title} />
    </div>
  );
}
