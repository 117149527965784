import useAxiosWithInterceptor from "../useAxiosWithInterceptor";
import { AxiosResponse } from "axios";
import { PaginatedResult } from "./PaginatedResult";
import { IProduct, IProductByCategory, IProductCategory } from "./schemas";

const productsURL = "api/Products/";

const useProductsAPI = () => {
  const { axiosInstance } = useAxiosWithInterceptor();

  const searchProduct = (text: string): Promise<IProductByCategory[]> =>
    axiosInstance
      .get("api/Home/Search?text=" + text)
      .then((response: AxiosResponse<IProductByCategory[]>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const loadProductsCategories = (): Promise<IProductCategory[]> =>
    axiosInstance
      .get("api/Home/ProductCategories")
      .then((response: AxiosResponse<IProductCategory[]>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const loadProductsByCategory = (
    category: number
  ): Promise<IProductByCategory[]> =>
    axiosInstance
      .get(`api/Home/ProductsByCategory/${category}`)
      .then((response: AxiosResponse<IProductByCategory[]>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const loadProducts = (): Promise<PaginatedResult<IProduct>> =>
    axiosInstance
      .get(productsURL + "GetProductsRegardlessEvent?limit=20&pageIndex=1")
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const loadProductDetails = (
    id: string | undefined
  ): Promise<IProductByCategory> =>
    axiosInstance
      .get("api/Home/ProductById/" + id)
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });
  const loadMyCourses = (): Promise<any> =>
    axiosInstance
      .get("api/TenantUser/CurrentUserCourses")
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const getAllHomeProducts =(): Promise<any> =>
    axiosInstance
      .get("api/Home/AllProducts")
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const getCoursesTimeline =(request: { firstStartDate?: string, lastStartDate?: string }): Promise<any[]> =>
    axiosInstance
      .get("api/Home/CoursesTimeline")
      .then((response: AxiosResponse) => response.data)
      .catch((error: any) => {
        throw error;
      });

  return {
    searchProduct,
    loadProducts,
    loadProductDetails,
    loadProductsCategories,
    loadProductsByCategory,
    loadMyCourses,
    getAllHomeProducts,
    getCoursesTimeline
  };
};

export default useProductsAPI;
