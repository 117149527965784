import React from "react";
import Loading from "../../components/Loading/Loading";
import Header from "../../components/Header/Header";
import AboutUsSection from "../../components/AboutUsSection/AboutUsSection";
import Footer from "../../components/Footer/Footer";
import {Container} from "@mui/material";

export default function AboutUs() {
    return (
        <div>
            <Container maxWidth="xl">
                <AboutUsSection/>
            </Container>
        </div>
    );
}
