import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

import { LocaleContext, LocaleContextProps } from "src/contexts/LocaleContext";
import { FaCalendarDay } from "react-icons/fa";
import { formatSimpleDate } from "../../utils/dateUtils";
import n1Image from "../AchievementsDetails/assets/n3.png";
import { FaLocationPin } from "react-icons/fa6";
import useEventsAPI from "../../api/ems/events";
import CircularProgress from "@mui/material/CircularProgress";
import { Speaker } from "../../components/SpeakersSection/Speaker";

const EventsDetails = () => {
  const { t, direction, lang } = useContext(LocaleContext) as LocaleContextProps;
  const { eventById } = useEventsAPI()
  const { id } = useParams();
  const [event, setEvent] = useState<any>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (id)
      eventById(parseInt(id)).then(event => {
        setEvent(event)
      }).finally(() => setLoading(false))
  }, [])


  return (
    <div>
      <section
        className="achievements-details mr-section mx-auto w-75"
        dir={direction}
      >
        <Container maxWidth="xl">
          <div className="row">
            <div className="col-lg-12">
              {loading ? <CircularProgress className='mx-auto' size={80}/> : (
                <div className="main-achievements-details">
                  <h1>
                    {event?.name}

                  </h1>
                  <div className="data-and-article-author">
                    <ul>
                      <li className="fs-5">
                        <FaCalendarDay/>{" "}
                        {formatSimpleDate(event.startDate, lang)}
                      </li>
                    </ul>
                  </div>
                  <div style={{
                    backgroundImage: `url(${event.banner ? event.banner.fullUrl : n1Image})`,
                  }} className="img-achievements-details">
                    <img
                      loading="lazy"
                      src={
                        event.banner
                          ? event.banner.fullUrl
                          : n1Image
                      }
                      alt=""
                    />

                  </div>
                  <div className="text-achievements-details mt-3">
                    <div className="p-1 ">
                      <h3>{t("Date & Location")}</h3>
                      <div className='d-flex gap-3 align-items-center mb-4'>
                        <span className='d-flex align-items-center'><FaCalendarDay/> {formatSimpleDate(event.startDate, lang)}</span>
                        {event.location && <span className='d-flex align-items-center'><FaLocationPin/> {event.location.name}</span>}
                      </div>
                      <h3>{t('Description')}</h3>
                      <div
                        className="mb-4"
                        dangerouslySetInnerHTML={{
                          __html: event
                            ? event.description
                            : t("lorem"),
                        }}
                      />
                      <h3>{t("speakers")}</h3>
                      <div className="row">
                        {event.speakers?.map((speaker: any) => (
                          <Speaker key={speaker.id} speaker={speaker}/>
                        ))}
                      </div>
                    </div>
                  </div>

                </div>
              )}
            </div>

          </div>
        </Container>
      </section>
    </div>
  )
};


export default EventsDetails;