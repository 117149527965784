/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useContext, useState } from 'react';
import { motion } from 'framer-motion'; // Import from framer-motion
// Import the images
import avatar from '../../assets/svg/avatar.svg';
import { FaTimes } from "react-icons/fa";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import toast from "react-hot-toast";
import useAuthServices from "../../api/useAuthServices";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import { FullName } from "../../iComponents/FullName";
import { MotionInput } from "../../iComponents/MotionInput";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";
import { IPhoneInput } from "../../iComponents/PhoneInput/PhoneInput";


const confirmationStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
  p: 4,
};

interface FormDataInterface {
  email: string | undefined;
  firstName: string;
  secondName: string;
  thirdName: string;
  lastName: string;
  PhoneNumber: string | undefined;
  country: string;
  image: File | null;
  bio: string;
  SCFHSID: string;
  CurrentPassword: string;
}

export default function EditProfile({ showEditProfile }: any) {
  const { t, direction, isRtl } = useContext(LocaleContext) as LocaleContextProps;
  const { currentUser, fetchProfile } = useContext(UserContext) as UserContextProps;

  const { editProfile } = useAuthServices();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const requiredError = t("This field is required");
  const [image, setImage] = useState<string | null | undefined>(currentUser?.image);

  const [formData, setFormData] = useState<FormDataInterface>({
      email: currentUser?.email,
      firstName: currentUser?.firstName ?? '',
      secondName: currentUser?.secondName ?? '',
      thirdName: currentUser?.thirdName ?? '',
      lastName: currentUser?.lastName ?? '',
      PhoneNumber: currentUser?.mobilephone,
      country: '',
      bio: currentUser?.bio ?? '',
      SCFHSID: currentUser?.scfhsid ?? '',
      image: null,
      CurrentPassword: ''
    }
  );

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

    setValidationErrors({
      ...validationErrors,
      [e.target.name]: e.target.value ? "" : requiredError,
    });
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {

    const file = e.target && e.target.files && e.target.files[0];
    setFormData({
      ...formData,
      image: file
    });
    if (file && file.type.startsWith("image/")) {
      if (typeof FileReader !== "undefined") {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          setImage(fileReader.result as string);
        };

        fileReader.readAsDataURL(file);
      } else {
        setImage(null);
      }

    } else {
      setImage(null);
    }
  };

  const handleEditProfile = (e: any) => {
    e.preventDefault();
    if (currentUser?.nameid && formData.CurrentPassword) {
      editProfile(currentUser?.nameid, formData)
        .then(() => {
          toast.success(t("Profile updated successfully!"))
          fetchProfile();
          showEditProfile(false);
        })
        .catch(() => {
          toast.error(t("Error updating profile"))
        });
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      dir={direction}>
      <div className="title-myprofile">
        <h3>{t('My Profile :')}</h3>
        <a onClick={showEditProfile} className="edit-link" style={{ cursor: "pointer" }}>
          <FaTimes/> {t('Cancel')}
        </a>
      </div>
      <div className="form-edit-profile">
        <form>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="img-edit-profile">
              <img loading="lazy" src={image ?? avatar} alt=""/>
              <div className="chenge-img">
                <input type="file" id="chenge1" name="chenge-img" onChange={handleImageChange}/>
                <label htmlFor="chenge1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={22}
                    height={22}
                    fill="currentColor"
                    className="bi bi-camera-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                    <path
                      d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/>
                  </svg>
                </label>
              </div>
            </div>
          </div>

          <FullName showAllNames form={formData} className="row input-form" handleChange={handleChange}
                    validationErrors={{}}/>

          <MotionInput
            form={formData}
            type="text"
            name="bio"
            placeholder={t("Bio")}
            handleChange={handleChange}
            validationErrors={validationErrors}
            className="row input-form"
          />

          <MotionInput
            form={formData}
            type="email"
            name="email"
            placeholder={t("EmailPlaceholder")}
            handleChange={handleChange}
            validationErrors={validationErrors}
            className="row input-form"
          />

          <IPhoneInput form={formData} name="PhoneNumber" handleChange={handleChange}
                       placeholder={t("MobileNoPlaceholder")} validationErrors={validationErrors}
                       direction={direction} className="row input-form"
          />

          <MotionInput
            form={formData}
            type="text"
            name="SCFHSID"
            placeholder={t("SCFHS ID")}
            handleChange={handleChange}
            validationErrors={validationErrors}
            className="row input-form"
          />
          {/*<MotionInput*/}
          {/*    form = {formData}*/}
          {/*    name = "PhoneNumber"*/}
          {/*    placeholder={t('MobileNoPlaceholder')}*/}
          {/*    handleChange = {handleChange}*/}
          {/*    validationErrors = {validationErrors}*/}
          {/*    className="row input-form"*/}
          {/*/>*/}

          {/*<div className="form-group row input-form">*/}
          {/*    <label className="col-sm-2 col-form-label" htmlFor="country">*/}
          {/*        {t('CountryPlaceholder')} :*/}
          {/*    </label>*/}
          {/*    <div className="col-sm-10">*/}
          {/*    <input*/}
          {/*        type="tel"*/}
          {/*        name="country"*/}
          {/*        value={formData.country}*/}
          {/*        onChange={handleChange}*/}
          {/*        className="form-control"*/}
          {/*        placeholder={t('CountryPlaceholder')}*/}
          {/*    />*/}
          {/*    </div>*/}
          {/*</div>*/}

          <div className="form-group row">
            <div className="btn-edit-profile">
              <button className={`ctm-btn2 float-${isRtl ? 'start' : 'end'}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenConfirmation(true)
                      }}>{t('Save')}</button>
            </div>
          </div>
        </form>
      </div>

      <Modal
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        dir={direction}
      >
        <Box sx={confirmationStyle}>

          <div
            className="animate__animated animate__fadeIn"
            id="smForm">

            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: "10px" }}>
              {t('Please type your current password')}
            </Typography>

            <MotionInput
              form={formData}
              name="CurrentPassword"
              type="password"
              placeholder={t('Current Password')}
              handleChange={handleChange}
              validationErrors={validationErrors}
              className="row input-form"
            />
            <br/>
            <div className="form-group row" style={{ textAlign: "center" }}>
              <div className="btn-edit-profile">
                <button className={`ctm-btn2`} onClick={handleEditProfile}>{t('Confirm')}</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

    </motion.div>
  );
}
