import React, { useContext, useState } from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { Speaker } from "../../components/SpeakersSection/Speaker";
import CloseIcon from "@mui/icons-material/Close";
import FallbackImage from "../../assets/images/imagefallback.png";

export default function EventSpeakers({ speakers }: any) {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const [showSpeakers, setShowSpeakers] = useState(false);

  return (
    <>
      <div className="speakers-wrap" onClick={(e) => {
        e.stopPropagation();
        setShowSpeakers(true);
      }} style={{ cursor: 'pointer'}}>
        {speakers?.map((speaker: any, index: any) => {
          if (index < 2) {
            // render only two
            return (
              <div className="speakers-icon" key={index}>
                <img
                  src={speaker.profilePicture?.fullUrl || FallbackImage}
                  alt={speaker.firstName}
                />
              </div>
            );
          } else if (index === 2) {
            // Render a counter div for the third speaker
            return (
              <div className="speakers-icon speakers-counter" key={index}>
                +{speakers.length - 2}
              </div>
            );
          } else {
            // Render nothing for speakers beyond the first three
            return null;
          }
        })}
      </div>

      <Modal
        open={showSpeakers}
        onClose={() => setShowSpeakers(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        dir={direction}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="modal-container" dir={direction}>
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: "15px",
            }}
          >
            <div
              className="row"
              style={{
                maxWidth: "1500px",
                maxHeight:"500px",
                padding: "20px",
                display: "flex",
                justifyContent: "center",
                overflowY:"auto"
              }}
            >
              <div
                className="space-between"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{ marginBottom: "10px" }}
                >
                  <h2>{t("meetTheSpeakers")}</h2>
                </Typography>

                <IconButton onClick={() => setShowSpeakers(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div>
                <div className="row">
                  {speakers?.map((speaker: any) => (
                    <Speaker key={speaker.id} speaker={speaker} />
                  ))}
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Modal>
    </>
  );
}
