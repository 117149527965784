// notificationsData.ts
export interface Notification {
    id: number;
    content: string;
  }
  
  const notificationsData: Notification[] = [
    { id: 1, content: 'This is a notification' },
    { id: 2, content: 'This is a notification' },
    { id: 3, content: 'This is a notification' },
  ];
  
  export default notificationsData;
  