import p1 from "../ProductsSection/assets/p1.png";
import p2 from "../ProductsSection/assets/p2.png";
import p3 from "../ProductsSection/assets/p3.png";

export const coursesData = [
    {
        id: "0",
        category: "web",
        title: "Anterior Direct Esthetic Restorations",
        description:
            "It is a long established fact that a reader will be distracted by the readable content...",
        duration: "10 Weeks",
        instructor: "Dr. Baraa",
        completed: true,
        paymentRequired: false,
        price: false,
        image: p1
    },

    {
        id: "1",
        category: "BackEnd",
        title: "Posterior Composite Restorations",
        description:
            "It is a long established fact that a reader will be distracted by the readable content...",
        duration: "10 Weeks",
        instructor: "Dr. Wedaad",
        completed: false,
        paymentRequired: false,
        price: false,
        image: p2
    },

    {
        id: "2",
        category: "Android ",
        title: "Implant-Supported Prosthodontics",
        description:
            "It is a long established fact that a reader will be distracted by the readable content...",
        duration: "10 Weeks",
        instructor: "Dr. Fahad",
        completed: false,
        paymentRequired: true,
        price: "100 SAR",
        image: p3
    },
];
