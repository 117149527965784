import {Alert} from "@mui/material";
import React, {useContext} from "react";
import {LocaleContext, LocaleContextProps} from "../../contexts/LocaleContext";
import "./styles.css";

export const NoData = ({msg}: any) => {

    const {t} = useContext(LocaleContext) as LocaleContextProps;

    return (
        <div>
            <Alert style={{width: "100%",
                display: "flex",
                justifyContent: "center"}} color="info">
                <h4>{" "} {t(msg)}</h4></Alert>
        </div>
    )
}


export default NoData;