import AboutUs from "../pages/AboutUs/AboutUs";
import News from "../pages/News/News";
import AchievementsDetails from "../pages/AchievementsDetails/AchievementsDetails";
import Authors from "../pages/Authors/Authors";
import AuthorsDetails from "../pages/AuthorsDetails/AuthorsDetails";
import Booking from "../pages/Booking/Booking";
import Cart from "../pages/Cart/Cart";
import ChangeLanguage from "../pages/ChangeLanguage/ChangeLanguage";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import ConfirmPayment from "../pages/ConfirmPayment/ConfirmPayment";
import ContactUs from "../pages/ContactUs/ContactUs";
import CourseVideo from "../pages/CourseVideo/CourseVideo";
import EditProfile from "../pages/EditProfile/EditProfile";
import Events from "../pages/Events/Events";
import Home from "../pages/Home/Home";
import Memberships from "../pages/Memberships/Memberships";
import Cases from "../pages/Cases/Cases";
import CasesDetails from "../pages/CasesDetails/CasesDetails";
import Orders from "../pages/Orders/Orders";
import OrdersDetails from "../pages/OrdersDetails/OrdersDetails";
import Partners from "../pages/Partners/Partners";
import Payment from "../pages/Payment/Payment";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import ProductDetails from "../pages/ProductDetails/ProductDetails";
import Products from "../pages/Products/Products";
import ProfileCourses from "../pages/ProfileCourses/ProfileCourses";
import Settings from "../pages/Settings/Settings";
import Speakers from "../pages/Speakers/Speakers";
import Terms from "../pages/Terms/Terms";

import { Outlet } from "react-router";
import React from "react";
import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";
import ForgetPasssword from "../pages/ForgetPasssword/ForgetPasssword";
import Verification from "../pages/Verification/Verification";
import LoginChangePassword from "../pages/LoginChangePassword/LoginChangePassword";
import MyMemberships from "../pages/Memberships/MyMemberships";
import MyCases from "../pages/MyCases/Cases";
import MySessions from "../pages/MySessions/MySessions";
import MyTickets from "../pages/MyTickets/MyTickets";
import PaymentStatus from "src/pages/PaymentStatus";
import AlreadyLoginPage from "src/pages/AlreadyLoginPage";
import EventsDetails from "src/pages/EventsDetails";
import RedirectToConfirmation from "../pages/ConfirmPayment/RedirectToConfirmation";
import VariableContent from "../pages/VariableContent/VariableContent";


interface RoutesOutletProps {
  Layout: React.ComponentType<any>;
}

export function RoutesOutlet({ Layout }: RoutesOutletProps) {
  return (
    <Layout>
      <Outlet/>
    </Layout>
  );
}

export const accountRoutes = [
  { path: "/MyAccount", element: <Settings/> },
  { path: "/EditProfile", element: <EditProfile/> },
  { path: "/ChangePassword", element: <ChangePassword/> },
  { path: "/ChangeLanguage", element: <ChangeLanguage/> },
  { path: "/mySessions", element: <MySessions/> },
  { path: "/myTickets", element: <MyTickets/> },
  { path: "/myorders", element: <Orders/> },
  { path: "/myorders/OrdersDetails/:id", element: <OrdersDetails/> },
  { path: "/Settings", element: <Settings/> },
  { path: "/myCourses", element: <ProfileCourses/> },
  { path: "/myMemberships", element: <MyMemberships/> },
  { path: "/myCases", element: <MyCases/> }
];

const routes = (isAuthenticated: any) => {
  return (
    [
      { path: "/", element: <Home/> },
      { path: "/AboutUs", element: <AboutUs/> },
      { path: "/news", element: <News/> },
      { path: "/newsDetails", element: <AchievementsDetails/> },
      { path: "/newsDetails/:id", element: <AchievementsDetails/> },
      { path: "/Authors", element: <Authors/> },
      { path: "/AuthorsDetails", element: <AuthorsDetails/> },
      { path: "/Booking", element: <Booking/> },
      { path: "/Cart", element: <Cart/> },
    
      { path: "/ConfirmPayment", element: <ConfirmPayment/> },
      { path: "/RedirectConfirmPayment", element: <RedirectToConfirmation/> },
      { path: "/ContactUs", element: <ContactUs/> },
      { path: "/CourseVideo", element: <CourseVideo/> },
      { path: "/Schedule", element: <Events/> },
      { path: "/Events/:id", element: <EventsDetails/> },
      { path: "/paymentstatus", element: <PaymentStatus/> },
    
      { path: "/cases", element: <Cases/> },
      { path: "/casesDetails", element: <CasesDetails/> },
      { path: "/casesDetails/:id", element: <CasesDetails/> },
    
      { path: "/Partners", element: <Partners/> },
      { path: "/Payment", element: <Payment/> },
      { path: "/PrivacyPolicy", element: <PrivacyPolicy/> },
      { path: "/ProductDetails/:id", element: <ProductDetails/> },
      { path: "/ProductDetails", element: <ProductDetails/> },
      { path: "/Products", element: <Products/> },
      { path: "/Memberships", element: <Memberships/> },
    
      { path: "/Speakers", element: <Speakers/> },
      { path: "/Terms", element: <Terms/> },
      { path: "/page/:name", element: <VariableContent/> },

      { path: "/Login", element: isAuthenticated ? <AlreadyLoginPage /> : <Login/> },
      { path: "/Signup", element: isAuthenticated ? <AlreadyLoginPage /> : <Signup/> },
      { path: "/ForgetPasssword", element: isAuthenticated ? <AlreadyLoginPage /> : <ForgetPasssword/> },
      { path: "/Verification", element: isAuthenticated ? <AlreadyLoginPage /> : <Verification/> },
      { path: "/LoginChangePassword", element: isAuthenticated ? <AlreadyLoginPage /> : <LoginChangePassword/> },
    ]
  )
};

export default routes;