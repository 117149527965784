import React, { useContext } from "react";
import { useNavigate } from "react-router";

import { IProductByCategory } from "../../api/ems/schemas";
import "./ProductSpeaker.css";
import { FaCartPlus } from "react-icons/fa";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { CartContext } from "../../contexts/CartContext";
import FallbackImage from "../../assets/images/imagefallback.png";
import EventSpeakers from "src/pages/Events/EventSpeakers";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";

interface ProductProps {
  product?: IProductByCategory;
  index: number;
  textDirection?: any;
  showAddToCart?: boolean;
}

const Product = ({ product, textDirection, showAddToCart }: ProductProps) => {
  const { t } = useContext(LocaleContext) as LocaleContextProps;
  const navigate = useNavigate();

  const { addToCart } = useContext(CartContext);
  const handleAddToCart = (product: any) => {
    addToCart({
      id: product ? product.id : 0,
      name: product ? product.name : "Product Name",
      count: 1,
      image: product ? product?.media?.fullUrl : null,
      totalPrice: product ? product.price?.totalPrice : 0,
      selectedCheckedInDate: null,
      selectedInstructorId: null,
      selectedLocationId: null,
      selectedAddonsIds: [],
      addons: [],
      speakers: [],
      locations: [],
      maxQTYPerOrder: product.maxQTYPerOrder,
      minQTYPerOrder: product.minQTYPerOrder,
      price: product.price,
    });
  };
  const isProductFree = product?.price?.amount === 0;

  const renderMedia=()=>{
    if(product?.directLink){
      return <video height={340} width={360} src={product?.directLink}/>
    }else if(product?.youtubeLink){
      let videoId = product.youtubeLink.split('v=')[1];
      const ampersandPosition = videoId?.indexOf('&');
      if(ampersandPosition && ampersandPosition != -1) {
        videoId = videoId.substring(0, ampersandPosition);
      }
      return <YouTube opts={{height: '340', width: '360',}} videoId={videoId} />
    } else if(product?.vimeoLink){
      const firstPart =  product.vimeoLink.split('?')[0].split("/")
      const videoId = firstPart.pop()
      return <Vimeo height={340} width={360} video={videoId??''}/>
    }else {
      return <img
        loading="lazy"
        src={product?.media?.fullUrl || FallbackImage}
        alt={product?.name}
        style={{ backgroundColor: product?.media?.fullUrl ? 'unset' : '#f5f5f5' }}
      />
    }
  }

  return (
    <div className="col-lg-3 col-md-6 my-2" style={{height:"400px"}}>
      

      <div className="product-card">
        <div style={product?.media?.fullUrl && (!product?.directLink && !product?.youtubeLink && !product?.vimeoLink) ? {
          backgroundImage: `url(${product?.media?.fullUrl})`,
        }:{}} className="product-card-image cursor-pointer" onClick={() => navigate(`/ProductDetails/${product?.id}`)}>
          {renderMedia()}
        </div>
        <div className="product-card-data">
          <div className="product-card-data-top">
            <div className="product-card-title-wrap">
              <h2 data-toggle="tooltip" data-placement="top" title={product?.name} className="product-card-title" onClick={() => navigate(`/ProductDetails/${product?.id}`)}>{product?.name}</h2>

            </div>

            <div
              className="product-card-price"
              style={{
                marginTop: "4px"
              }}
            >
              <div className="result-item-price">
                {product && product?.price?.discount > 0 && (
                  <span className="prev-price">
                    {product.price?.displayTotalPriceWithoutDiscount}
                  </span>
                )}

                <span className="current-price">
                  {isProductFree
                    ? t("FREE")
                    : product?.price?.displayTotalPrice}
                </span>
              </div>
              {product && product?.speakers?.length > 0 && (
                <div className="">
                  <EventSpeakers speakers={product?.speakers} />
                </div>
              )}
            </div>
          </div>
          {!showAddToCart ? (
            <div className="product-card-data-bot">
              <button
                className="add-to-cart-btn mt-2"
                onClick={() => handleAddToCart(product)}
              >
                <FaCartPlus /> {t("Add To Cart")}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Product;
