import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import useContentApi from "../../api/contentApi";
import { Container } from "@mui/material";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import NotFoundPage from "../notFoundPage/notFoundPage";
import { AiOutlineLoading } from "react-icons/ai";

const VariableContent = () => {

  const { name } = useParams()
  const { isRtl,direction } = useContext(LocaleContext) as LocaleContextProps;
  const { loadContent, loadContentTypes } = useContentApi();
  const [content, setContent] = useState<any>()
  const [loading ,setLoading] = useState<boolean>(true)

  useEffect(() => {
    loadContentTypes().then(async (response) => {
      const index = response.findIndex(value => value.name.toUpperCase() === name?.toUpperCase())
      if (index >= 0)
        setLoading(true)
        const result = await loadContent(response[index].id, isRtl)
        setContent(result)

    }).finally(() => setLoading(false))
  }, [name, isRtl])
  
  return (
    <div dir={direction} className="privacy-policy-page">
      {loading ? <AiOutlineLoading /> : content ?<section className={`privacy-policy mr-section p-4 col-10 mx-auto`}>
        <Container maxWidth="xl" className="main-container py-5">
           <div className="variable-content" dangerouslySetInnerHTML={{ __html: content }}/>
        </Container>
      </section>: <NotFoundPage/>}
    </div>
  )

}

export default VariableContent;