import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext, UserContextProps } from "./UserContext";
import useUserCartAPI from "../api/ems/user-cart";
import toast from "react-hot-toast";
import { LocaleContext, LocaleContextProps } from "./LocaleContext";
import { synchronizeCarts } from "src/pages/Cart/utils";
import { currencyFormate } from "src/utils/dateUtils";

interface CartContextProps {
  cartItems: any[];
  setCartItems: (item: any) => void;
  hasBookingItem: boolean;
  addToCart: (product: any) => void;
  removeFromCart: (id: number, count?: number) => void;
  clearCart: () => void;
  coupons: any[];
  setCoupons: (coupons: any) => void;
  addNewCoupon: (coupon: any) => void;
  removeCoupon: (id: number) => void;
  backendPriceDetails: any;
  setBackendPriceDetails: (data: any) => void;

}

export const CartContext = createContext<CartContextProps>({
  cartItems: [],
  setCartItems: () => {},
  hasBookingItem: false,
  addToCart: () => {},
  removeFromCart: (_id: number, _count?: number) => {},
  clearCart: () => {},
  coupons: [],
  addNewCoupon: () => {},
  removeCoupon: (id: number) => {},
  setCoupons: () => {},
  backendPriceDetails: {},
  setBackendPriceDetails: () => {},

});

interface CartProviderProps {
  children: React.ReactNode;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const { t,isRtl } = useContext(LocaleContext) as LocaleContextProps;

  const [coupons, setCoupons] = useState<any[]>(
    localStorage.getItem("addedCoupons")
      ? JSON.parse(localStorage.getItem("addedCoupons") as string)
      : []
  );
  const [cartItems, setCartItems] = useState<any[]>(
    localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems") as string)
      : []
  );
  const [hasBookingItem, setHasBookingItem] = useState(false);
  const [backendPriceDetails, setBackendPriceDetails] = useState<any>({
    subTotal: null,
    totalProductsDiscountsAmount: null,
    coupons: null,
    totalCouponsDiscountsAmount: null,
    totalVatAmount: null,
    totalOtherTaxesAmount: null,
    finalPrice: null,
  });

  const { isAuthenticated } = useContext(UserContext) as UserContextProps;
  const {
    addToUserCart,
    updateToUserCart,
    deleteFromUserCart,
    addCouponToCart,
    loadReadyUserCart,
  } = useUserCartAPI();

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    setHasBookingItem(
      cartItems?.some(
        (item) => item?.categoryId === 4 || item?.addons?.length >= 1
      )
    );
    const addedCoupons = cartItems
      .map((item: any) => {
        if (item.coupon) {
          return {
            cartId: item?.uuid,
            couponCode: item?.coupon?.code || item?.coupon?.couponCode,
            couponDiscountAmount: item.price.couponDiscountAmount,
            couponId: item?.coupon?.id || item?.coupon?.couponId,
          };
        }
        return null;
      })
      .filter(Boolean);

    setCoupons(addedCoupons);
    if(!isAuthenticated) {
      setBackendPriceDetails({
        subTotal: `${currencyFormate(cartItems?.reduce(
          (total, item) => total + item.price.amount * item.count,
          0
        ))} ${t('SAR')}`,        
        totalProductsDiscountsAmount: `${currencyFormate(
          cartItems?.reduce((total, item) => {
            const discountAmount = item.price.isDiscountPercentage
              ? (item.price.discount / 100) * item.price.amount * item.count
              : item.price.discount * item.count;
        
            return total + discountAmount;
          }, 0)
        )} ${t('SAR')}`,
        coupons: null,
        totalCouponsDiscountsAmount: null,
        totalVatAmount: `${currencyFormate(
          cartItems?.reduce((total, item) => total + item.price.vatAmount * item.count, 0)
        )} ${t('SAR')}`,
        totalOtherTaxesAmount: null,
        finalPrice: `${currencyFormate(
          cartItems?.reduce((total, item) => total + item.price.totalPrice * item.count, 0)
        )} ${t('SAR')}`,
      })
    };
  }, [cartItems, isAuthenticated,isRtl]);

  useEffect(() => {
    localStorage.setItem("addedCoupons", JSON.stringify(coupons));
  }, [coupons,isRtl]);

  const addToCart = (product: any) => {
    const existingItem = cartItems.find((item) => item.id === product.id);

    if (isAuthenticated) {

      if (existingItem) {

        const updatedItem = { ...existingItem, count: existingItem.count + 1 };
        if (existingItem.uuid) {
          updateToUserCart({
            uuid: existingItem.uuid,
            productId: product.id,
            qty: updatedItem.count,
            couponCode: existingItem.coupon?.code,
          })
            .then((_res) => {
              setCartItems((prevItems) => {
                return prevItems.map((item) =>
                  item.id === product.id ? updatedItem : { ...item }
                );
              });
              reloadPriceDetails();
              toast.success(t("Product added successfully to cart"));
            })
            .catch((error: any) => {
              toast.error(
                error?.response?.data?.detail || t("something went wrong")
              );
            });
        }
      } else {
        addToUserCart({
          productId: product.id,
          qty: product.count,
          membershipDurationId: product.membershipDurationId || null,
        })
          .then((uuid) => {
            setCartItems([...cartItems, { ...product, uuid: uuid }]);
            reloadPriceDetails();
            toast.success(t("Product added successfully to cart"));
          })
          .catch((error: any) => {
            toast.error(
              error?.response?.data?.detail || t("something went wrong")
            );
          });
      }

      
    } else {
      if (existingItem) {
        const updatedItem = { ...existingItem, count: existingItem.count + 1 };
        if (existingItem.uuid) {
          if (existingItem.count === product.maxQTYPerOrder) {
            toast.error(t("reached max quantity"));
          } else {
            setCartItems((prevItems) => {
              return prevItems.map((item) =>
                item.id === product.id ? updatedItem : { ...item }
              );
            });
            toast.success(t("Product added successfully to cart"));
          }
        }
      } else {
        setCartItems([
          ...cartItems,
          { ...product, uuid: new Date().toISOString() },
        ]);
        toast.success(t("Product added successfully to cart"));
      }
    }
  };

  const removeFromCart = (id: number, count?: number) => {
    const existingItem = cartItems.find((item) => item.id === id);

    if (isAuthenticated) {
      if (existingItem) {
        if (count && existingItem.count > 1) {
          const updatedItem = {
            ...existingItem,
            count: existingItem.count - 1,
          };
          updateToUserCart({
            uuid: existingItem.uuid,
            productId: existingItem.id,
            qty: updatedItem.count,
            couponCode: existingItem.coupon?.code
          })
            .then((_res) => {
              const updatedCartItems = cartItems.map((item) =>
                item.id === id ? updatedItem : item
              );
              setCartItems(updatedCartItems);
              reloadPriceDetails();
            })
            .catch((err) => {
              toast.error(
                err?.response?.data?.detail || t("something went wrong")
              );
            });
        } else {
          existingItem.uuid &&
            deleteFromUserCart(existingItem.uuid)
              .then(() => {
                const updatedCartItems = cartItems.filter(
                  (item) => item.id !== id
                );
                setCartItems(updatedCartItems);
                reloadPriceDetails();
              })
              .catch((err) => console.error(err));
        }
      }
    } else {
      if (existingItem) {
        if (count && existingItem.count > 1) {
          const updatedItem = {
            ...existingItem,
            count: existingItem.count - 1,
          };

          const updatedCartItems = cartItems.map((item) =>
            item.id === id ? updatedItem : item
          );
          setCartItems(updatedCartItems);
        } else {
          const updatedCartItems = cartItems.filter((item) => item.id !== id);
          setCartItems(updatedCartItems);
        }
      }
    }
  };

  const clearCart = () => {
    setCoupons([]);
    setCartItems([]);
    if (isAuthenticated) {
      reloadPriceDetails();

    }
    
  };

  const addNewCoupon = (coupon: any) => {
    addCouponToCart({ couponCode: coupon })
      .then((response: any) => {
        const { cartId } = response || {};
        const foundedCartItem = cartItems.find(
          (item: any) => item.uuid === cartId
        );
        if (foundedCartItem.coupon) {
          if (foundedCartItem.coupon.id === response.couponId) {
            toast.success(
              `${foundedCartItem.name} has already the same coupon.`
            );
          } else {
            toast.success(
              `We found a coupon at ${foundedCartItem.name}, coupon has been replaced with the new one successfully.`
            );
          }
        } else {
          toast.success(
            `Coupon has been placed to ${foundedCartItem.name} successfully.`
          );
        }

        loadReadyUserCart().then((response) => {
          synchronizeCarts({
            backendCart: response,
            cartItems,
            setCartItems,
            addToUserCart,
            updateToUserCart,
            deleteFromUserCart,
            setBackendPriceDetails,
            loadReadyUserCart,
          }).catch((error) => console.error("ERROR", error));
        });
      })
      .catch((error: any) =>
        toast.error(error?.response?.data?.detail || t("something went wrong"))
      );
  };

  const removeCoupon = (coupon: any) => {
    const existingItem = cartItems.find((item) => item.uuid === coupon.cartId);
    updateToUserCart({
      productId: existingItem?.id,
      qty: existingItem?.count,
      uuid: existingItem?.uuid,
      couponCode: existingItem.coupon?.code
    })
      .then(() => {
        loadReadyUserCart()
          .then((response) => {
            synchronizeCarts({
              backendCart: response,
              cartItems,
              setCartItems,
              addToUserCart,
              updateToUserCart,
              deleteFromUserCart,
              setBackendPriceDetails,
              loadReadyUserCart,
            });
          })
          .catch((error) => console.error("ERROR", error));

        toast.success(t("coupon removed"));
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.detail || t("something went wrong"));
      });
  };

  const reloadPriceDetails = () => {
    loadReadyUserCart().then((response: any) => {
      setBackendPriceDetails({
        subTotal: response.subTotal,
        totalProductsDiscountsAmount: response.totalProductsDiscountsAmount,
        coupons: response.coupons,
        totalCouponsDiscountsAmount: response.totalCouponsDiscountsAmount,
        totalVatAmount: response.totalVatAmount,
        totalOtherTaxesAmount: response.totalOtherTaxesAmount,
        finalPrice: response.finalPrice,
      })
    }).catch((error: any) =>
    toast.error(error?.response?.data?.detail || t("something went wrong"))
  );
  }

  return (
    <>
      <CartContext.Provider
        value={{
          cartItems,
          setCartItems,
          hasBookingItem,
          addToCart,
          removeFromCart,
          clearCart,
          coupons,
          addNewCoupon,
          removeCoupon,
          setCoupons,
          backendPriceDetails,
          setBackendPriceDetails,
        }}
      >
        {children}
      </CartContext.Provider>
    </>
  );
};
