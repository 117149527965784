/* eslint-disable no-dupe-keys */
import React, {useContext, useState} from 'react';
import {NavLink} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {ListItem, ListItemIcon, Tooltip, useMediaQuery} from "@mui/material";
import "./sidebarMyaccount.css";
import {LocaleContext} from "../../contexts/LocaleContext";
import {UserContext} from "../../contexts/UserContext";
import QrCodeIcon from '@mui/icons-material/QrCode';
import QRCode from "react-qr-code";

import CardMembershipIcon from '@mui/icons-material/CardMembership';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArticleIcon from '@mui/icons-material/Article';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import LogoutIcon from '@mui/icons-material/Logout';

export default function SidebarMyaccount() {

    const {t, direction, isRtl} = useContext(LocaleContext);
    const {currentUser, handleLogout: handleLogoutGlobal} = useContext(UserContext);


    const [logoutModalOpen, setLogoutModalOpen] = useState(false);

    const [showQrCode, setShowQrCode] = useState(false);

    const isSmallDevice = useMediaQuery('(max-width: 992px)');
    const isMobile = useMediaQuery('(max-width: 450px)');

    const handleLogout = () => {
        setLogoutModalOpen(true);
    };

    const handleLogoutConfirmation = () => {
        handleLogoutGlobal();
        setLogoutModalOpen(false);
    };


    return (
        <Box
            dir={direction}
            id="SidebarMyaccount"
            sx={{
                width: '100%',
                height: '100%',
                minHeight: '600px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                background: '#ebf6ff',
                borderRadius: '8px'
            }}
        >
            <List>
                {isSmallDevice ?
                    <>
                        <ListItem
                            component={NavLink}
                            className={"profile-item"}
                            activeClassName="active"
                            to="/MyAccount">
                            {/*<ListItemButton to="/MyAccount">*/}
                            <ListItemIcon>
                                <div data-testid="svg-usericon" className="css-xsh90p">{
                                    currentUser && currentUser.image ?
                                        <img src={currentUser?.image} alt="User Profile"/> :
                                        <svg aria-hidden="true" fill="none" focusable="false" height="36"
                                             viewBox="0 0 24 24"
                                             width="36" className="css-amu4db" id="cds-react-aria-2">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12.034 2.5a4.467 4.467 0 10.002 8.934 4.467 4.467 0 00-.002-8.934zM6.568 6.966a5.467 5.467 0 1110.934.002 5.467 5.467 0 01-10.934-.002zM15.868 13.348a.5.5 0 01.386-.048c4.093 1.17 6.246 4.961 6.246 8.7h-1c0-3.323-1.87-6.593-5.324-7.68a8.845 8.845 0 01-4.162 1.057h-.004a8.93 8.93 0 01-4.185-1.056C4.39 15.408 2.5 18.679 2.5 22h-1c0-3.74 2.178-7.53 6.245-8.7a.5.5 0 01.386.047 7.916 7.916 0 003.881 1.03 7.834 7.834 0 003.856-1.03z"
                                                  fill="currentColor"></path>
                                        </svg>}
                                </div>
                            </ListItemIcon>
                            {/*</ListItemButton>*/}
                        </ListItem>
                        <ListItem component="div" style={{backgroundColor: "#EBF6FF"}} className={"profile-item"}>
                            <Tooltip title={t("QR Code")}>
                                <a onClick={() => setShowQrCode(!showQrCode)}>
                                    <IconButton size="large">
                                        <QrCodeIcon/>
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </ListItem>
                    </>
                    :
                    <ListItem component="div" style={{backgroundColor: "#EBF6FF"}}>
                        <ListItemButton className='user-profile-btn' sx={{height: 120}} to="/MyAccount"
                                        component={NavLink}>
                            <Box
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <IconButton>
                                    <div data-testid="svg-usericon" className="css-xsh90p">
                                        {
                                            currentUser && currentUser.image ?
                                                <img src={currentUser?.image} alt="User Profile"/> :
                                                <svg aria-hidden="true" fill="none" focusable="false" height="36"
                                                     viewBox="0 0 24 24"
                                                     width="36" className="css-amu4db" id="cds-react-aria-2">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M12.034 2.5a4.467 4.467 0 10.002 8.934 4.467 4.467 0 00-.002-8.934zM6.568 6.966a5.467 5.467 0 1110.934.002 5.467 5.467 0 01-10.934-.002zM15.868 13.348a.5.5 0 01.386-.048c4.093 1.17 6.246 4.961 6.246 8.7h-1c0-3.323-1.87-6.593-5.324-7.68a8.845 8.845 0 01-4.162 1.057h-.004a8.93 8.93 0 01-4.185-1.056C4.39 15.408 2.5 18.679 2.5 22h-1c0-3.74 2.178-7.53 6.245-8.7a.5.5 0 01.386.047 7.916 7.916 0 003.881 1.03 7.834 7.834 0 003.856-1.03z"
                                                          fill="currentColor"></path>
                                                </svg>
                                        }
                                    </div>
                                </IconButton>
                                <Box>
                                    <Typography variant="h6" dir={direction} style={isRtl ? {
                                        textAlign: 'right',
                                        fontWeight: "bold"
                                    } : {fontWeight: "bold"}}>
                                        {currentUser?.firstName}
                                    </Typography>
                                    <Typography variant="body2" dir={direction}>{currentUser?.email}</Typography>
                                </Box>

                            </Box>

                        </ListItemButton>
                        <Tooltip title={t("QR Code")}>
                            <a onClick={() => setShowQrCode(!showQrCode)}>
                                <IconButton size="large">
                                    <QrCodeIcon/>
                                </IconButton>
                            </a>
                        </Tooltip>
                    </ListItem>
                }
                <ListItem
                    component={NavLink}
                    className={"profile-item"}
                    to="/myMemberships"
                    activeClassName="active"
                >
                    {isSmallDevice ?
                        <ListItemIcon sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px"
                        }}>
                            <CardMembershipIcon/>
                            {isMobile?"":t("Membership")}
                        </ListItemIcon> :
                        <ListItemText primary={t("Membership")}/>
                    }
                </ListItem>
                <ListItem
                    component={NavLink}
                    className={"profile-item"}
                    to="/myCourses"
                    activeClassName="active"
                >
                    {isSmallDevice ?
                        <ListItemIcon sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px"
                        }}>
                            <SubscriptionsIcon/>
                            {isMobile?"":t("Courses")}
                        </ListItemIcon> :
                        <ListItemText primary={t("Courses")}/>
                    }
                </ListItem>
                <ListItem
                    component={NavLink}
                    className={"profile-item"}
                    to="/mySessions"
                    activeClassName="active"
                >
                    {isSmallDevice ?
                        <ListItemIcon sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px"
                        }}>
                            <CalendarMonthIcon/>
                            {isMobile?"":t("Sessions")}
                        </ListItemIcon> :
                        <ListItemText primary={t("Sessions")}/>
                    }
                </ListItem>
                <ListItem
                    component={NavLink}
                    className={"profile-item"}
                    to="/myTickets"
                    activeClassName="active"
                >
                    {isSmallDevice ?
                        <ListItemIcon sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px"
                        }}>
                            <ConfirmationNumberIcon/>
                            {isMobile?"":t("Tickets")}
                        </ListItemIcon> :
                        <ListItemText primary={t("Tickets")}/>
                    }
                </ListItem>
                <ListItem
                    className={"profile-item"}
                    component={NavLink}
                    to="/myorders"
                    activeClassName="active"
                >
                    {isSmallDevice ?
                        <ListItemIcon sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px"
                        }}>
                            <LocalShippingIcon/>
                            {isMobile?"":t("Orders")}
                        </ListItemIcon> :
                        <ListItemText primary={t("Orders")}/>
                    }
                </ListItem>
                <ListItem
                    className={"profile-item"}
                    component={NavLink}
                    to="/myCases"
                    activeClassName="active"
                >
                    {isSmallDevice ?
                        <ListItemIcon sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px"
                        }}>
                            <ArticleIcon/>
                            {isMobile?"":t("casesArticles")}
                        </ListItemIcon> :
                        <ListItemText primary={t("casesArticles")}/>
                    }
                </ListItem>
            </List>

            <Modal
                open={logoutModalOpen}
                onClose={() => setLogoutModalOpen(false)}
                aria-labelledby="logout-modal-title"
                aria-describedby="logout-modal-description"
                style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={{p: 4, maxWidth: 400, mx: 'auto', background: 'white', borderRadius: '8px'}}>
                    <Typography variant="h6" id="logout-modal-title" gutterBottom>

                        {t('Are you sure you want to log out?')}

                    </Typography>
                    <Button onClick={handleLogoutConfirmation} variant="contained" color="primary">
                        {t('Yes, Log out')}

                    </Button>
                    <Button onClick={() => setLogoutModalOpen(false)} variant="outlined" sx={{ml: 2}}>
                        {t('Cancel')}
                    </Button>
                </Box>
            </Modal>


            <Modal
                open={showQrCode}
                onClose={() => setShowQrCode(false)}
                style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={{p: 4, mx: 'auto', mt: 6, background: 'white', borderRadius: '8px'}}>

                    <QRCode value={`${currentUser?.nameid.toString()}, ${currentUser?.email}`}/>
                </Box>
            </Modal>

            <ListItem
                className={"profile-item delete-profile noBorder"}
                style={{cursor: "pointer"}}
                component="a" onClick={handleLogout}>
                {isSmallDevice ?
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon> :
                    <ListItemText primary={t("Log out")}/>
                }
            </ListItem>
        </Box>
    );
}
