import useAxiosWithInterceptor from "../useAxiosWithInterceptor";
import { AxiosResponse } from "axios";

const usePaymentAPI = () => {
  const { axiosInstance } = useAxiosWithInterceptor();

  const getAvailablePaymentGateways = (): Promise<any[]> =>
    axiosInstance
      .get("api/PaymentGateways/GetAvailablePaymentGateways")
      .then((response: AxiosResponse<any[]>) => response.data)
      .catch((error: any) => {
        throw error;
      });

  const readyCartToOrder = async (isTamara=false): Promise<any> => {
    const response = await axiosInstance.post("/api/Orders/ReadyCartToOrder",{isTamaraPaymentSelected:isTamara});
    return response.data;
  };

  const initiateMoyasarCallback = async (payload: any): Promise<any> => {
    const response = await axiosInstance.post(
      "api/Payments/MoyasarCallBack",
      payload
    );
    return response.data;
  };

  return { getAvailablePaymentGateways, readyCartToOrder, initiateMoyasarCallback };
};

export default usePaymentAPI;
