import React, { useEffect } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import "./CartItem.css";
import FallbackImage from "../../assets/images/imagefallback.png";
import { currencyFormate } from "../../utils/dateUtils";

export interface ProductItem {
  uuid?: string;
  id: number;
  name: string;
  price: number;
  displayPrice: string;
  count: number;
  vatAmount?: number;
}

export interface CartItemProps {
  item: any;
  onAdd: (id: any) => void;
  onRemove: (id: number, count?: number) => void;
}

const CartItem: React.FC<CartItemProps> = ({
  item,
  onAdd,
  onRemove,
}) => {
  const handleRemove = (e: any) => {
    e.preventDefault();
    onRemove(item.id);
  };

  const handleDecrement = (e: any) => {
    e.preventDefault();
    onRemove(item.id, 1);
  };

  const handleIncrement = (e: any) => {
    e.preventDefault();
    onAdd(item);
  };
  useEffect(()=>{},[item])


  return (
    <a href={`/ProductDetails/${item.id}`} className="cart-list-item">
      <div className="cart-item-img">
        <img
          loading="lazy"
          src={item.image || FallbackImage}
          alt={item.name}
          style={{
            backgroundColor: item.image ? "unset" : "#f5f5f5",
          }}
        />
      </div>
      <div className="cart-item-content">
        <strong>{item.name}</strong>
        <div className="counter-wrap justify-content-around">
          <div className='d-flex flex-row'>
          <div className="counter-action" onClick={handleDecrement}>
            <RemoveIcon />
          </div>
          <div className="counter-number">{item.count}</div>
          <div className="counter-action" onClick={handleIncrement}>
            <AddIcon />
          </div>
          </div>
          {item.price &&  <div>{currencyFormate(item.price.totalPrice)} {item.price.currencyCode}</div>}
        </div>
      </div>
      <div className="cart-item-remove" onClick={handleRemove}>
        <DeleteIcon />
      </div>
    </a>
  );
};

export default CartItem;
