/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { LocaleContext, LocaleContextProps } from '../../contexts/LocaleContext';
import useEventsAPI from '../../api/ems/events';
import toast from 'react-hot-toast';
import moment from "moment";
import EventsCalendar from "../../iComponents/EventCalendar/EventsCalendar";
import useProductsAPI from "../../api/ems/products";

export default function Events() {
  const { t } = useContext(LocaleContext) as LocaleContextProps;
  const { defaultEventTimeline } = useEventsAPI();
  const { getCoursesTimeline } = useProductsAPI();

  const [items, setItems] = useState<any[]>([])


  const setSessions = (sessions: any[]) => {

    const sortedSession = [...sessions];
    sortedSession.sort((a, b) => moment(a.startDateTime).isAfter(b.startDateTime) ? 1 : -1)
    setItems(sortedSession)

  }

  const fetchItems = async () => {
    const sessionTimeline = await defaultEventTimeline({});
    const coursesTimeLine = await getCoursesTimeline({});
    return [...sessionTimeline,...coursesTimeLine.map(value => ({...value,isCourse:true}))]
  }

  useEffect(() => {

    fetchItems().then(value => {

      setSessions(value);
    }).catch(error => {
      console.error(error)
      toast.error(error.response?.data?.detail ?? "Error while fetching Sessions")
    })

  }, []);




  return (
    <div>
      <div
        className="body_page  d-flex flex-column justify-content-between"

      >
        <section className="events mr-section">
          <Container maxWidth="xl" className="main-container">
            <div className="title-center">
              <h3>{t('Schedule')}</h3>
            </div>
          <EventsCalendar events={items} />
          </Container>
        </section>
      </div>
    </div>
  );
}
