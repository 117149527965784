/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import PaymentOptionComponent from "../PaymentOptionComponent/PaymentOptionComponent";
import pu1 from "./assets/pu1.png";
import pu2 from "./assets/pu2.png";
import pu3 from "./assets/pu3.png";
import pu7 from "./assets/Stc_pay.svg.png";
import pu4 from "./assets/pu4.png";
import amex from "./assets/pu6.png";
import pu5 from "./assets/tamara.png";
import Modal from "@mui/material/Modal";
import { PaymentModal } from "./PaymentModal";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import LoginSM from "../../pages/LoginSM/LoginSM";

import "./PaymentMethod.css";
import { CartSummary } from "../CartSummary/CartSummary";
import usePaymentAPI from "../../api/ems/payment";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { CartContext } from "../../contexts/CartContext";

interface PaymentOptionProps {
  label: string;
  image: string;
  selected: boolean;
  handleOpen: () => void;
  setSelectedOption: React.Dispatch<React.SetStateAction<string | null>>;
}

interface PaymentOption {
  value: string;
  label: string;
  image?: string;
  images?: string[];
  publishKey?: string;
}

const PaymentMethod: React.FC = () => {
  const isMobile = window.innerWidth <= 768;
  const [selectedOption, setSelectedOption] = useState<any | null>(null);
  const [openPayment, setOpenPayment] = useState(false);
  const { isAuthenticated,currentUser } = useContext(UserContext) as UserContextProps;
  const { backendPriceDetails,clearCart } = useContext(CartContext);
  const finalPrice = Number.parseInt(backendPriceDetails.finalPrice?.split(" ")[0])

  const { t, direction ,lang } = useContext(LocaleContext) as LocaleContextProps;
  const [openAuth, setOpenAuth] = useState(!isAuthenticated);
  const navigation = useNavigate()


  const { getAvailablePaymentGateways, readyCartToOrder } = usePaymentAPI();
  const [paymentGateways, setPaymentGateways] = useState<any>(null);
  const [selectedMethod, setSelectdMethod] = useState<any>(null);

  const redirectEnvs = {
    local: 'http://localhost:3001/RedirectConfirmPayment',
    prod: `${window.location.origin}/RedirectConfirmPayment`,
  };

  const seperatedPaymentOptions: PaymentOption[] = [
    {
      value: "creditcard",
      label: t("creditcard"),
      images: [pu2, pu1, pu4, amex],
    },
    { value: "stcpay", label: t("stcPayLabel"), image: pu7 },
    { value: "applepay", label: t("applePayLabel"), image: pu3 },
    { value: "tamara", label: t("tamaraLabel"), image: pu5 },
    // { value: "tabby", label: t("tabbyLabel"), image: pu6 },
  ];

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    PaymentOption[]
  >([]);

  const [cartInfo, setCartInfo] = useState<{loading:boolean,cartData:any}>({
    loading: false,
    cartData: undefined,
  });

  const handleOpen = () => {
    //setOpen(true);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.moyasar.com/mpf/1.10.0/moyasar.js";
    script.async = true;

    document.body.appendChild(script);

    getAvailablePaymentGateways()
      .then((paygateways) => {
        let pms: PaymentOption[] = [];
        paygateways.forEach((pg) => {
          if (pg.paymentGatewayName === "Moyasar") {
            let images: any[] = [];
            if (pg.methods) {
              pg.methods.forEach((pm: any) => {
                const paymentMethodName = pm.name;
                if (
                  ["Visa", "Master Card", "American Express", "Mada"].includes(
                    paymentMethodName
                  )
                ) {
                  images.push(pm.imageUrl);
                } else {
                  if (paymentMethodName === "Apple Pay") {
                    pms.push({
                      value: "applepay",
                      label: t("applePayLabel"),
                      image: pm.imageUrl,
                      publishKey: pg.publishableKey.publishKey,
                    });
                  } else if (paymentMethodName === "STC Pay") {
                    pms.push({
                      value: "stcpay",
                      label: t("stcPayLabel"),
                      image: pm.imageUrl,
                      publishKey: pg.publishableKey.publishKey,
                    });
                  } else
                    pms.push({
                      value: paymentMethodName,
                      label: paymentMethodName,
                      image: pm.imageUrl,
                      publishKey: pg.publishableKey.publishKey,
                    });
                }
              });
            }
            pms.push({
              value: "creditcard",
              label: t("creditcard"),
              images: images,
              publishKey: pg.publishableKey.publishKey,
            });
          } else {
            pms.push({
              value: pg.paymentGatewayName,
              label: pg.paymentGatewayName,
              image: pg.imageUrl,
              publishKey: pg.publishableKey.publishKey,
            });
          }
        });

        const hideApplePayIfMobile = pms.filter(
          (item) => item.value !== "applepay"
        );
        setAvailablePaymentMethods(isMobile ? pms : hideApplePayIfMobile);
        setPaymentGateways([...paygateways]);
      })
      .catch((err) => {});

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleConfirmAndPay = async () => {
    if (!isAuthenticated) {
      setOpenAuth(true);
    } else {

      setCartInfo(prevState => ({ ...prevState,loading: true }));
      if(!!selectedMethod && selectedMethod.value !== 'Tamara' && cartInfo.cartData){
        setCartInfo(prevState => ({ ...prevState,loading: false }));
        setOpenPayment(true);
      }else{
      readyCartToOrder(!!selectedMethod && selectedMethod.value === 'Tamara')
        .then(response => {

          if(response.redirectURL){
            navigation(response.redirectURL);
          }else if(selectedMethod) {
            setCartInfo({ loading: false, cartData: response });
            setOpenPayment(true);
          }else{
              if(response.status.name==="Paid"){
                clearCart();
                navigation('/ConfirmPayment',{state:{
                    id:response.code,
                    status:response.status.name,
                    message:"Order confirmed",
                  }})
              }
          }
        })
        .catch((error: any) => {
          toast.error(error?.response?.data?.detail || t("something went wrong"));
          setCartInfo({ loading: false,cartData: undefined });
        });
      }
    }
  };

  return (
    <div dir={direction}>
      <Modal
        open={openPayment}
        onClose={() => setOpenPayment(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PaymentModal
          paymentGateways={paymentGateways}
          selectedMethod={selectedMethod}
          open={openPayment}
          option={selectedOption}
          handleClose={() => setOpenPayment(false)}
          cartData={cartInfo.cartData}
        />
      </Modal>

      <div className="main-payment-page">
        <div className="row justify-content-center">
          {finalPrice !== 0 && <div className="col-lg-7">
            <div className="select-payment-method">
              {t("Select Payment Method")}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "auto",
                }}
              ></div>
              <br/>
              <form>
                {availablePaymentMethods.map((option) => (
                  <PaymentOptionComponent
                    showLabel={true}
                    direction={direction}
                    value={option.value}
                    key={option.label}
                    label={option.label}
                    image={option.image}
                    images={option.images}
                    selected={selectedOption === option.value}
                    handleOpen={handleOpen}
                    setSelectedOption={setSelectedOption}
                    handleMethodSelection={() => {
                      setSelectdMethod(option);
                      setSelectedOption(option.value);
                    }}
                  />
                ))}
              </form>
            </div>
          </div>}
          <div className="col-lg-5">
            <CartSummary
              disableConfirm={(finalPrice !== 0 && selectedOption == null) || cartInfo.loading}
              confirmMsg={finalPrice === 0 ?"Confirm":"Confirm and Pay"}
              handleConfirm={handleConfirmAndPay}
              isLoading={cartInfo.loading}
            />
          </div>
        </div>
      </div>

      <Modal
        open={openAuth}
        aria-labelledby="login-modal"
        id="loginModal"
      >
        <div onClick={(e) => e.stopPropagation()}>
          <LoginSM returnUrl="/Payment" closeModal={() => setOpenAuth(false)} />
        </div>
      </Modal>
    </div>
  );
};

export default PaymentMethod;
