import "animate.css";
import React, { useContext, useEffect, useState } from "react";

import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { motion } from "framer-motion";
import useOrdersAPI from "../../api/ems/orders";
import { currencyFormate, formatSimpleDate } from "../../utils/dateUtils";
import { useNavigate } from "react-router";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import printJs from "print-js";
import NoData from "src/iComponents/NoData/NoData";

interface StatusToClassMapping {
  [key: string]: string;
}

const statusToClassMap: StatusToClassMapping = {
  "Refund Request": "onpre-order",
  Paid: "payment-accepted",
  Canceled: "payment-error",
  Pending: "preparing-order",
  Refunded: "delivered",
};

const OrderRow = ({ order, lang }: any) => {
  const navigate = useNavigate();
  const { printOrderInvoice } = useOrdersAPI();
  const { t } = useContext(
    LocaleContext
  ) as LocaleContextProps;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrint = (invoiceId: number) => {
    printOrderInvoice(invoiceId).then((response: any) => {
      printJs({ printable: response, type: 'raw-html' })
    });
  };

  // const handleInvoicePrint = () => {
  //   console.log(order);
  //   printOrderInvoice(order.total)
  //     .then((response: any) => console.log(response))
  //     .catch((error: any) => console.log(error));
  // };

  return (
    <tr className="orders-list" key={order.id}>
      <td onClick={() => navigate(`/myorders/ordersDetails/${order.code}`)}>
        {order.id}
      </td>
      <td onClick={() => navigate(`/myorders/ordersDetails/${order.code}`)}>
        {`${currencyFormate(order.total)} ${t('SAR')}`}
      </td>
      <td onClick={() => navigate(`/myorders/ordersDetails/${order.code}`)}> {t("Visa")}</td>
      <td className={`stats ${statusToClassMap[order.status?.name]}`}
          onClick={() => navigate(`/myorders/ordersDetails/${order.code}`)}>
        {t(order.status?.name)}
      </td>
      <td onClick={() => navigate(`/myorders/ordersDetails/${order.code}`)}>
        {" "}
        {order.createDate ? formatSimpleDate(order.createDate, lang) : ""}
      </td>
      <td>
        <IconButton
          aria-label="more"
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon/>
        </IconButton>
        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className="toolbar-menu"
        >
          <MenuItem
            onClick={() => navigate(`/myorders/ordersDetails/${order.code}`)}
          >
            {t('View Order')}
          </MenuItem>
          {order.invoice && <MenuItem onClick={() => handlePrint(order.invoice.code)}>{t("Print")}</MenuItem>}
        </Menu>
      </td>
    </tr>
  );
};

export default function Orders() {
  const { t, direction, lang } = useContext(
    LocaleContext
  ) as LocaleContextProps;
  const { loadMyOrders } = useOrdersAPI();

  const [myOrders, setMyOrders] = useState<any[]>([]);

  const fetchOrders = () => {
    loadMyOrders()
      .then((resp) => {
        if (resp.items && resp.items.length > 0) setMyOrders(resp.items);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="myCasesContainer">
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
        dir={direction}
      >
        <div className="title-myprofile">
          <h3>{t("Orders")}</h3>
        </div>
        <div className="orders" dir={direction}>
          <div className="table-order">

            {(!myOrders || myOrders.length === 0) ? (
              <NoData msg="There are no orders"/>
            ) : <table className="table animate__fadeIn animate__animated ">
              <thead>
              <tr>
                <th scope="col">{t("Order no.")}</th>
                <th scope="col">{t("Total")}</th>
                <th scope="col">{t("Payment")}</th>
                <th scope="col">{t("Status")}</th>
                <th scope="col">{t("Date")}</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              {myOrders.map((order) => (
                <OrderRow order={order} key={order.id} lang={lang}/>
              ))}
              </tbody>
            </table>}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
