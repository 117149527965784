import { useLocation } from "react-router-dom";
import usePaymentAPI from "../../api/ems/payment";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../contexts/CartContext";
import { useNavigate } from "react-router";
import { CircularProgress } from "@mui/material";

const RedirectToConfirmation = () => {
  const location = useLocation();
  const navigation = useNavigate()
  const { initiateMoyasarCallback } = usePaymentAPI();
  const [loading, setLoading] = useState(false);
  const { clearCart } = useContext(CartContext);
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const message = searchParams.get("message");
  const status = searchParams.get("status");
  const method = searchParams.get("method");
  useEffect(() => {
    if (id) {
      setLoading(true);
      const callBack = method === 'tamara' ? (payload:any)=>new Promise((resolve) =>  resolve(payload)) : initiateMoyasarCallback
      callBack({
        id,
        status,
        message,
      })
        .then(() => {
          clearCart();
          navigation('/ConfirmPayment',{state:{
              id,
              status,
              message,
            }})
        }).catch((error)=>{
        navigation('/ConfirmPayment',{state:{
            id,
            status:'failed',
            message:error.response?.data.detail,
          }})

      })
        .finally(() => {
          setLoading(false)
        })
    }
  }, []);

  if(loading) return <CircularProgress size={80}/>
  return <div className='mx-auto'>Redirection to Confirmation Page</div>

}
export default RedirectToConfirmation;