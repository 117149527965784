/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-octal */
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import youtube from "./assets/youtupe.png";
import eye from "./assets/eye.png";
import star from "./assets/star.png";
import user from "./assets/user.png";
import p1 from "./assets/p2.png";
import p2 from "./assets/p2.png";
import { FaArrowRight, FaCartPlus, } from "react-icons/fa";
import FallbackImage from "../../assets/images/imagefallback.png";

import { Container, Modal, } from "@mui/material";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { CartContext } from "../../contexts/CartContext";
import toast from "react-hot-toast";
import useProductsAPI from "../../api/ems/products";
import { IProductByCategory } from "../../api/ems/schemas";
import { motion } from 'framer-motion';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TamaraWidgetComponent from "../../components/TamaraComponent/TamaraWidgetComponent";
import Loader from "../../components/ImageLoader/Loader";

export default function ProductDetails() {
  const { id } = useParams();

  const { t, direction, isRtl } = useContext(
    LocaleContext
  ) as LocaleContextProps;
  const { addToCart } = useContext(CartContext);
  const { loadProductDetails } = useProductsAPI();

  const [product, setProduct] = useState<IProductByCategory | null>(null);
  const [open, setOpen] = useState(false);
  const [imageURL, setImageURL] = useState('')

  const handleOpen = (imageURL: string) => {
    setImageURL(imageURL)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (id) {
      loadProductDetails(id)
        .then((data) => setProduct(data))
        .catch((err) => toast.error(t("Couldn't load product with ID" + id)));
    }
  }, [isRtl]);

  const handleAddToCart = (e: any) => {
    e.preventDefault();
    if (!product) return;
    addToCart({
      id: product ? product.id : 0,
      name: product ? product.name : "Product Name",
      count: 1,
      image: product && product.media ? product.media?.fullUrl : null,
      selectedCheckedInDate: null,
      selectedInstructorId: null,
      selectedLocationId: null,
      selectedAddonsIds: [],
      addons: [],
      speakers: [],
      locations: [],
      maxQTYPerOrder: product.maxQTYPerOrder,
      minQTYPerOrder: product.minQTYPerOrder,
      price: product.price,
    });
  };

  const isProductFree = product?.price?.amount === 0;
  return (
    <div dir={direction}>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-modal"
        aria-describedby="image-modal-description"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.3 }}
          style={{
            display: "flex",
            justifyContent: 'center',
            alignItems: "center",
            height: "100%",
            width: "50%", /* Adjust the width as needed */
            overflow: "hidden",
            margin: 'auto'
          }}
        >
          <Loader>
            <motion.img
              loading="lazy"
              src={imageURL}
              alt=""
              style={{
                maxWidth: "100%",
                maxHeight: "90vh",
                objectFit: "contain"
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              id='showVideoModal'

            />
          </Loader>
        </motion.div>
      </Modal>
      <section className="product-details mr-section">
        <Container maxWidth="xl" className="main-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="img-product-details">
                <div
                  className="owl-carousel owl-theme maincarousel"
                  id="slider-product-details"
                >
                  <div className="item">
                    <div className="sub-img-product-details">
                      <Loader>
                        <img
                          loading="lazy"
                          src={product?.media?.fullUrl || FallbackImage}
                          alt=""
                        />
                      </Loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="text-product-details">
                <div
                  className="title-start"
                  style={{ textAlign: isRtl ? "right" : "left" }}
                >
                  <h3>
                    {product?.productCategory
                      ? t(product.productCategory.name)
                      : t("onSiteCoursesTitle")}
                  </h3>
                  <h2 className='fw-bold'>
                    {product?.name
                      ? product.name
                      : t("estheticRestorationsTitle")}
                  </h2>
                </div>

                <div className="sub-text-product-details">
                  <div className="rate">
                    <span className="fa fa-star checked"/>
                    <span className="fa fa-star checked"/>
                    <span className="fa fa-star checked"/>
                    <span className="fa fa-star"/>
                    <span className="fa fa-star"/>
                  </div>
                  <div className="price-product-details">
                    {product?.price && product?.price?.discount > 0 && (
                      <span className="old-price">
                        {product
                          ? product.price?.displayTotalPriceWithoutDiscount
                          : ""}
                      </span>
                    )}

                    <span className="new-price">
                      {isProductFree
                        ? t("FREE")
                        : product?.price?.displayTotalPrice}
                    </span>
                  </div>

                </div>
                <TamaraWidgetComponent amount={product?.price?.totalPrice}/>
                <div className="links-products-details">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="pills-1-tab"
                        data-toggle="pill"
                        href="#pills-1"
                        role="tab"
                        aria-controls="pills-1"
                        aria-selected="true"
                      >
                        {t("overviewLabel")}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tabs-details">
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-1"
                      role="tabpanel"
                      aria-labelledby="pills-1-tab"
                    >
                      <div className="overview" dir={direction}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product ? product.content : "",
                          }}
                        ></div>
                        {/*<>*/}
                        {/*    dangerouslySetInnerHTML={{ __html: product ? product.description : ""}}*/}
                        {/*</>*/}
                      </div>
                      {product?.speakers &&
                      <div className="instructors">
                          <h4>{t('Instructors')}</h4>
                          <ul>

                            {product.speakers.map((speaker) => (
                              <li>
                                <Link to='#'>
                                  <div className="img-instructors">
                                    <Loader>
                                      <img
                                        loading="lazy"
                                        src={speaker.profilePicture?.fullUrl}
                                        alt={speaker.firstName}
                                        width="80px"
                                        height="80px"
                                        style={{ borderRadius: "50%" }}
                                      />
                                    </Loader>
                                  </div>
                                  <div className="text-instructors">
                                    <h2>
                                      {speaker.title +
                                      ", " +
                                      speaker.firstName +
                                      " " +
                                      speaker.lastName}
                                    </h2>
                                    <p>{speaker.bio}</p>
                                  </div>
                                </Link>
                              </li>))}
                          </ul>
                      </div>
                      }

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <div className="product-card">
                  <div className="product-card-image ">
                    {product?.mediaList && product.mediaList.length > 0 ?
                      <div className="slider-container">
                        <Slider
                          customPaging={index => {
                            return (<a>
                              <Loader>
                                <img src={product?.mediaList && product.mediaList[index]?.fullUrl || FallbackImage}/>
                              </Loader>
                            </a>)
                          }}
                          dots
                          speed={500}
                          slidesToShow={1}
                          slidesToScroll={1}
                          arrows

                        >
                          {product?.mediaList.map(value => {
                            return (<div key={value.fullUrl}>
                              <Loader>
                              <img
                                className='product-details-img'
                                onClick={() => handleOpen(value.fullUrl)}
                                loading="lazy"
                                style={{ maxHeight: '350px' }}
                                src={value.fullUrl || FallbackImage}
                              />
                              </Loader>
                            </div>)
                          })}
                        </Slider>
                      </div>
                      :
                      <img
                        loading="lazy"
                        src={FallbackImage}
                        alt=""
                      />}
                  </div>
                  <div className="product-card-data">
                    <div className="product-card-data-bot">
                      <button
                        className="add-to-cart-btn mt-2"
                        onClick={handleAddToCart}
                      >
                        <FaCartPlus/> {t("Add To Cart")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="related-products mr-section">
            <div
              className="owl-carousel owl-theme maincarousel"
              id="slider-related-products"
            >
              <div className="item">
                <Link to="/ProductDetails">
                  <div className="sub-products-index">
                    <div className="img-products-index">
                      <img loading="lazy" src={p1} alt=""/>
                    </div>
                    <div className="text-products-index">
                      <div className="rates-and-view">
                        <ul>
                          <li>
                            <img loading="lazy" src={youtube} alt=""/>
                            150 Session
                          </li>
                          <li>
                            <img loading="lazy" src={eye} alt=""/>
                            1.500 View
                          </li>
                          <li>
                            <img loading="lazy" src={star} alt=""/>( 120) Rate
                          </li>
                        </ul>
                      </div>
                      <h2> Anterior Direct Esthetic Restorations </h2>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout ..
                      </p>
                      <div className="sub-text-products-index">
                        <div className="name-doc">
                          <img loading="lazy" src={user} alt=""/>
                          <span>Dr. Name </span>
                        </div>
                        <div className="price-product">
                          <span className="old-price">2300 SAR </span>
                          <span className="new-price">2000 SAR </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="/ProductDetails">
                  <div className="sub-products-index">
                    <div className="img-products-index">
                      <img loading="lazy" src="images/p3.png" alt=""/>
                    </div>
                    <div className="text-products-index">
                      <div className="rates-and-view">
                        <ul>
                          <li>
                            <img loading="lazy" src={youtube} alt=""/>
                            150 Session
                          </li>
                          <li>
                            <img loading="lazy" src={eye} alt=""/>
                            1.500 View
                          </li>
                          <li>
                            <img loading="lazy" src={star} alt=""/>( 120) Rate
                          </li>
                        </ul>
                      </div>
                      <h2> Anterior Direct Esthetic Restorations </h2>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout ..
                      </p>
                      <div className="sub-text-products-index">
                        <div className="name-doc">
                          <img loading="lazy" src={user} alt=""/>
                          <span>Dr. Name </span>
                        </div>
                        <div className="price-product">
                          <span className="old-price">2300 SAR </span>
                          <span className="new-price">2000 SAR </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="/ProductDetails">
                  <div className="sub-products-index">
                    <div className="img-products-index">
                      <img loading="lazy" src={p2} alt=""/>
                    </div>
                    <div className="text-products-index">
                      <div className="rates-and-view">
                        <ul>
                          <li>
                            <img loading="lazy" src={youtube} alt=""/>
                            150 Session
                          </li>
                          <li>
                            <img loading="lazy" src={eye} alt=""/>
                            1.500 View
                          </li>
                          <li>
                            <img loading="lazy" src={star} alt=""/>( 120) Rate
                          </li>
                        </ul>
                      </div>
                      <h2> Anterior Direct Esthetic Restorations </h2>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout ..
                      </p>
                      <div className="sub-text-products-index">
                        <div className="name-doc">
                          <img loading="lazy" src={user} alt=""/>
                          <span>Dr. Name </span>
                        </div>
                        <div className="price-product">
                          <span className="old-price">2300 SAR </span>
                          <span className="new-price">2000 SAR </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="/ProductDetails">
                  <div className="sub-products-index">
                    <div className="img-products-index">
                      <img loading="lazy" src={p2} alt=""/>
                    </div>
                    <div className="text-products-index">
                      <div className="rates-and-view">
                        <ul>
                          <li>
                            <img loading="lazy" src={youtube} alt=""/>
                            150 Session
                          </li>
                          <li>
                            <img loading="lazy" src={eye} alt=""/>
                            1.500 View
                          </li>
                          <li>
                            <img loading="lazy" src={star} alt=""/>( 120) Rate
                          </li>
                        </ul>
                      </div>
                      <h2> Anterior Direct Esthetic Restorations </h2>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout ..
                      </p>
                      <div className="sub-text-products-index">
                        <div className="name-doc">
                          <img loading="lazy" src={user} alt=""/>
                          <span>Dr. Name </span>
                        </div>
                        <div className="price-product">
                          <span className="old-price">2300 SAR </span>
                          <span className="new-price">2000 SAR </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </Container>
        {/* form-add-comment */}
        <div
          className="modal fade form-add-comment"
          tabIndex={1}
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <form>
                <div className="main-form-add-comment">
                  <div className="title-center">
                    <h3>{t("Rate Us")}</h3>
                    <h2>{t("Rate our Product")} :</h2>
                  </div>
                  <div className="rating">
                    <input
                      type="radio"
                      id="star5"
                      name="rating"
                      defaultValue={5}
                    />
                    <label
                      className="star"
                      htmlFor="star5"
                      title="Awesome"
                      aria-hidden="true"
                    />
                    <input
                      type="radio"
                      id="star4"
                      name="rating"
                      defaultValue={4}
                    />
                    <label
                      className="star"
                      htmlFor="star4"
                      title="Great"
                      aria-hidden="true"
                    />
                    <input
                      type="radio"
                      id="star3"
                      name="rating"
                      defaultValue={3}
                    />
                    <label
                      className="star"
                      htmlFor="star3"
                      title="Very good"
                      aria-hidden="true"
                    />
                    <input
                      type="radio"
                      id="star2"
                      name="rating"
                      defaultValue={2}
                    />
                    <label
                      className="star"
                      htmlFor="star2"
                      title="Good"
                      aria-hidden="true"
                    />
                    <input
                      type="radio"
                      id="star1"
                      name="rating"
                      defaultValue={1}
                    />
                    <label
                      className="star"
                      htmlFor="star1"
                      title="Bad"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="textarea-comment">
                    <textarea
                      className="form-control"
                      placeholder={t("write your comment")}
                      defaultValue={""}
                    />
                  </div>
                  <div className="btn-add-comment">
                    <button className="ctm-btn2">
                      {t("Submit")} <FaArrowRight/>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
