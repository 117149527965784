/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { IconButton, Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { AnimatePresence, motion } from "framer-motion"; // Import motion components
import ba from "./assets/ba.svg";
import ba2 from "./assets/ba2.svg";
import "./BannerSection.css";
import { Link } from "react-router-dom";
import pu4 from "./assets/pu4.png";
import pu5 from "./assets/pu5.png";
import pu6 from "./assets/pu6.png";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { UserContext, UserContextProps } from "src/contexts/UserContext";

export default function BannerSection() {
  const { isAuthenticated } = useContext(UserContext) as UserContextProps;

  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    image: null,
  });

  const handleOpenModal = (title: any, description: any, image: any) => {
    setModalContent({ title, description, image });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent({ title: "", description: "", image: null });
  };

  const options = [
    { id: "option1", image: pu5 },
    { id: "option2", image: pu4 },
    { id: "option3", image: pu6 },
  ];
  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedValue(event.target.value);

    switch (event.target.value) {
      case "option1":
        setSelectedImage(pu5);
        break;
      case "option2":
        setSelectedImage(pu4);
        break;
      case "option3":
        setSelectedImage(pu6);
        break;
      default:
        setSelectedImage(pu5);
    }
  };

  const [selectedValue, setSelectedValue] = useState("option1");
  const [selectedImage, setSelectedImage] = useState(ba);

  const selectedID = `sub-banner-${selectedValue}`;

  if (isAuthenticated) return null;

  return (
    <div>
      <section className="banner-index mr-section" dir={direction}>
        <div className="main-container">
          <div className={`row animate__animated animate__fadeIn ${isAuthenticated ? 'justify-content-center' : ''}`}>
            <div
              className={`col-lg-6 animate__animated animate__fadeIn  ${selectedID}`}
            >
              <div className="sub-banner-tamara animate__animated animate__fadeIn">
                

                <div className="text-sub-banner-index1 animate__animated animate__fadeIn">
                  <h2>
                    {selectedValue === "option1" && (
                      <>
                        {t("tamara")}
                        <br />
                        {t("tamaraDescription")}
                      </>
                    )}
                    {selectedValue === "option2" && (
                      <>
                        {t("mada")}
                        <br />
                        {t("madaDescription")}
                      </>
                    )}
                    {selectedValue === "option3" && (
                      <>
                        {t("tabby")}
                        <br />
                        {t("tabbyDescription")}
                      </>
                    )}
                  </h2>

                </div>
                

                <div className="btn-tamara-and-img" dir={direction}>
                  <Link
                    className="ctm-btn bg-dark"
                    to="/signup"
                  >
                    {t("getStartedText")}
                  </Link>
                  <img
                    width={150}
                    style={{ objectFit: "contain" }}
                    loading="lazy"
                    src={selectedImage}
                    alt=""
                  />

                </div>
              </div>
            </div>
            {!isAuthenticated && (
              <div className="col-lg-6">
                <div className="sub-banner-get-started">
                  <div className="text-get-started">
                    <h2>
                      {t("Join now to start")}
                      <br />
                      {t("learning")}
                    </h2>
                    <Link target="_blank" to="https://najtraining.com/become-instructor"
                      className="ctm-btn"
                    >
                      {t("getStartedText")}
                    </Link>
                  </div>
                  <div className="img-get-started">
                    <img loading="lazy" src={ba2} alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Modal */}
      <Modal open={isModalOpen} onClose={handleCloseModal} dir={direction}>
        <AnimatePresence>
          {isModalOpen && (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              style={{
                position: "absolute",
                inset: "0",
                margin: "auto",
                backgroundColor: "white",
                borderRadius: "8px",
                maxWidth: "500px",
                maxHeight: "500px",
                overflowY: "auto",
                padding: "20px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                width: "maxContent",
                height: "maxContent",
              }}
            >
              {/* Close Icon */}
              <IconButton
                onClick={handleCloseModal}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
              >
                <Close />
              </IconButton>

              {modalContent.image && (
                <motion.img
                  src={modalContent.image}
                  alt=""
                  style={{ maxWidth: "100%", marginBottom: "20px" }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                />
              )}
              {modalContent.title && (
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  <Typography variant="h4" style={{ marginBottom: "10px" }}>
                    {modalContent.title}
                  </Typography>
                </motion.div>
              )}
              {modalContent.description && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  <Typography>{modalContent.description}</Typography>
                </motion.div>
              )}
              <motion.div
                style={{ marginTop: "20px", textAlign: "center" }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // Handle "Get Started" button click
                    // You can add your logic here
                  }}
                  style={{ marginRight: "10px" }}
                >
                  {t("Get Started")}
                </Button> */}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </Modal>
    </div>
  );
}
