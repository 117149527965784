/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Container } from "@mui/material";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import useSponsorsApi from "../../api/ems/sponsers";
import NoData from "../../iComponents/NoData/NoData";

export default function Partners() {
  const { t, direction } = useContext(LocaleContext) as LocaleContextProps;
  const { loadSponsersHome } = useSponsorsApi();
  const [sponsors, setSponsors] = useState<any[]>([]);
  useEffect(() => {
    loadSponsersHome()
      .then((response) => {
        setSponsors(response);
      })
      .catch((error) => console.error(error));
  }, []);
  return (
    <div>
      <section dir={direction} className="partners mr-section">
        <Container maxWidth="xl" className="main-container">
          <div className="title-center">
            <h3>{t("partners")}</h3>
          </div>
          <div className="row">
            {sponsors.length > 0 ? (
              sponsors.map((sponsor) => (
                <div className="col-lg-3 col-md-4 col-sm-6 col-xl-2">
                  <div className="item" dir={direction}>
                    <div className="sub-partners-index h-100 py-2">
                      <div className="img-partners-index">
                        <img
                          height={120}
                          loading="lazy"
                          src={sponsor?.logo?.fullUrl}
                          alt={sponsor.name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NoData msg="There is no Sponsors yet" />
            )}
          </div>
        </Container>
      </section>
    </div>
  );
}
