import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Tooltip } from "@mui/material";
import { LocaleContext, LocaleContextProps, } from "../../contexts/LocaleContext";
import { CartSummary } from "../../components/CartSummary/CartSummary";
import { CartContext } from "../../contexts/CartContext";
import "./Cart.css";
import NoData from "../../iComponents/NoData/NoData";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import useUserCartAPI from "../../api/ems/user-cart";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { synchronizeCarts, synchronizeCartsOffline } from "./utils";
import toast from "react-hot-toast";
import InfoIcon from "@mui/icons-material/Info";
import { currencyFormate } from "src/utils/dateUtils";

const CartTableRow = ({
  item,
  index,
  handleDecrement,
  handleIncrement,
  removeFromCart,
  t,
}: any) => {
  const formattedString = item?.selectedAddons?.map((obj: any, index: number) => (
    <div key={index}>{`${obj.qty >= 2 ? obj.qty + 'x' : ''} ${obj.name}: ${currencyFormate(obj.price.amount * obj.qty)} ${t('SAR')}`}</div>
  ));

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        {item.name}
        {formattedString && formattedString.length >= 1 ? (
          <Tooltip
            title={
              <div>
                {`${t("equipmentsText")}:`}
                {formattedString}
              </div>
            }
            arrow
          >
            <InfoIcon style={{ fontSize: 16 }} />
          </Tooltip>
        ) : null}
      </td>
      <td>
        <div className="price-product">
          {item?.price.totalPrice !== item?.price.totalPriceWithoutDiscount && (
            <span className="old-price">
              {item?.price.displayTotalPriceWithoutDiscount}
            </span>
          )}

          <span className="new-price">{item?.price.displayTotalPrice}</span>
        </div>
      </td>
      <td>
        <div className="counter-wrap">
          <div className="counter-action" onClick={() => handleDecrement(item)}>
            <RemoveIcon />
          </div>
          <div className="counter-number">{item.count}</div>
          <div className="counter-action" onClick={() => handleIncrement(item)}>
            <AddIcon />
          </div>
        </div>
      </td>
      <td>
        <DeleteIcon
          onClick={() => removeFromCart(item.id)}
          style={{ cursor: "pointer" }}
          color="error"
        />
      </td>
    </tr>
  );
};
export default function Cart() {
  const navigate = useNavigate();
  const { t, direction,isRtl } = useContext(LocaleContext) as LocaleContextProps;
  const {
    cartItems,
    setCartItems,
    hasBookingItem,
    removeFromCart,
    addToCart,
    addNewCoupon,
    setBackendPriceDetails,
    backendPriceDetails
  } = useContext(CartContext);

  const { isAuthenticated } = useContext(UserContext) as UserContextProps;
  const {
    loadReadyUserCart,
    addToUserCart,
    updateToUserCart,
    deleteFromUserCart,
    loadReadyAnonymousCart,
    reviewReadyCartCoupons
  } = useUserCartAPI();

  const [couponValue, setCouponValue] = useState("");
  const [shouldDisableCart, setShouldDisableCart] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      loadReadyUserCart()
        .then((response) => {
          synchronizeCarts({
            backendCart: response,
            cartItems,
            setCartItems,
            addToUserCart,
            updateToUserCart,
            deleteFromUserCart,
            setBackendPriceDetails,
            loadReadyUserCart,
          });
        }).then(()=>
          reviewReadyCartCoupons().then(response=>{
            setBackendPriceDetails({ ...backendPriceDetails, coupons:response.map(value => ({
                couponCode: value.couponCode,
                discountAmount: value.couponDiscountAmount
              })) })
          } ).catch((error => console.error('ERROR', error)))
        )
        .catch((err) => console.error(err));
    } else {
      loadReadyAnonymousCart(
        cartItems.map((item) => ({
          productId: item.id,
          qty: item.count,
        }))
      )
        .then((response) => {
          setCartItems(
            synchronizeCartsOffline(cartItems, response, setBackendPriceDetails)
          );
        })
        .catch(() => {
          setShouldDisableCart(true);
          toast.error(
            "Cart services not available at the moment, please try again later."
          );
        });
    }
  }, [isAuthenticated,isRtl]);

  const handleDecrement = (item: any) => {
    removeFromCart(item.id, 1);
  };

  const handleIncrement = (item: any) => {
    addToCart(item);
  };

  const handleAddCoupon = () => {
    if (couponValue.length >= 1) {
      addNewCoupon(couponValue.trim());
      setCouponValue("");
    }
  };

  return (
    <div dir={direction}>
      <section className="cart-page mr-section">
        <Container maxWidth="xl" className="main-container">
          <div className="order-tracking">
            <ul>
              <li className="active">
                <Link to={`${shouldDisableCart ? "/" : "/cart"}`}>
                  <div className="img-order-tracking">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={27}
                      height={27}
                      fill="currentColor"
                      className="bi bi-cart3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                    </svg>
                  </div>
                  <h2>
                    {t("Your Cart")} <span />
                  </h2>
                </Link>
              </li>
              {hasBookingItem && (
                <li>
                  <Link to={`${shouldDisableCart ? "/" : "/booking"}`}>
                    <div className="img-order-tracking">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={27}
                        height={27}
                        fill="currentColor"
                        className="bi bi-calendar4-week"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                        <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                      </svg>
                    </div>
                    <h2>
                      {t("Preferences")} <span />
                    </h2>
                  </Link>
                </li>
              )}
              <li>
                <Link to={`${shouldDisableCart ? "/" : "/payment"}`}>
                  <div className="img-order-tracking">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={27}
                      height={27}
                      fill="currentColor"
                      className="bi bi-credit-card"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
                      <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                    </svg>
                  </div>
                  <h2>
                    {t("Payment")} <span />
                  </h2>
                </Link>
              </li>
            </ul>
          </div>
          <div id="cart-page">
            <div className="row" style={{ marginTop: "32px" }}>
              <div className="col-lg-8">
                <div style={{ width: "100%", overflowX: "auto" }}>
                  {cartItems.length > 0 ? (
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t("Products")}</th>
                          {/* <th>{t("Picture")}</th> */}
                          <th>{t("Price")}</th>
                          <th>{t("quantity")}</th>
                        </tr>
                      </thead>
                      <tbody style={{ maxHeight: "300px", overflowY: "auto" }}>
                        {cartItems.map((item, index) => (
                          <CartTableRow
                            key={index}
                            item={item}
                            index={index}
                            handleDecrement={handleDecrement}
                            handleIncrement={handleIncrement}
                            removeFromCart={removeFromCart}
                            t={t}
                          />
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <NoData msg={"There is no item in your cart"} />
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                {cartItems.length > 0 && isAuthenticated && (
                  <div className="coupon-wrap" style={{ marginBottom: "16px" }}>
                    <div className="form-group">
                      <label className="form-label">{t("Coupon code")}</label>
                      <div className="coupon-input-wrap">
                        <input
                          className="LoginSMinput"
                          value={couponValue}
                          onChange={(e) => setCouponValue(e.target.value.trim())}
                          placeholder={t("Enter coupon")}
                        />
                        <button
                          className="ctm-btn"
                          onClick={handleAddCoupon}
                          disabled={couponValue.length === 0}
                        >
                          {t("Apply")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <CartSummary
                  disableConfirm={shouldDisableCart}
                  confirmMsg={"Check out"}
                  handleConfirm={() =>
                    hasBookingItem ? navigate("/booking") : navigate("/payment")
                  }
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
