import React, {useContext} from 'react';
import LoginSM, {FormMode} from "../LoginSM/LoginSM";
import {LocaleContext, LocaleContextProps} from "../../contexts/LocaleContext";

export default function Signup() {
    const {direction} = useContext(LocaleContext) as LocaleContextProps;

    return (
        <section className="signup login-page" dir={direction}>
            <div className="row pg_none" style={{backgroundColor: "#ebf3ff", overflowY: "auto", height: "100vh"}}>
                <div className="d-flex justify-content-center align-items-center" style={{margin: '50px 0'}}>
                    <LoginSM mode={FormMode.SignUp}/>
                </div>
            </div>
        </section>
    );
}
