export function extractDay(dateValue: string): number {
  const date = new Date(dateValue);
  return date.getDate();
}

export function extractAbbreviatedDayOfWeek(dateValue: string): string {
  const date = new Date(dateValue);
  return date.toLocaleString('en-US', { weekday: 'short' });
}

export function extractAbbreviatedMonth(dateValue: string, lang: string = 'en') {
  const date = new Date(dateValue);
  return date.toLocaleString(lang, { month: 'short' });
}

export function extractYear(dateValue: string, lang: string = 'en') {
  const date = new Date(dateValue);
  return date.toLocaleString(lang, { year: 'numeric' });
}

export function extractTime(dateValue: string): string {
  const date = new Date(dateValue);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${period}`;
}

export const formatDate = (dateString: string, lang: string = 'en') => {
  const date = new Date(dateString);
  return date.toLocaleDateString(lang,{day:"2-digit",month:'short',year:'numeric'})
};


export const formatSimpleDate = (dateString: string, lang: string = 'en') => {
  const date = new Date(dateString);
  return date.toLocaleDateString(lang,{day:"2-digit",month:'short',year:'numeric',minute:"2-digit",hour:"2-digit",hour12:true})
};


export const currencyFormate = (number: number) => number?.toFixed(2);
