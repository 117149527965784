/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { Speaker } from "./Speaker";
import React, { useContext, useEffect, useState } from "react";
import speakersAPI from "../../api/ems/speakers";
import { LocaleContext, LocaleContextProps } from "../../contexts/LocaleContext";
import NoData from "../../iComponents/NoData/NoData";
import OwlCarousel from "react-owl-carousel";

export default function SpeakersSection() {
  const { t, direction, isRtl } = useContext(LocaleContext) as LocaleContextProps;
  const { loadHomeSpeakers } = speakersAPI();
  const [speakers, setSpeakers] = useState<any[]>([]);

  useEffect(() => {
    loadHomeSpeakers()
      .then(data => {
        if (data.items && data.items.length > 0) {
          setSpeakers([...data.items])
        }
      })
      .catch((err) => {
        console.error(err)
      });
  }, [])

  return (
    <div id="SpeakersSection">
      <section className="speakers-index pg-section" dir={direction}>
        <div className="main-container">
          <div className="title-center">
            <h3>{t('speakers')}</h3>
            <h2>{t('meetTheSpeakers')}</h2>

          </div>
          <div className="main-speakers-index mb-5">
            <div className="row">
              {speakers.length > 0 ? <OwlCarousel
                  center
                  className="owl-carousel owl-theme"
                  id="slider-speaker"
                  loop={true}
                  nav={false}
                  margin={100}
                  items={3}
                  dots={false}
                  autoplayTimeout={4000}
                  autoplayHoverPause={true}
                  autoplay={false}
                  rtlClass="owl-rtl"
                  smartSpeed={1000}
                  responsiveClass={true}
                  responsive={{
                    0: {
                      items: 1,
                      smartSpeed: 400,
                    },
                    700: {
                      items: 3,
                      margin: 50
                    },
                    1000: {
                      items: 4,
                    },
                  }}
                >
                  {speakers.map((speaker) =>
                    <Speaker swiper speaker={speaker}/>
                  )}
                </OwlCarousel>

                :
                <NoData msg="There is no speakers yet"/>
              }
            </div>
          </div>
          {speakers.length > 8 ?
            <div className="btn-more center-text mt-5 p-4">
              <Link to="/speakers" className="ctm-btn2">
                {t('View More')} {isRtl ? <FaLongArrowAltLeft/> : <FaLongArrowAltRight/>}
              </Link>
            </div> : ""}
        </div>

      </section>
    </div>
  );
}
